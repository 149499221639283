import './BroadcastMessageSection.scss';
import { IconButton } from '@mui/material';
import { motion } from 'framer-motion';
import SendIcon from '@mui/icons-material/Send';
import { useEffect, useRef, useState } from 'react';
import { gql } from '@apollo/client';
import { formatNotices } from '../../utils/functions';
import moment from 'moment';
import CircularProgress from '@mui/material/CircularProgress';
import { useAddNotice } from '../../hooks/gql/mutation/create/useAddNotice';
import { useGetAllNotices } from '../../hooks/gql/query/getAll/useGetAllNotices';

const GET_ALL_NOTICES = gql`
  query GetAllNotices($input: PaginationInput!) {
    getAllNotices(input: $input) {
      _id
      content
      createdAt
    }
  }
`;

const BroadcastMessageSection: React.FC<{}> = (props) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [brMessage, setBrMessage] = useState('');
  const brRef = useRef<HTMLDivElement>(null);

  const { addNotice } = useAddNotice();

  const {
    data: notices,
    error: noticesError,
    loading: noticesLoading,
    refetch: noticesRefectch,
  } = useGetAllNotices(GET_ALL_NOTICES, {
    page: 1,
    item_per_page: 20,
  });

  const formattedNotices = formatNotices(notices);

  useEffect(() => {
    const interval = setInterval(() => {
      noticesRefectch();
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    brRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
    });
  }, [notices?.length]);

  return (
    <motion.div
      initial={{ x: 50, y: 0 }}
      animate={{ x: 0, y: 0 }}
      transition={{ duration: 0.5 }}
      className='broadcast_message'>
      <div className='broadcast_message--h'>
        <p>You are sending broadcast message to all companies</p>
      </div>

      {/* Message Displaying */}
      <div className='broadcast_message--c'>
        {/* ITERATE HERE */}

        {formattedNotices.map((n) => {
          if (typeof n === 'string') {
            return (
              <div key={n} className='broadcast_message--c_date'>
                <p>{n}</p>
              </div>
            );
          } else {
            return (
              <motion.div
                key={n._id}
                className='broadcast_message--c_right'
                initial={{ y: 10, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.5 }}>
                <div className='conv_message'>
                  <pre className='message_content'>{n.content}</pre>
                </div>
                <div className='conv_time'>
                  <p>{moment(n.createdAt).format('HH:mm')}</p>
                </div>
              </motion.div>
            );
          }
        })}

        <div ref={brRef} />
      </div>

      {/* Message Typing */}
      <div className='broadcast_message--i'>
        <div className='broadcast_message--i_inp'>
          <textarea
            name=''
            id=''
            placeholder='Type new message...'
            value={brMessage}
            onChange={(e) => {
              setBrMessage(e.target.value);
            }}
            cols={20}
            rows={2}></textarea>
        </div>
        <div className='broadcast_message--i_sub'>
          <IconButton
            onClick={async () => {
              // Send BroadCast Message
              setIsSubmitting(true);
              try {
                await addNotice({
                  content: brMessage,
                });
                setBrMessage('');
                setIsSubmitting(false);

                setTimeout(() => {
                  noticesRefectch();
                }, 1000);
              } catch (err) {
                setIsSubmitting(false);
                console.log(err);
              }
            }}
            disabled={!(brMessage.trim().length >= 2)}
            style={{ color: '#fff', backgroundColor: '#002661' }}>
            {isSubmitting ? (
              <CircularProgress style={{ color: '#fff' }} size={24} />
            ) : (
              <SendIcon
                style={{
                  marginLeft: 3,
                  transform: 'rotate(-45deg)',
                  marginBottom: 3,
                }}
              />
            )}
          </IconButton>
        </div>
      </div>
    </motion.div>
  );
};
export default BroadcastMessageSection;
