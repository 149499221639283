import * as React from 'react';
import Box from '@mui/material/Box';
import Rating from '@mui/material/Rating';
import { getNameAvatar, getRatingLabel } from '../../utils/functions';
import './ReviewListItem.scss';
import { Avatar, FormControl } from '@mui/material';
import Colors from '../../constants/Colors';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import { gBaseUrl } from '../../utils/config';
import { useUpdateReviewByAdmin } from '../../hooks/gql/mutation/update/useUpdateReviewByAdmin';
import { useState } from 'react';

const ReviewListItem: React.FC<{
  _id: string;
  rating: number;
  message: string;
  contact_name: string;
  referral_code: string;
  photo: string;
  isPhotoVisible: boolean;
  isVisible: boolean;
  createdAt: Date;
  review_by: {
    name: string;
  };
  onDelete: (id: string) => void;
  onActionClick: (id: string, isVisible: boolean) => void;
  refetch_review: () => void;
}> = ({
  _id,
  rating,
  isVisible,
  message,
  contact_name,
  referral_code,
  photo,
  isPhotoVisible,
  createdAt,
  review_by: { name },
  onDelete,
  onActionClick,
  refetch_review,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [reviewText, setReviewText] = useState(message);

  const { updateReviewByAdmin } = useUpdateReviewByAdmin();

  return (
    <div className='reviews_cards_item'>
      <div className='review_item_details'>
        <div className='review_by'>
          <p>
            <strong>Review By - </strong> {name}
          </p>
        </div>
        <div className='title_logo'>
          <div className='logo_comp'>
            {isPhotoVisible ? (
              <Avatar
                src={`${gBaseUrl}/photos/avatars/${photo}`}
                sx={{
                  bgcolor: Colors.pclXLight,
                  marginRight: 1,
                  color: Colors.primaryColorLight,
                  boxShadow: '0px 10px 15px -3px rgba(0, 0, 0, 0.1)',
                }}
              />
            ) : (
              <Avatar
                sx={{
                  bgcolor: Colors.pclXLight,
                  marginRight: 1,
                  color: Colors.primaryColorLight,
                  boxShadow: '0px 10px 15px -3px rgba(0, 0, 0, 0.1)',
                }}>
                {getNameAvatar(contact_name)}
              </Avatar>
            )}
            <div className='logo_comp_star'>
              <p className='business_name'>{contact_name}</p>
              <p className='business_id'>#{referral_code}</p>
            </div>
          </div>
        </div>

        <div className='content_wrapper'>
          <p className='content_text'>{message}</p>
        </div>
      </div>
      <div className='review_item_details_ac'>
        <div className='rating_wrapper'>
          <div className='rating_star'>
            <p>{rating?.toFixed(1)}</p>
            <Rating
              name='simple-controlled'
              value={rating}
              size='small'
              disabled={true}
              onChange={(event, newValue) => {
                //
              }}
            />

            <div className='rating_status'>
              <p>{getRatingLabel(rating)}</p>
            </div>
          </div>

          <div className='rating_date'>
            <p>{moment(createdAt).format('MMM DD, YYYY')}</p>
          </div>
        </div>
        <div className='action_buttons'>
          <FormControl sx={{ m: 0, width: '12ch' }} variant='standard'>
            <Button
              variant='contained'
              size='small'
              onClick={() => {
                onActionClick(_id, isVisible);
              }}>
              {isVisible ? 'Hide' : 'Approve'}
            </Button>
          </FormControl>
          <div className='view_del'>
            <IconButton
              aria-label='edit'
              size='small'
              onClick={() => {
                setOpenEdit(true);
              }}>
              <EditIcon style={{ color: Colors.primaryColorLight }} />
            </IconButton>

            <IconButton
              aria-label='delete'
              size='small'
              onClick={() => {
                onDelete(_id);
              }}>
              <DeleteIcon style={{ color: 'red' }} />
            </IconButton>
          </div>

          <Modal
            open={openEdit}
            onClose={() => {
              setOpenEdit(false);
            }}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
              backdrop: {
                timeout: 500,
              },
            }}>
            <Fade in={openEdit}>
              <Box
                sx={{
                  position: 'absolute' as const,
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  width: '80%',
                  bgcolor: Colors.light,
                  boxShadow: 24,
                  borderRadius: 2,
                  p: 2,
                }}>
                <Typography variant='h6' component='h2'>
                  Edit Review
                </Typography>

                <div className='review_edit_field' style={{ margin: '20px 0' }}>
                  <textarea
                    maxLength={500}
                    style={{
                      width: '98%',
                      border: '1px solid #ddd',
                      borderRadius: 5,
                      fontFamily: 'inherit',
                      padding: 10,
                    }}
                    name=''
                    id=''
                    placeholder='Type new review...'
                    value={reviewText}
                    onChange={(e) => {
                      setReviewText(e.target.value);
                    }}
                    cols={20}
                    rows={5}></textarea>
                </div>

                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'flex-end',
                    alignItems: 'flex-end',
                  }}>
                  <FormControl
                    sx={{
                      m: 0,
                      width: '12ch',
                      marginRight: 2,
                    }}
                    variant='standard'>
                    <Button
                      variant='contained'
                      size='small'
                      onClick={() => {
                        setOpenEdit(false);
                      }}>
                      Cancel
                    </Button>
                  </FormControl>

                  <FormControl
                    sx={{
                      m: 0,
                      width: '12ch',
                    }}
                    variant='standard'>
                    <Button
                      variant='contained'
                      size='small'
                      disabled={isSubmitting}
                      onClick={async () => {
                        setIsSubmitting(true);
                        await updateReviewByAdmin({
                          _id,
                          message: reviewText,
                        });

                        setIsSubmitting(false);

                        // Refetch Data
                        refetch_review();
                      }}>
                      Update
                    </Button>
                  </FormControl>
                </div>
              </Box>
            </Fade>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default ReviewListItem;
