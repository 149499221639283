/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';

const ActionButtons: React.FC<{
  id: string;
  isSuspended: boolean;
  onEyeClick: (id: string) => void;
  onSwitchClick: (id: string, isSuspended: boolean) => void;
  onDelete: (id: string) => void;
}> = ({ id, isSuspended, onEyeClick, onSwitchClick, onDelete }) => {
  return (
    <Stack direction='row' spacing={1}>
      <IconButton
        color='primary'
        aria-label='visiblity'
        onClick={() => onEyeClick(id)}>
        <VisibilityIcon />
      </IconButton>
      <IconButton
        aria-label='toggleoff'
        color={isSuspended ? 'warning' : 'primary'}
        onClick={() => {
          onSwitchClick(id, !isSuspended);
        }}>
        {isSuspended && <ToggleOffIcon />}
        {!isSuspended && <ToggleOnIcon />}
      </IconButton>

      <IconButton
        color='primary'
        aria-label='group'
        onClick={() => onDelete(id)}>
        <DeleteOutlinedIcon style={{ color: 'red' }} />
      </IconButton>
    </Stack>
  );
};

export default ActionButtons;
