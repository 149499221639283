import moment from 'moment';
import { labelsRating } from './data';
import { AddressType } from '../types/types';
import { ConversationsObjType } from '../types/schema/utils.types';
import { Notice } from '../types/schema/notice.types';
import { ServiceAreaObjType } from '../types/schema/user.types';

export const beautifyString = (inputString: string) => {
  // Remove the leading slash and split the string into words
  const words = inputString.replace(/^\//, '').split('-');

  // Capitalize the first letter of each word
  const capitalizedWords = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1)
  );

  // Join the words back together with spaces
  const beautifiedString = capitalizedWords.join(' ');

  return beautifiedString;
};

export const getNameAvatar = (name: string) => {
  const avtrArr = name?.match(/\b(\w)/g)?.slice(0, 2);
  if (!avtrArr) return name;
  const avtr = avtrArr.slice(0, 2).join('').toUpperCase();
  return avtr;
};

export const getLabelText = (value: number) => {
  return `${value} Star${value !== 1 ? 's' : ''}, ${labelsRating[value]}`;
};

export const formatString = (slug?: string) => {
  if (!slug) {
    return '';
  }

  const formattedSlug = slug
    .replace(/[-_]+/g, ' ')
    .replace(
      /\b(?!and\b)\w+/g,
      (match) => match.charAt(0).toUpperCase() + match.slice(1).toLowerCase()
    )
    .replace(/And/g, 'and');

  return formattedSlug;
};

export const getRatingLabel = (value: number): string => {
  if (value <= 0.5) {
    return labelsRating[0.5];
  } else if (value <= 1) {
    return labelsRating[1];
  } else if (value <= 1.5) {
    return labelsRating[1.5];
  } else if (value <= 2) {
    return labelsRating[2];
  } else if (value <= 2.5) {
    return labelsRating[2.5];
  } else if (value <= 3) {
    return labelsRating[3];
  } else if (value <= 3.5) {
    return labelsRating[3.5];
  } else if (value <= 4) {
    return labelsRating[4];
  } else if (value <= 4.5) {
    return labelsRating[4.5];
  } else if (value <= 5) {
    return labelsRating[5];
  }

  // Default case if the value is out of the specified range
  return 'Unknown';
};

export const getAmountWithCurrency = (value: number, isCent?: boolean) => {
  const amount = (value / (isCent ? 100 : 1)).toFixed(2);
  return `${value < 0 ? '- ' : ''}£${Math.abs(Number(amount)).toFixed(2)}`;
};
export const getVehicleType = (input: string): string => {
  if (!input) return '';

  const parts = input.replace('_seater', '').split('_');
  const firstPart = parts[0];

  if (firstPart.includes('-')) {
    const [lower, upper] = firstPart.split('-');
    return `${parts
      .slice(1, parts.length)
      .map((part) => part.charAt(0).toUpperCase() + part.slice(1))
      .join(' ')}: ${lower} - ${upper} Seats`;
  } else {
    return `${parts
      .slice(1, parts.length)
      .map((part) => part.charAt(0).toUpperCase() + part.slice(1))
      .join(' ')}: ${firstPart} Seats`;
  }
};

export const getVehicleSeats = (vechile_type: string[]): string => {
  if (!vechile_type) return '';

  const result: Set<string> = new Set();

  vechile_type.forEach((vt) => {
    const matches = vt.match(/\d+(?:-\d+)?/);
    if (matches) {
      result.add(matches[0]);
    }
  });

  return Array.from(result).join(', ');
};

export const getServiceAreaString = (service_areas: ServiceAreaObjType[]) => {
  if (service_areas) {
    const strings = service_areas.map((se, i) => {
      const loc_name = se.location_name;
      const radius = se.radius;

      if (!radius || !loc_name) {
        return 'Not Updated';
      }

      return `${i + 1}) ${radius} miles around - ${loc_name}`;
    });

    return strings.join('\r\n\n');
  }

  return '';
};

export const sumRevenueByDate = (
  input?: { Revenue: number; Date: string }[]
) => {
  if (!input) return [];

  const result: any = {};

  input.forEach((item) => {
    const { Revenue, Date } = item;

    if (!result[Date]) {
      result[Date] = 0;
    }
    result[Date] += Revenue;
  });

  const mapDate = Object.keys(result).map((Date) => ({
    Date,
    Revenue: +result[Date].toFixed(2),
  }));

  return mapDate;
};

export const formatNotices = (notices: Notice[] = []) => {
  // Function to format date as "DD MMM, YYYY"
  const formatDate = (dateString: string | Date) => {
    return moment(dateString).format('DD MMM, YYYY');
  };

  // Group messages by date using Map()
  const noticeMap = new Map<string, Notice[]>();

  const noticesOrderView = [...notices].reverse();

  noticesOrderView.forEach((notice) => {
    const formattedDate = formatDate(notice.createdAt);
    if (!noticeMap.has(formattedDate)) {
      noticeMap.set(formattedDate, []);
    }

    noticeMap.get(formattedDate)?.push(notice);
  });

  const formattednotices = [];
  for (const [date, notices] of noticeMap) {
    formattednotices.push(date);
    formattednotices.push(...notices);
  }

  return formattednotices;
};

export const formatMessages = (messages: ConversationsObjType[]) => {
  // Function to format date as "DD MMM, YYYY"
  const formatDate = (dateString: Date | string) => {
    return moment(dateString).format('DD MMM, YYYY');
  };

  // Group messages by date using Map()
  const messageMap = new Map<string, ConversationsObjType[]>();

  messages.forEach((message) => {
    const formattedDate = formatDate(message.sentAt);
    if (!messageMap.has(formattedDate)) {
      messageMap.set(formattedDate, []);
    }

    messageMap.get(formattedDate)?.push(message);
  });

  const formattedmessages = [];
  for (const [date, messages] of messageMap) {
    formattedmessages.push(date);
    formattedmessages.push(...messages);
  }

  return formattedmessages;
};
