import { useSelector } from 'react-redux';
import {
  useQuery,
  DocumentNode,
  ApolloError,
  ApolloQueryResult,
  OperationVariables,
} from '@apollo/client';
import { RootState } from '../../../../store/store';
import { PaginationWithBooleanInput } from '../../../../types/schema/utils.types';
import { Testimonial } from '../../../../types/schema/testimonial.types';

interface GetAllTestimonialsByPageHook {
  data: Testimonial[] | undefined;
  loading: boolean;
  error: ApolloError | undefined;
  refetch: (variables?: Partial<OperationVariables> | undefined) => Promise<
    ApolloQueryResult<{
      getAllTestimonialsByPage: Testimonial[];
    }>
  >;
}

interface UseGetAllTestimonialsByPageMutationData {
  getAllTestimonialsByPage: Testimonial[];
}

interface UseGetAllTestimonialsByPageInputType {
  input: PaginationWithBooleanInput;
}

export function useGetAllTestimonialsByPage(
  query: DocumentNode,
  input: PaginationWithBooleanInput
): GetAllTestimonialsByPageHook {
  const { jwt } = useSelector((state: RootState) => state.auth);

  const { data, loading, error, refetch } = useQuery<
    UseGetAllTestimonialsByPageMutationData,
    UseGetAllTestimonialsByPageInputType
  >(query, {
    variables: {
      input,
    },
    context: {
      headers: {
        cookie: `jwt=${jwt}`,
        authorization: jwt ? `Bearer ${jwt}` : '',
      },
    },
  });

  return {
    data: data?.getAllTestimonialsByPage,
    loading,
    error,
    refetch,
  };
}
