import * as React from 'react';
import { alpha, styled } from '@mui/material/styles';
import { DataGrid, gridClasses } from '@mui/x-data-grid';
import { GridColDef } from '@mui/x-data-grid';
import moment from 'moment';
import { gql } from '@apollo/client';
import { formatString, getAmountWithCurrency } from '../../utils/functions';
import { useDeleteCustomer } from '../../hooks/gql/mutation/delete/useDeleteCustomer';
import { useGetAllCancellationsByPage } from '../../hooks/gql/query/getAll/useGetAllCancellationsByPage';
import ActionEyeButton from '../../components/Utils/ActionEyeButton';
import { useGetAllQuotationsByPage } from '../../hooks/gql/query/getAll/useGetAllQuotationsByPage';

const quotationTableState = {
  initialState: {
    columns: {
      columnVisibilityModel: {
        id: false,
        quotation_by: true,
        quotation_price: true,
        quotation_type: true,
        pickup_location: true,
        destination: true,
        createdAt: true,
        actions: true,
      },
    },
  },
};

const quotationTableColumn: GridColDef[] = [
  { field: 'id', headerClassName: 'quotation_table_header' },
  {
    field: 'quotation_by',
    headerName: 'Quotation By',
    minWidth: 150,
    flex: 1,
    headerAlign: 'left',
    align: 'left',
    valueGetter: (params) => params.row?.quotation_by?.contact_name,
    headerClassName: 'quotation_table_header',
  },
  {
    field: 'quotation_price',
    headerName: 'Price',
    minWidth: 120,
    flex: 1,
    headerAlign: 'center',
    valueGetter: (params) => getAmountWithCurrency(params.row?.quotation_price),
    align: 'center',
    headerClassName: 'quotation_table_header',
  },
  {
    field: 'quotation_type',
    headerName: 'Type',
    minWidth: 150,
    flex: 1,
    headerAlign: 'center',
    valueGetter: (params) => formatString(params.row?.quotation_type),
    align: 'center',
    headerClassName: 'quotation_table_header',
  },
  {
    field: 'pickup_location',
    headerName: 'Pickup',
    flex: 1,
    minWidth: 120,
    headerAlign: 'center',
    valueGetter: (params) =>
      params.row?.trip_info?.pickup_location?.location_name || '---',
    headerClassName: 'quotation_table_header',
  },
  {
    field: 'destination',
    headerName: 'Destination',
    flex: 1,
    minWidth: 120,
    valueGetter: (params) =>
      params.row?.trip_info?.destination?.location_name || '---',
    headerAlign: 'center',
    headerClassName: 'quotation_table_header',
  },
];

const ODD_OPACITY = 0.2;

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.odd`]: {
    backgroundColor: '#F4F6F6',
    '&:hover, &.Mui-hovered': {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
    '&.Mui-selected': {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity
      ),
      '&:hover, &.Mui-hovered': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity
        ),
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  },
}));

const GET_ALL_QUOTATIONS = gql`
  query GetAllQuotationsByPage($input: PaginationInput!) {
    getAllQuotationsByPage(input: $input) {
      _id
      quotation_by {
        _id
        company_name
        contact_name
      }
      quotation_type
      quotation_variant
      quotation_price
      createdAt
      trip_info {
        pickup_location {
          location_name
        }
        destination {
          location_name
        }
      }
    }
  }
`;

const QuotationsPage: React.FC<{}> = (props) => {
  const {
    data: quotations,
    error: quotationsError,
    loading: quotationsLoading,
    refetch: quotationsRefectch,
  } = useGetAllQuotationsByPage(GET_ALL_QUOTATIONS, {
    page: 1,
    item_per_page: 1000,
  });

  const quotations_row =
    quotations?.map((c) => {
      return { ...c, id: c._id, actions: 'V' };
    }) || [];

  return (
    <>
      <div style={{ height: 631, width: '100%', marginBottom: 150 }}>
        <StripedDataGrid
          pageSizeOptions={[10, 50, 100]}
          columns={[
            ...quotationTableColumn,
            {
              field: 'createdAt',
              headerName: 'Date',
              flex: 1,
              minWidth: 180,
              headerAlign: 'center',
              align: 'center',
              headerClassName: 'quotation_table_header',
              renderCell: (params) => {
                const data = params.row;
                return (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <p>{moment(data.createdAt).format('lll')}</p>
                  </div>
                );
              },
            },
            {
              field: 'action',
              headerName: 'Action',
              maxWidth: 70,
              minWidth: 70,
              headerAlign: 'center',
              align: 'center',
              sortable: false,
              filterable: false,
              headerClassName: 'quotation_table_header',
              renderCell: (params) => {
                const data = params.row;
                return (
                  <div>
                    <ActionEyeButton
                      id={data.id}
                      onEyeClick={(_id) => {
                        alert('More details not available on this phase!');
                      }}
                    />
                  </div>
                );
              },
            },
          ]}
          rows={quotations_row}
          initialState={{
            ...quotationTableState.initialState,
            pagination: { paginationModel: { pageSize: 10 } },
          }}
          pagination
          sx={{
            '& .quotation_table_header': {
              backgroundColor: '#F3F8FF',
            },
          }}
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
          }
        />
      </div>
    </>
  );
};

export default QuotationsPage;
