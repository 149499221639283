import { useSelector } from 'react-redux';
import {
  useQuery,
  DocumentNode,
  ApolloError,
  ApolloQueryResult,
  OperationVariables,
} from '@apollo/client';
import { RootState } from '../../../../store/store';
import { MongoIdInput } from '../../../../types/schema/utils.types';
import { User } from '../../../../types/schema/user.types';

interface GetAllContactsByUserIdHook {
  data: User[] | undefined;
  loading: boolean;
  error: ApolloError | undefined;
  refetch: (variables?: Partial<OperationVariables> | undefined) => Promise<
    ApolloQueryResult<{
      getAllContactsByUserId: User[];
    }>
  >;
}

interface GetAllContactsByUserIdMutationData {
  getAllContactsByUserId: User[];
}

interface GetAllContactsByUserIdInputType {
  input: MongoIdInput;
}

export function useGetAllContactsByUserId(
  query: DocumentNode,
  input: MongoIdInput
): GetAllContactsByUserIdHook {
  const { jwt } = useSelector((state: RootState) => state.auth);

  const { data, loading, error, refetch } = useQuery<
    GetAllContactsByUserIdMutationData,
    GetAllContactsByUserIdInputType
  >(query, {
    variables: {
      input,
    },
    context: {
      headers: {
        cookie: `jwt=${jwt}`,
        authorization: jwt ? `Bearer ${jwt}` : '',
      },
    },
  });

  return {
    data: data?.getAllContactsByUserId,
    loading,
    error,
    refetch,
  };
}
