import { useSelector } from 'react-redux';
import {
  useQuery,
  DocumentNode,
  ApolloError,
  ApolloQueryResult,
  OperationVariables,
} from '@apollo/client';
import { RootState } from '../../../../store/store';
import { MongoIdInput } from '../../../../types/schema/utils.types';
import { Cover } from '../../../../types/schema/cover.types';

interface GetCoverDetailsByIdHook {
  data: Cover | undefined;
  loading: boolean;
  error: ApolloError | undefined;
  refetch: (variables?: Partial<OperationVariables> | undefined) => Promise<
    ApolloQueryResult<{
      getCoverDetailsById: Cover;
    }>
  >;
}

interface GetCoverDetailsByIdMutationData {
  getCoverDetailsById: Cover;
}

interface GetCoverDetailsByIdInputType {
  input: MongoIdInput;
}

export function useGetCoverDetailsById(
  query: DocumentNode,
  input: MongoIdInput
): GetCoverDetailsByIdHook {
  const { jwt } = useSelector((state: RootState) => state.auth);

  const { data, loading, error, refetch } = useQuery<
    GetCoverDetailsByIdMutationData,
    GetCoverDetailsByIdInputType
  >(query, {
    variables: {
      input,
    },
    context: {
      headers: {
        cookie: `jwt=${jwt}`,
        authorization: jwt ? `Bearer ${jwt}` : '',
      },
    },
  });

  return {
    data: data?.getCoverDetailsById,
    loading,
    error,
    refetch,
  };
}
