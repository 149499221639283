import * as React from 'react';
import { alpha, styled } from '@mui/material/styles';
import { DataGrid, gridClasses } from '@mui/x-data-grid';
import { GridColDef } from '@mui/x-data-grid';
import Colors from '../../constants/Colors';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { gql } from '@apollo/client';
import { formatString, getAmountWithCurrency } from '../../utils/functions';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useEffect } from 'react';
import ActionButtonsPayments from '../../components/Utils/ActionButtonsPayments';
import { useGetAllPaymentsByRangeByPage } from '../../hooks/gql/query/getAll/useGetAllPaymentsByRangeByPage';
import { RangeTypeEnum } from '../../types/schema/utils.types';

const GET_ALL_PAYMENTS_BY_RANGE_BY_PAGE = gql`
  query GetAllPaymentsByRangeByPage($input: PaginationWithRangeInput!) {
    getAllPaymentsByRangeByPage(input: $input) {
      _id
      admin_revenue
      amount_paid
      createdAt
      payment_status
      payment_type
      referrer_revenue
    }
  }
`;

const paymentTableState = {
  initialState: {
    columns: {
      columnVisibilityModel: {
        id: false,
        payment_type: true,
        amount_paid: true,
        admin_revenue: true,
        referrer_revenue: true,
        createdAt: true,
        payment_status: true,
        actions: true,
      },
    },
  },
};

const paymentTableColumn: GridColDef[] = [
  { field: 'id', headerClassName: 'payments_table_header' },
];

const ODD_OPACITY = 0.2;

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.odd`]: {
    backgroundColor: '#F4F6F6',
    '&:hover, &.Mui-hovered': {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
    '&.Mui-selected': {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity
      ),
      '&:hover, &.Mui-hovered': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity
        ),
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  },
}));

const PaymentsPage: React.FC<{}> = (props) => {
  const [range, setRange] = React.useState<RangeTypeEnum>('all');
  const navigate = useNavigate();

  const {
    data: payments,
    error: paymentsError,
    loading: paymentsLoading,
    refetch: paymentsRefectch,
  } = useGetAllPaymentsByRangeByPage(GET_ALL_PAYMENTS_BY_RANGE_BY_PAGE, {
    page: 1,
    item_per_page: 1000,
    range,
  });

  const payments_row =
    payments?.map((b) => {
      return { ...b, id: b._id, actions: 'V' };
    }) || [];

  useEffect(() => {
    paymentsRefectch();
  }, [range]);

  return (
    <>
      <div style={{ height: 631, width: '100%', marginBottom: 150 }}>
        <p style={{ textAlign: 'center' }}>
          This payments page is listing all the payments that is associated
          directly with platform revenue. The payments coming from upfront
          payment, Operator Topup. And payment goes out from the platform
          revinue when user asking for payout from their wallet.
        </p>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
          }}>
          <FormControl>
            <Select
              id='demo-simple-select'
              value={range}
              onChange={(event: SelectChangeEvent) => {
                setRange(event.target.value as RangeTypeEnum);
              }}>
              <MenuItem value={'all'}>All</MenuItem>
              <MenuItem value={'week'}>This week</MenuItem>
              <MenuItem value={'month'}>This month</MenuItem>
              <MenuItem value={'year'}>This year</MenuItem>
            </Select>
          </FormControl>
        </div>
        <StripedDataGrid
          pageSizeOptions={[10, 50, 100]}
          columns={[
            ...paymentTableColumn,
            {
              field: 'payment_type',
              headerName: 'Indentifier',
              flex: 1,
              minWidth: 150,
              headerAlign: 'center',
              align: 'center',
              headerClassName: 'payments_table_header',
              renderCell: (params) => {
                const data = params.row;
                return (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <p>{formatString(data?.payment_type)}</p>
                  </div>
                );
              },
            },
            {
              field: 'amount_paid',
              headerName: 'Amount Paid',
              flex: 1,
              minWidth: 150,
              headerAlign: 'center',
              align: 'center',
              headerClassName: 'payments_table_header',
              renderCell: (params) => {
                const data = params.row;
                return (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <p>{getAmountWithCurrency(data?.amount_paid)}</p>
                  </div>
                );
              },
            },
            {
              field: 'admin_revenue',
              headerName: 'Admin Revenue',
              flex: 1,
              minWidth: 150,
              headerAlign: 'center',
              align: 'center',
              headerClassName: 'payments_table_header',
              renderCell: (params) => {
                const data = params.row;
                return (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <p
                      style={{
                        color:
                          data?.admin_revenue < 1
                            ? Colors.accentColorDark
                            : Colors.primaryColorLight,
                      }}>
                      {getAmountWithCurrency(data?.admin_revenue)}
                    </p>
                  </div>
                );
              },
            },
            {
              field: 'referrer_revenue',
              headerName: 'Referrer Revenue',
              flex: 1,
              minWidth: 150,
              headerAlign: 'center',
              align: 'center',
              headerClassName: 'payments_table_header',
              renderCell: (params) => {
                const data = params.row;
                return (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <p
                      style={{
                        color:
                          data?.referrer_revenue > 0
                            ? Colors.accentColorDark
                            : Colors.fontColor2,
                      }}>
                      {getAmountWithCurrency(data?.referrer_revenue)}
                    </p>
                  </div>
                );
              },
            },
            {
              field: 'createdAt',
              headerName: 'Date and Time',
              flex: 1,
              minWidth: 180,
              headerAlign: 'center',
              align: 'center',
              headerClassName: 'payments_table_header',
              renderCell: (params) => {
                const data = params.row;
                return (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <p>
                      {moment(data?.createdAt).format('MMM DD, YYYY hh:mm A')}
                    </p>
                  </div>
                );
              },
            },
            {
              field: 'payment_status',
              headerName: 'Status',
              flex: 1,
              minWidth: 100,
              headerAlign: 'center',
              align: 'center',
              headerClassName: 'payments_table_header',
              renderCell: (params) => {
                const data = params.row;
                return (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <p
                      style={{
                        backgroundColor:
                          data?.payment_status === 'pending'
                            ? Colors.accentColorDark
                            : Colors.pclXLight,
                        color:
                          data?.payment_status === 'pending'
                            ? Colors.light
                            : Colors.primaryColorLight,
                        paddingTop: 3,
                        paddingBottom: 3,
                        paddingLeft: 10,
                        paddingRight: 10,
                        borderRadius: 100,
                      }}>
                      {formatString(data?.payment_status)}
                    </p>
                  </div>
                );
              },
            },

            {
              field: 'actions',
              headerName: 'Action',
              maxWidth: 100,
              headerAlign: 'center',
              align: 'center',
              sortable: false,
              filterable: false,
              headerClassName: 'payments_table_header',
              renderCell: (params) => {
                const data = params.row;
                return (
                  <div>
                    <ActionButtonsPayments
                      id={data.id}
                      onEyeClick={(id) => {
                        navigate(`/payments/${id}`);
                      }}
                    />
                  </div>
                );
              },
            },
          ]}
          rows={payments_row}
          initialState={{
            ...paymentTableState.initialState,
            pagination: { paginationModel: { pageSize: 10 } },
          }}
          pagination
          sx={{
            '& .payments_table_header': {
              backgroundColor: '#F3F8FF',
            },
          }}
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
          }
        />
      </div>
    </>
  );
};
export default PaymentsPage;
