import { useSelector } from 'react-redux';
import {
  useQuery,
  DocumentNode,
  ApolloError,
  ApolloQueryResult,
  OperationVariables,
} from '@apollo/client';
import { RootState } from '../../../../store/store';
import { PaginationInput } from '../../../../types/schema/utils.types';
import { User } from '../../../../types/schema/user.types';

interface GetAllUsersByPageHook {
  data: User[] | undefined;
  loading: boolean;
  error: ApolloError | undefined;
  refetch: (variables?: Partial<OperationVariables> | undefined) => Promise<
    ApolloQueryResult<{
      getAllUsersByPage: User[];
    }>
  >;
}

interface GetAllUsersByPageMutationData {
  getAllUsersByPage: User[];
}

interface GetAllUsersByPageInputType {
  input: PaginationInput;
}

export function useGetAllUsersByPage(
  query: DocumentNode,
  input: PaginationInput
): GetAllUsersByPageHook {
  const { jwt } = useSelector((state: RootState) => state.auth);

  const { data, loading, error, refetch } = useQuery<
    GetAllUsersByPageMutationData,
    GetAllUsersByPageInputType
  >(query, {
    variables: {
      input,
    },
    context: {
      headers: {
        cookie: `jwt=${jwt}`,
        authorization: jwt ? `Bearer ${jwt}` : '',
      },
    },
  });

  return {
    data: data?.getAllUsersByPage,
    loading,
    error,
    refetch,
  };
}
