import { Button, FormControl } from '@mui/material';
import Colors from '../../constants/Colors';
import './PayoutRequestsDetailsPage.scss';
import { gql } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import { formatString, getAmountWithCurrency } from '../../utils/functions';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import moment from 'moment';
import { useUpdatePayment } from '../../hooks/gql/mutation/update/useUpdatePayment';
import { useGetPaymentDetailsById } from '../../hooks/gql/query/getById/useGetPaymentDetailsById';
import { useUpdatePaymentStatus } from '../../hooks/gql/mutation/update/useUpdatePaymentStatus';

const GET_PAYMENT_DETAILS_BY_ID = gql`
  query GetPaymentDetailsById($input: MongoIdInput!) {
    getPaymentDetailsById(input: $input) {
      _id
      admin_revenue
      amount_paid
      net_revenue
      createdAt
      payment_status
      payment_type
      payment_id
      referrer_revenue
      square_charge
      company_ref {
        _id
        company_name
        contact_name
        email
        phone
        photo
        isPhotoVisible
        referral_code
        receivingBank {
          account_name
          account_number
          bank_name
          sort_code
        }
      }
      customer_ref {
        _id
        name
        phone_number
        email
        traveller_id
      }
      booking_ref {
        _id
        booking_price
        booking_token
        booking_type
        trip_info {
          destination {
            location_name
          }
          pickup_datetime
          return_datetime
          pickup_location {
            location_name
          }
          travelling_reason
          tripType
          vehicle_count
          vehicle_type
          passenger_count
          note
          luggage_type
        }
      }
      referrer_ref {
        _id
        company_name
        isPhotoVisible
        photo
        referral_code
      }
    }
  }
`;

const PayoutRequestsDetailsPage: React.FC<{}> = (props) => {
  const navigate = useNavigate();
  const { slug } = useParams();

  const { updatePaymentStatus } = useUpdatePaymentStatus();

  const {
    data: payment,
    error: paymentError,
    loading: paymentLoading,
    refetch: paymentRefetch,
  } = useGetPaymentDetailsById(GET_PAYMENT_DETAILS_BY_ID, {
    _id: slug || '64f8267c7ed1f6aa0166bde1',
  });

  const referrer_info = payment?.referrer_ref;
  const customer_info = payment?.customer_ref;
  const company_info = payment?.company_ref;
  const booking_info = payment?.booking_ref;
  const trip_info = booking_info?.trip_info;

  return (
    <>
      <div className='payouts_details_wrapper'>
        <div className='payout_details_section'>
          {payment && (
            <div className='payout_details_wrapper'>
              <h2>Main Payment Details</h2>
              <div className='payout_details_section_det1'>
                <div className='payout_details_section_det1_p1'>
                  <div className=''>
                    <p>Transected Amount</p>
                    <p
                      style={{
                        fontWeight: 'bold',
                        color: Colors.primaryColorLight,
                        fontSize: 25,
                      }}>
                      {getAmountWithCurrency(payment.amount_paid)}
                    </p>
                  </div>

                  <div className=''>
                    <p>SQ Payment ID</p>
                    <p style={{ fontWeight: 'bold' }}>{payment?.payment_id}</p>
                  </div>
                </div>
                <div className='payout_details_section_det1_p2'>
                  <div className=''>
                    <p>Payment Date</p>
                    <p style={{ fontWeight: 'bold' }}>
                      {moment(payment.createdAt).format('lll')}
                    </p>
                  </div>

                  <div className=''>
                    <p>Payment Method</p>
                    <p style={{ fontWeight: 'bold' }}>Manual Request</p>
                  </div>
                </div>
              </div>
              <div className='payout_details_section_det2'>
                <div className='payout_details_section_det2_p1'>
                  <div className=''>
                    <p>Square Charge</p>
                    <p style={{ fontWeight: 'bold' }}>
                      {getAmountWithCurrency(payment.square_charge)}
                    </p>
                  </div>

                  <div className=''>
                    <p>Net Revenue</p>
                    <p
                      style={{
                        fontWeight: 'bold',
                        color: Colors.primaryColorLight,
                      }}>
                      {getAmountWithCurrency(payment.net_revenue)}
                    </p>
                  </div>
                </div>
                <div className='payout_details_section_det2_p2'>
                  <div className=''>
                    <p>Status</p>
                    <p
                      style={{
                        fontWeight: 'bold',
                        color: Colors.primaryColorLight,
                      }}>
                      {formatString(payment.payment_status)}
                    </p>
                  </div>

                  <div className=''>
                    <p>Invoiced Amount</p>
                    <p
                      style={{
                        fontWeight: 'bold',
                      }}>
                      {getAmountWithCurrency(payment.amount_paid)}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}

          {payment && company_info && (
            <div className='payout_details_wrapper'>
              <h2>Operator Payout</h2>
              <div className='payout_details_section_det1'>
                <div className='payout_details_section_det1_p1'>
                  <div className=''>
                    <p>Amount to Pay</p>
                    <p
                      style={{
                        fontWeight: 'bold',
                        color: Colors.primaryColorLight,
                        fontSize: 25,
                      }}>
                      {getAmountWithCurrency(payment.amount_paid)}
                    </p>
                  </div>

                  <div className=''>
                    <p>Bank Holder Name</p>
                    <div className='content_copy'>
                      <p style={{ fontWeight: 'bold' }}>
                        {company_info.receivingBank.account_name}
                      </p>
                      <ContentCopyOutlinedIcon
                        fontSize='small'
                        color='primary'
                        style={{ marginLeft: 10, cursor: 'pointer' }}
                        onClick={(e) => {
                          navigator.clipboard.writeText(
                            company_info.receivingBank.account_name
                          );
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className='payout_details_section_det1_p2'>
                  <div className=''>
                    <p>Bank Account Number</p>
                    <div className='content_copy'>
                      <p style={{ fontWeight: 'bold' }}>
                        {company_info.receivingBank.account_number}
                      </p>
                      <ContentCopyOutlinedIcon
                        fontSize='small'
                        color='primary'
                        style={{ marginLeft: 10, cursor: 'pointer' }}
                        onClick={(e) => {
                          navigator.clipboard.writeText(
                            company_info.receivingBank.account_number
                          );
                        }}
                      />
                    </div>
                  </div>

                  <div className=''>
                    <p>Sort Code</p>
                    <div className='content_copy'>
                      <p style={{ fontWeight: 'bold' }}>
                        {company_info.receivingBank.sort_code}
                      </p>
                      <ContentCopyOutlinedIcon
                        fontSize='small'
                        color='primary'
                        style={{ marginLeft: 10, cursor: 'pointer' }}
                        onClick={(e) => {
                          navigator.clipboard.writeText(
                            company_info.receivingBank.sort_code
                          );
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='payout_details_section_det2'>
                <div className='payout_details_section_det2_p1'>
                  <div className=''>
                    <p>Company Name</p>
                    <p style={{ fontWeight: 'bold' }}>
                      {company_info.company_name}
                    </p>
                  </div>

                  <div className=''>
                    <p>Contact Name</p>
                    <p
                      style={{
                        fontWeight: 'bold',
                      }}>
                      {company_info.contact_name}
                    </p>
                  </div>
                </div>
                <div className='payout_details_section_det2_p2'>
                  <div className=''>
                    <p>Phone</p>
                    <p
                      style={{
                        fontWeight: 'bold',
                      }}>
                      {company_info.phone}
                    </p>
                  </div>

                  <div className=''>
                    <p>Email</p>
                    <p
                      style={{
                        fontWeight: 'bold',
                      }}>
                      {company_info.email}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}

          {payment && (
            <div className='customer_details_wrapper'>
              <div className='payout_details_section_det3'></div>

              <div className='payout_details_section_det4'>
                <div className='payout_details_section_det4_p1'></div>
                <div className='payout_details_section_det4_p2'>
                  <div className=''>
                    <FormControl
                      sx={{ m: 1, width: '11ch' }}
                      variant='standard'>
                      <Button
                        disabled={payment.payment_status === 'approved'}
                        variant='contained'
                        size='small'
                        color='info'
                        onClick={async () => {
                          await updatePaymentStatus({
                            _id: payment._id,
                            payment_status: 'approved',
                            syncTransaction: true,
                          });

                          paymentRefetch();
                        }}>
                        <p style={{ marginTop: 3, color: '#fff' }}>MARK PAID</p>
                      </Button>
                    </FormControl>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className='footer_section'></div>
    </>
  );
};
export default PayoutRequestsDetailsPage;
