import React from 'react';
import Sidebar from './Sidebar';
import Colors from '../../constants/Colors';

const MainNavigation: React.FC<{
  collapsed: boolean;
  toggled: boolean;
  broken: boolean;
  onCollapsed: (status: boolean) => void;
  onToggled: (status: boolean) => void;
  onBroken: (status: boolean) => void;
}> = ({ collapsed, toggled, broken, onBroken, onCollapsed, onToggled }) => {
  return (
    <header
      style={{ display: 'flex', height: '100vh', background: Colors.lightBg }}>
      <Sidebar
        collapsed={collapsed}
        onCollapsed={onCollapsed}
        toggled={toggled}
        onToggled={onToggled}
        broken={broken}
        onBroken={onBroken}
      />
    </header>
  );
};

export default MainNavigation;
