import { getAmountWithCurrency, getNameAvatar } from '../../utils/functions';
import './CompanyListItem.scss';
import { Avatar } from '@mui/material';
import Colors from '../../constants/Colors';
import { gBaseUrl } from '../../utils/config';

const CompanyListItem: React.FC<{
  _id: string;
  company_name: string;
  referral_code: string;
  photo?: string;
  isPhotoVisible?: boolean;
  counter?: number;
  isAmount?: boolean;
  isCVisible?: boolean;
}> = ({
  _id,
  company_name,
  referral_code,
  photo,
  isPhotoVisible,
  counter,
  isAmount = false,
  isCVisible = false,
}) => {
  return (
    <div className='company_cards_item'>
      <div className='company_cards_item_info'>
        {isPhotoVisible ? (
          <Avatar
            src={`${gBaseUrl}/photos/avatars/${photo}`}
            sx={{
              bgcolor: Colors.pclXLight,
              marginRight: 1,
              color: Colors.primaryColorLight,
              boxShadow: '0px 10px 15px -3px rgba(0, 0, 0, 0.1)',
            }}
          />
        ) : (
          <Avatar
            sx={{
              width: 40,
              height: 40,
              bgcolor: Colors.pclXLight,
              marginRight: 1,
              color: Colors.primaryColorLight,
              boxShadow: '0px 10px 15px -3px rgba(0, 0, 0, 0.1)',
            }}>
            {getNameAvatar(company_name)}
          </Avatar>
        )}
        <div className='business_info'>
          <p className='business_name'>{company_name}</p>
          <p className='business_id'>#{referral_code}</p>
        </div>
      </div>
      {isCVisible && counter && (
        <div className='company_cards_item_rank'>
          <p>{isAmount ? getAmountWithCurrency(counter) : counter}</p>
        </div>
      )}
    </div>
  );
};

export default CompanyListItem;
