import { RootState } from '../../../../store/store';
import { useSelector } from 'react-redux';
import { gql, useMutation } from '@apollo/client';
import { MongoIdInput } from '../../../../types/schema/utils.types';
import { Testimonial } from '../../../../types/schema/testimonial.types';

const GQL_MUT = gql`
  mutation DeleteTestimonial($input: MongoIdInput!) {
    deleteTestimonial(input: $input) {
      _id
    }
  }
`;

// Define the types for the mutation response and input
interface DeleteTestimonialMutationData {
  deleteTestimonial: Testimonial;
}

interface DeleteTestimonialInputType {
  input: MongoIdInput;
}

export const useDeleteTestimonial = () => {
  const { jwt } = useSelector((state: RootState) => state.auth);

  const [deleteTestimonialGql, { data, error, loading }] = useMutation<
    DeleteTestimonialMutationData,
    DeleteTestimonialInputType
  >(GQL_MUT);

  const deleteTestimonial = async (input: MongoIdInput) => {
    const { data, errors } = await deleteTestimonialGql({
      variables: {
        input: input,
      },
      context: {
        headers: {
          cookie: `jwt=${jwt}`,
          authorization: jwt ? `Bearer ${jwt}` : '',
        },
      },
    });

    return {
      deleteTestimonial: data?.deleteTestimonial,
      error: errors ? errors[0].message : null,
    };
  };

  return { deleteTestimonial, data, error, loading };
};
