import { RootState } from '../../../../store/store';
import { useSelector } from 'react-redux';
import { gql, useMutation } from '@apollo/client';
import {
  Testimonial,
  UpdateTestimonialInput,
} from '../../../../types/schema/testimonial.types';

const GQL_MUT = gql`
  mutation UpdateTestimonialByAdmin($input: UpdateTestimonialInput!) {
    updateTestimonialByAdmin(input: $input) {
      _id
    }
  }
`;

// Define the types for the mutation response and input
interface UpdateTestimonialByAdminMutationData {
  updateTestimonialByAdmin: Testimonial;
}

interface UpdateTestimonialByAdminInputType {
  input: UpdateTestimonialInput;
}

export const useUpdateTestimonialByAdmin = () => {
  const { jwt } = useSelector((state: RootState) => state.auth);

  const [updateTestimonialByAdminGql, { data, error, loading }] = useMutation<
    UpdateTestimonialByAdminMutationData,
    UpdateTestimonialByAdminInputType
  >(GQL_MUT);

  const updateTestimonialByAdmin = async (input: UpdateTestimonialInput) => {
    const { data, errors } = await updateTestimonialByAdminGql({
      variables: {
        input: input,
      },
      context: {
        headers: {
          cookie: `jwt=${jwt}`,
          authorization: jwt ? `Bearer ${jwt}` : '',
        },
      },
    });

    return {
      updateTestimonialByAdmin: data?.updateTestimonialByAdmin,
      error: errors ? errors[0].message : null,
    };
  };

  return { updateTestimonialByAdmin, data, error, loading };
};
