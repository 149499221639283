import React from 'react';
import './Sidebar.scss';
import { Sidebar as SidebarPro, Menu, MenuItem } from 'react-pro-sidebar';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import PieChartOutlinedIcon from '@mui/icons-material/PieChartOutlined';
import CurrencyPoundOutlinedIcon from '@mui/icons-material/CurrencyPoundOutlined';
import StarHalfOutlinedIcon from '@mui/icons-material/StarHalfOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import IconButton from '@mui/material/IconButton';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { resetAuth } from '../../store/reducer/auth';
import { useDispatch } from 'react-redux';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import SupportAgentOutlinedIcon from '@mui/icons-material/SupportAgentOutlined';
import ThreePOutlinedIcon from '@mui/icons-material/ThreePOutlined';
import ReviewsOutlinedIcon from '@mui/icons-material/ReviewsOutlined';
import PaymentOutlinedIcon from '@mui/icons-material/PaymentOutlined';
import DirectionsBusOutlinedIcon from '@mui/icons-material/DirectionsBusOutlined';
import AccessibilityNewOutlinedIcon from '@mui/icons-material/AccessibilityNewOutlined';
import FormatQuoteOutlinedIcon from '@mui/icons-material/FormatQuoteOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';

const Sidebar: React.FC<{
  collapsed: boolean;
  toggled: boolean;
  broken: boolean;
  onCollapsed: (status: boolean) => void;
  onToggled: (status: boolean) => void;
  onBroken: (status: boolean) => void;
}> = ({ collapsed, toggled, broken, onBroken, onCollapsed, onToggled }) => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <SidebarPro
      collapsed={collapsed}
      toggled={toggled}
      onBackdropClick={() => onToggled(false)}
      onBreakPoint={onBroken}
      style={{ backgroundColor: '#F3F8FF' }}
      breakPoint='md'>
      <Menu
        menuItemStyles={{
          button: ({ level, active, disabled }) => {
            // only apply styles on first level elements of the tree

            if (level === 0) {
              return {
                color: disabled ? '#F3F8FF' : '#000',
                backgroundColor: active ? '#C7DEFF' : undefined,
                [`&:hover`]: {
                  backgroundColor: '#C7DEFF',
                },
              };
            }
          },
        }}>
        <div
          className='sidebar_menu_wrapper'
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100vh',
          }}>
          <div className='sidebar_menu' style={{}}>
            <div
              className='menu_header'
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                height: 50,
              }}>
              {!collapsed && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    minWidth: 50,
                  }}>
                  <img
                    style={{ width: 50, height: 50, marginLeft: 15 }}
                    src='/images/main_logo.png'
                    alt='Logo'
                  />
                  <p>FullyBooked</p>
                </div>
              )}

              <div className='hamburger'>
                <IconButton
                  color='primary'
                  aria-label='menu'
                  onClick={() => onCollapsed(!collapsed)}>
                  <MenuOutlinedIcon />
                </IconButton>
              </div>
            </div>

            <div className='menuitem_wrapper' style={{ flex: 1 }}>
              <MenuItem
                active={pathname.includes('/dashboard')}
                icon={<DashboardOutlinedIcon />}
                component={<Link to={'/dashboard'} />}>
                Dashboard
              </MenuItem>

              <MenuItem
                active={pathname.includes('/companies')}
                icon={<BusinessOutlinedIcon />}
                component={<Link to={'/companies'} />}>
                Companies
              </MenuItem>

              <MenuItem
                active={pathname.includes('/customers')}
                icon={<PeopleAltOutlinedIcon />}
                component={<Link to={'/customers'} />}>
                Customers
              </MenuItem>

              <MenuItem
                active={pathname.includes('/users-approval')}
                icon={<PeopleAltOutlinedIcon />}
                component={<Link to={'/users-approval'} />}>
                User Approvals
              </MenuItem>

              <MenuItem
                active={pathname.includes('/company-messages')}
                icon={<ThreePOutlinedIcon />}
                component={<Link to={'/company-messages'} />}>
                Company Messages
              </MenuItem>

              <MenuItem
                active={pathname.includes('/customer-messages')}
                icon={<SupportAgentOutlinedIcon />}
                component={<Link to={'/customer-messages'} />}>
                Customer Messages
              </MenuItem>

              <MenuItem
                active={pathname.includes('/payout-requests')}
                icon={<PaymentsOutlinedIcon />}
                component={<Link to={'/payout-requests'} />}>
                Payout Request
              </MenuItem>

              <MenuItem
                active={pathname.includes('/in-app-wallets')}
                icon={<PieChartOutlinedIcon />}
                component={<Link to={'/in-app-wallets'} />}>
                In App Wallets
              </MenuItem>

              <MenuItem
                active={pathname.includes('/payments')}
                icon={<PaymentOutlinedIcon />}
                component={<Link to={'/payments'} />}>
                Payments
              </MenuItem>

              <MenuItem
                active={pathname.includes('/bookings')}
                icon={<DirectionsBusOutlinedIcon />}
                component={<Link to={'/bookings'} />}>
                Bookings
              </MenuItem>

              <MenuItem
                active={pathname.includes('/get-covers')}
                icon={<AccessibilityNewOutlinedIcon />}
                component={<Link to={'/get-covers'} />}>
                Get Covers
              </MenuItem>

              <MenuItem
                active={pathname.includes('/quotations')}
                icon={<FormatQuoteOutlinedIcon />}
                component={<Link to={'/quotations'} />}>
                Quotations
              </MenuItem>

              <MenuItem
                active={pathname.includes('/cancellations')}
                icon={<CancelOutlinedIcon />}
                component={<Link to={'/cancellations'} />}>
                Cancellations
              </MenuItem>

              <MenuItem
                active={pathname.includes('/referrals')}
                icon={<ShareOutlinedIcon />}
                component={<Link to={'/referrals'} />}>
                Referrals
              </MenuItem>

              <MenuItem
                active={pathname.includes('/testimonials')}
                icon={<StarHalfOutlinedIcon />}
                component={<Link to={'/testimonials'} />}>
                Testimonials
              </MenuItem>

              <MenuItem
                active={pathname.includes('/customer-reviews')}
                icon={<ReviewsOutlinedIcon />}
                component={<Link to={'/customer-reviews'} />}>
                Customer Reviews
              </MenuItem>

              <MenuItem
                active={pathname.includes('/settings')}
                icon={<SettingsOutlinedIcon />}
                component={<Link to={'/settings'} />}>
                Settings
              </MenuItem>
            </div>
          </div>

          <div className='sidebar_footer'>
            <div style={{}}>
              {!collapsed && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginLeft: 20,
                    height: 50,
                  }}>
                  <IconButton
                    aria-label='menu'
                    style={{
                      fontFamily: 'inherit',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderRadius: 10,
                      height: 30,
                    }}
                    onClick={() => {
                      dispatch(resetAuth());
                      navigate('/login');
                    }}>
                    <LogoutOutlinedIcon
                      style={{ marginRight: 10, marginTop: 3, color: '#000' }}
                    />
                    <p style={{ color: '#232D42' }}> Logout</p>
                  </IconButton>
                </div>
              )}

              {collapsed && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginLeft: 20,
                    height: 50,
                  }}>
                  <IconButton
                    aria-label='menu'
                    style={{
                      fontFamily: 'inherit',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                    onClick={() => {
                      dispatch(resetAuth());
                      navigate('/login');
                    }}>
                    <LogoutOutlinedIcon
                      style={{ marginRight: 10, marginTop: 3 }}
                    />
                  </IconButton>
                </div>
              )}
            </div>
          </div>
        </div>
      </Menu>
    </SidebarPro>
  );
};

export default Sidebar;
