import { Link } from 'react-router-dom';

import './ErrorPage.scss';

const ErrorPage: React.FC<{}> = (props) => {
  return (
    <div className='wrapper_404'>
      <div className='four_zero_four_bg'>
        <h1 className='text-center '>404</h1>
      </div>
      <div className='contant_box_404'>
        <h3 className='h2'>Look like you're lost</h3>
        <p>The page you are looking for not available!</p>
        <Link to={'/'} className='link_404'>
          Go to Home
        </Link>
      </div>
    </div>
  );
};
export default ErrorPage;
