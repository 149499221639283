import { useState } from 'react';
import './Layout.scss';
import MainNavigation from './MainNavigation';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import IconButton from '@mui/material/IconButton';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { useLocation } from 'react-router-dom';
import { beautifyString, getNameAvatar } from '../../utils/functions';
import { Avatar } from '@mui/material';
import Colors from '../../constants/Colors';

const Layout: React.FC<{ children: React.ReactNode }> = (props) => {
  const { isAuthenticated } = useSelector((state: RootState) => state.auth);
  const { pathname } = useLocation();
  const [collapsed, setCollapsed] = useState(true);
  const [toggled, setToggled] = useState(false);
  const [broken, setBroken] = useState(false);

  return (
    <div className='wrapper'>
      {!isAuthenticated && <div className='main'>{props.children}</div>}
      {isAuthenticated && (
        <>
          <MainNavigation
            collapsed={collapsed}
            onCollapsed={setCollapsed}
            toggled={toggled}
            onToggled={setToggled}
            broken={broken}
            onBroken={setBroken}
          />
          <main className='main'>
            <div className='main_block_header'>
              <div
                className='main_block_header_content'
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                  alignItems: 'center',
                }}>
                {!broken && <div style={{}}></div>}
                {broken && (
                  <div className='hamburger'>
                    <IconButton
                      color='primary'
                      aria-label='menu'
                      onClick={() => setToggled(!toggled)}>
                      <MenuOutlinedIcon />
                    </IconButton>
                  </div>
                )}

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    minWidth: 50,
                    marginRight: 15,
                  }}>
                  <Avatar
                    sx={{
                      width: 30,
                      height: 30,
                      bgcolor: Colors.pclXLight,
                      marginRight: 1,
                      color: Colors.primaryColorLight,
                      boxShadow: '0px 10px 15px -3px rgba(0, 0, 0, 0.1)',
                    }}>
                    {getNameAvatar('Dominic')}
                  </Avatar>
                  {!broken && <p>Dominic</p>}
                </div>
              </div>
            </div>
            {!broken && (
              <div className='main_block_header_title'>
                <p style={{ margin: 0, padding: 0, marginLeft: 30 }}>
                  {beautifyString(pathname)}
                </p>
              </div>
            )}
            <div className='main_content'>{props.children}</div>
          </main>
        </>
      )}
    </div>
  );
};
export default Layout;
