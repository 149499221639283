import { useSelector } from 'react-redux';
import {
  useQuery,
  DocumentNode,
  ApolloError,
  ApolloQueryResult,
  OperationVariables,
} from '@apollo/client';
import { RootState } from '../../../../store/store';
import { Payment } from '../../../../types/schema/payment.types';
import { PaginationWithTextInput } from '../../../../types/schema/utils.types';

interface GetAllPaymentsByStatusByPageHook {
  data: Payment[] | undefined;
  loading: boolean;
  error: ApolloError | undefined;
  refetch: (variables?: Partial<OperationVariables> | undefined) => Promise<
    ApolloQueryResult<{
      getAllPaymentsByStatusByPage: Payment[];
    }>
  >;
}

interface GetAllPaymentsByStatusByPageMutationData {
  getAllPaymentsByStatusByPage: Payment[];
}

interface GetAllPaymentsByStatusByPageInputType {
  input: PaginationWithTextInput;
}

export function useGetAllPaymentsByStatusByPage(
  query: DocumentNode,
  input: PaginationWithTextInput
): GetAllPaymentsByStatusByPageHook {
  const { jwt } = useSelector((state: RootState) => state.auth);

  const { data, loading, error, refetch } = useQuery<
    GetAllPaymentsByStatusByPageMutationData,
    GetAllPaymentsByStatusByPageInputType
  >(query, {
    variables: {
      input,
    },
    context: {
      headers: {
        cookie: `jwt=${jwt}`,
        authorization: jwt ? `Bearer ${jwt}` : '',
      },
    },
  });

  return {
    data: data?.getAllPaymentsByStatusByPage,
    loading,
    error,
    refetch,
  };
}
