export const labelsRating: { [index: string]: string } = {
  0.5: 'Useless',
  1: 'Useless+',
  1.5: 'Poor',
  2: 'Poor+',
  2.5: 'Ok',
  3: 'Ok+',
  3.5: 'Good',
  4: 'Good+',
  4.5: 'Excellent',
  5: 'Excellent+',
};

export const getCusometResponses = (key: number) => {
  const responses: Record<number, string[]> = {
    0: ['Yes', 'No'],
    1: ['Thanks'],
  };

  return responses[key];
};
