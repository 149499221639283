import { RootState } from '../../../../store/store';
import { useSelector } from 'react-redux';
import { gql, useMutation } from '@apollo/client';
import {
  CuMessage,
  UpdateCuMessageInput,
} from '../../../../types/schema/cumessage.types';

const GQL_MUT = gql`
  mutation UpdateCuMessage($input: UpdateCuMessageInput!) {
    updateCuMessage(input: $input) {
      _id
    }
  }
`;

// Define the types for the mutation response and input
interface UpdateCuMessageMutationData {
  updateCuMessage: CuMessage;
}

interface UpdateCuMessageInputType {
  input: UpdateCuMessageInput;
}

export const useUpdateCuMessage = () => {
  const { jwt } = useSelector((state: RootState) => state.auth);

  const [updateCuMessageGql, { data, error, loading }] = useMutation<
    UpdateCuMessageMutationData,
    UpdateCuMessageInputType
  >(GQL_MUT);

  const updateCuMessage = async (input: UpdateCuMessageInput) => {
    const { data, errors } = await updateCuMessageGql({
      variables: {
        input: input,
      },
      context: {
        headers: {
          cookie: `jwt=${jwt}`,
          authorization: jwt ? `Bearer ${jwt}` : '',
        },
      },
    });

    return {
      updateCuMessage: data?.updateCuMessage,
      error: errors ? errors[0].message : null,
    };
  };

  return { updateCuMessage, data, error, loading };
};
