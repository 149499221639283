import Colors from '../../constants/Colors';
import {
  TransactionStatusEnum,
  TransactionTypeEnum,
} from '../../types/schema/transaction.types';
import { formatString, getAmountWithCurrency } from '../../utils/functions';
import './TransactionListItem.scss';
import moment from 'moment';

const TransactionListItem: React.FC<{
  _id: string;
  amount: number;
  mode: number;
  transaction_type: TransactionTypeEnum;
  status: TransactionStatusEnum;
  createdAt: Date;
}> = ({ _id, mode, transaction_type, status, createdAt, amount }) => {
  return (
    <div className='transactions_cards_item'>
      <div className='name_status'>
        <div className='company_name'>
          <p>Indentifier</p>
          <p className='det_answer'>{formatString(transaction_type)}</p>
        </div>

        <div className='tr_status'>
          <p>Status</p>
          <p
            className='det_answer det_answer_status'
            style={{
              color:
                status === 'pending'
                  ? Colors.accentColorDark
                  : Colors.primaryColorLight,
            }}>
            {formatString(status)}
          </p>
        </div>
      </div>
      <div className='date_amount'>
        <div className='tr_date'>
          <p>Date</p>
          <p className='det_answer'>
            {moment(createdAt).format('MMM DD, YYYY')}
          </p>
        </div>
        <div className='tr_amount'>
          <p>Amount {mode < 0 ? 'Sent' : 'Received'}</p>
          <p
            className='det_answer'
            style={{
              color:
                mode < 0 ? Colors.accentColorDark : Colors.primaryColorLight,
            }}>
            {mode < 0 ? '-' : ''}
            {getAmountWithCurrency(amount)}
          </p>
        </div>
      </div>
    </div>
  );
};

export default TransactionListItem;
