import { RootState } from '../../../../store/store';
import { useSelector } from 'react-redux';
import { gql, useMutation } from '@apollo/client';
import {
  Payment,
  UpdatePaymentStatusInput,
} from '../../../../types/schema/payment.types';

const GQL_MUT = gql`
  mutation UpdatePaymentStatus($input: UpdatePaymentStatusInput!) {
    updatePaymentStatus(input: $input) {
      _id
    }
  }
`;

// Define the types for the mutation response and input
interface UpdatePaymentStatusMutationData {
  updatePaymentStatus: Payment;
}

interface UpdatePaymentStatusInputType {
  input: UpdatePaymentStatusInput;
}

export const useUpdatePaymentStatus = () => {
  const { jwt } = useSelector((state: RootState) => state.auth);

  const [updatePaymentStatusGql, { data, error, loading }] = useMutation<
    UpdatePaymentStatusMutationData,
    UpdatePaymentStatusInputType
  >(GQL_MUT);

  const updatePaymentStatus = async (input: UpdatePaymentStatusInput) => {
    const { data, errors } = await updatePaymentStatusGql({
      variables: {
        input: input,
      },
      context: {
        headers: {
          cookie: `jwt=${jwt}`,
          authorization: jwt ? `Bearer ${jwt}` : '',
        },
      },
    });

    return {
      updatePaymentStatus: data?.updatePaymentStatus,
      error: errors ? errors[0].message : null,
    };
  };

  return { updatePaymentStatus, data, error, loading };
};
