import { RootState } from '../../../../store/store';
import { useSelector } from 'react-redux';
import { gql, useMutation } from '@apollo/client';
import { Pdf } from '../../../../types/schema/pdf.schema';
import { MongoIdInput } from '../../../../types/schema/utils.types';

const GQL_MUT = gql`
  mutation GenerateAndSendDuePaymentPdf($input: MongoIdInput!) {
    generateAndSendDuePaymentPdf(input: $input) {
      _id
    }
  }
`;

// Define the types for the mutation response and input
interface GenerateAndSendDuePaymentPdfMutationData {
  generateAndSendDuePaymentPdf: Pdf;
}

interface GenerateAndSendDuePaymentPdfInputType {
  input: MongoIdInput;
}

export const useGenerateAndSendDuePaymentPdf = () => {
  const { jwt } = useSelector((state: RootState) => state.auth);

  const [generateAndSendDuePaymentPdfGql, { data, error, loading }] =
    useMutation<
      GenerateAndSendDuePaymentPdfMutationData,
      GenerateAndSendDuePaymentPdfInputType
    >(GQL_MUT);

  const generateAndSendDuePaymentPdf = async (input: MongoIdInput) => {
    const { data, errors } = await generateAndSendDuePaymentPdfGql({
      variables: {
        input: input,
      },
      context: {
        headers: {
          cookie: `jwt=${jwt}`,
          authorization: jwt ? `Bearer ${jwt}` : '',
        },
      },
    });

    return {
      generateAndSendDuePaymentPdf: data?.generateAndSendDuePaymentPdf,
      error: errors ? errors[0].message : null,
    };
  };

  return { generateAndSendDuePaymentPdf, data, error, loading };
};
