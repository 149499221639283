import './CustomerMessagesSection.scss';
import { Avatar, CircularProgress, IconButton } from '@mui/material';
import Colors from '../../constants/Colors';
import { useEffect, useRef, useState } from 'react';
import SendIcon from '@mui/icons-material/Send';
import { motion } from 'framer-motion';
import { gql } from '@apollo/client';
import { formatMessages } from '../../utils/functions';
import moment from 'moment';
import { useAddCuMessage } from '../../hooks/gql/mutation/create/useAddCuMessage';
import { useUpdateCuMessage } from '../../hooks/gql/mutation/update/useUpdateCuMessage';
import { useGetCuMessageByCustomerId } from '../../hooks/gql/query/getByValue/useGetCuMessageByCustomerId';

const GET_CU_MESSAGE_BY_CUS_ID = gql`
  query GetCuMessageByCustomerId($input: MongoIdInput!) {
    getCuMessageByCustomerId(input: $input) {
      _id
      customer_ref {
        _id
        name
      }
      conversations {
        _id
        sender
        content
        sentAt
      }
    }
  }
`;

const CustomerMessagesSection: React.FC<{ selectedCustomer?: string }> = ({
  selectedCustomer,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [cuMessage, setCuMessage] = useState('');
  const cuRef = useRef<HTMLDivElement>(null);

  const { addCuMessage } = useAddCuMessage();
  const { updateCuMessage } = useUpdateCuMessage();

  const {
    data: cu_message,
    error: cu_messageError,
    loading: cu_messageLoading,
    refetch: cu_messageRefectch,
  } = useGetCuMessageByCustomerId(GET_CU_MESSAGE_BY_CUS_ID, {
    _id: selectedCustomer || '64f8267c7ed1e6aa0166bde1',
  });

  const conversations = cu_message?.conversations || [];
  const formattedMessages = formatMessages(conversations);

  useEffect(() => {
    const itv = setInterval(() => {
      cu_messageRefectch();
    }, 5000);

    return () => clearInterval(itv);
  }, []);

  useEffect(() => {
    cuRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
    });
  }, [conversations.length]);

  return (
    <div className='customer_message'>
      {(cu_message === undefined || selectedCustomer === undefined) && (
        <p style={{ textAlign: 'center', fontSize: 16 }}>
          Select / search a valid customer to send message
        </p>
      )}

      {(cu_message === null || cu_message) && selectedCustomer && (
        <>
          <div className='customer_message--h'>
            <p>
              {cu_message === null
                ? 'Type a new message to start a conversation.'
                : `You are talking to ${cu_message?.customer_ref?.name}`}
            </p>
          </div>
          <div className='customer_message--c'>
            {/* ITIRATE HERE */}
            {/* DATE */}

            {cu_message && (
              <>
                {formattedMessages.map((m) => {
                  if (typeof m === 'string') {
                    return (
                      <div key={m} className='customer_message--c_date'>
                        <p>{m}</p>
                      </div>
                    );
                  } else if (m.sender === 'admin') {
                    return (
                      <motion.div
                        key={m._id}
                        // initial={{ y: 10, opacity: 0 }}
                        // animate={{ y: 0, opacity: 1 }}
                        // transition={{ duration: 0.5 }}
                        className='customer_message--c_right'>
                        <div className='conv_message'>
                          <pre className='message_content'>{m.content}</pre>
                        </div>
                        <div className='conv_time'>
                          <p>{moment(m.sentAt).format('HH:mm')}</p>
                        </div>
                      </motion.div>
                    );
                  } else {
                    return (
                      <motion.div
                        key={m._id}
                        // initial={{ y: 10, opacity: 0 }}
                        // animate={{ y: 0, opacity: 1 }}
                        // transition={{ duration: 0.5 }}
                        className='customer_message--c_left'>
                        <div className='conv_message'>
                          <Avatar
                            sx={{
                              width: 30,
                              height: 30,
                              marginRight: 1,
                              bgcolor: Colors.pclXLight,
                              color: Colors.primaryColorLight,
                              boxShadow:
                                '0px 10px 15px -3px rgba(0, 0, 0, 0.1)',
                            }}
                          />
                          <pre className='message_content'>{m.content}</pre>
                        </div>
                        <div className='conv_time'>
                          <p>{moment(m.sentAt).format('HH:mm')}</p>
                        </div>
                      </motion.div>
                    );
                  }
                })}
              </>
            )}

            <div ref={cuRef} />
          </div>
          <div className='customer_message--i'>
            <div className='customer_message--i_inp'>
              <textarea
                name=''
                id=''
                placeholder='Type new message...'
                value={cuMessage}
                onChange={(e) => {
                  setCuMessage(e.target.value);
                }}
                cols={20}
                rows={2}></textarea>
            </div>
            <div className='customer_message--i_sub'>
              <IconButton
                onClick={async () => {
                  // Send BroadCast Message
                  setIsSubmitting(true);
                  try {
                    if (cu_message === null && selectedCustomer) {
                      await addCuMessage({
                        customer_ref: selectedCustomer,
                        conversations: {
                          sender: 'admin',
                          content: cuMessage,
                        },
                      });
                    }

                    if (cu_message) {
                      await updateCuMessage({
                        _id: cu_message._id,
                        conversations: {
                          sender: 'admin',
                          content: cuMessage,
                        },
                      });
                    }

                    setCuMessage('');
                    setIsSubmitting(false);

                    setTimeout(() => {
                      cu_messageRefectch();
                    }, 1000);
                  } catch (err) {
                    setIsSubmitting(false);
                    console.log(err);
                  }
                }}
                disabled={!(cuMessage.trim().length >= 2)}
                style={{ color: '#fff', backgroundColor: '#002661' }}>
                {isSubmitting ? (
                  <CircularProgress style={{ color: '#fff' }} size={24} />
                ) : (
                  <SendIcon
                    style={{
                      marginLeft: 3,
                      transform: 'rotate(-45deg)',
                      marginBottom: 3,
                    }}
                  />
                )}
              </IconButton>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
export default CustomerMessagesSection;
