import { RootState } from '../../../../store/store';
import { useSelector } from 'react-redux';
import { gql, useMutation } from '@apollo/client';
import { User, DeleteUserVPInput } from '../../../../types/schema/user.types';

const GQL_MUT = gql`
  mutation DeleteUserVP($input: DeleteUserVPInput!) {
    deleteUserVP(input: $input) {
      _id
    }
  }
`;

// Define the types for the mutation response and input
interface DeleteUserVPMutationData {
  deleteUserVP: User;
}

interface DeleteUserVPInputType {
  input: DeleteUserVPInput;
}

export const useDeleteUserVP = () => {
  const { jwt } = useSelector((state: RootState) => state.auth);

  const [deleteUserVPGql, { data, error, loading }] = useMutation<
    DeleteUserVPMutationData,
    DeleteUserVPInputType
  >(GQL_MUT);

  const deleteUserVP = async (input: DeleteUserVPInput) => {
    const { data, errors } = await deleteUserVPGql({
      variables: {
        input: input,
      },
      context: {
        headers: {
          cookie: `jwt=${jwt}`,
          authorization: jwt ? `Bearer ${jwt}` : '',
        },
      },
    });

    return {
      deleteUserVP: data?.deleteUserVP,
      error: errors ? errors[0].message : null,
    };
  };

  return { deleteUserVP, data, error, loading };
};
