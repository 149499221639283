/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import VisibilityIcon from '@mui/icons-material/Visibility';

const ActionEyeButton: React.FC<{
  id: string;
  onEyeClick: (id: string) => void;
}> = ({ id, onEyeClick }) => {
  return (
    <Stack direction='row' spacing={1}>
      <IconButton
        color='primary'
        aria-label='visiblity'
        onClick={() => onEyeClick(id)}>
        <VisibilityIcon />
      </IconButton>
    </Stack>
  );
};

export default ActionEyeButton;
