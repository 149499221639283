import { useSelector } from 'react-redux';
import {
  useQuery,
  DocumentNode,
  ApolloError,
  ApolloQueryResult,
  OperationVariables,
} from '@apollo/client';
import { RootState } from '../../../../store/store';
import { MongoIdInput } from '../../../../types/schema/utils.types';
import { Payment } from '../../../../types/schema/payment.types';

interface GetPaymentDetailsByIdHook {
  data: Payment | undefined;
  loading: boolean;
  error: ApolloError | undefined;
  refetch: (variables?: Partial<OperationVariables> | undefined) => Promise<
    ApolloQueryResult<{
      getPaymentDetailsById: Payment;
    }>
  >;
}

interface GetPaymentDetailsByIdMutationData {
  getPaymentDetailsById: Payment;
}

interface GetPaymentDetailsByIdInputType {
  input: MongoIdInput;
}

export function useGetPaymentDetailsById(
  query: DocumentNode,
  input: MongoIdInput
): GetPaymentDetailsByIdHook {
  const { jwt } = useSelector((state: RootState) => state.auth);

  const { data, loading, error, refetch } = useQuery<
    GetPaymentDetailsByIdMutationData,
    GetPaymentDetailsByIdInputType
  >(query, {
    variables: {
      input,
    },
    context: {
      headers: {
        cookie: `jwt=${jwt}`,
        authorization: jwt ? `Bearer ${jwt}` : '',
      },
    },
  });

  return {
    data: data?.getPaymentDetailsById,
    loading,
    error,
    refetch,
  };
}
