import { Avatar, Button, FormControl } from '@mui/material';
import Colors from '../../constants/Colors';
import './PaymentsDetailsPage.scss';
import { gql } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import {
  formatString,
  getAmountWithCurrency,
  getNameAvatar,
} from '../../utils/functions';
import moment from 'moment';
import { gBaseUrl } from '../../utils/config';
import { useGetPaymentDetailsById } from '../../hooks/gql/query/getById/useGetPaymentDetailsById';

const GET_PAYMENT_DETAILS_BY_ID = gql`
  query GetPaymentDetailsById($input: MongoIdInput!) {
    getPaymentDetailsById(input: $input) {
      _id
      admin_revenue
      amount_paid
      net_revenue
      createdAt
      payment_status
      payment_type
      payment_id
      referrer_revenue
      square_charge
      company_ref {
        _id
        company_name
        contact_name
        email
        phone
        photo
        isPhotoVisible
        referral_code
      }
      customer_ref {
        _id
        name
        phone_number
        email
        traveller_id
      }
      booking_ref {
        _id
        booking_price
        booking_token
        booking_type
        trip_info {
          destination {
            location_name
          }
          pickup_datetime
          return_datetime
          pickup_location {
            location_name
          }
          travelling_reason
          tripType
          vehicle_count
          vehicle_type
          passenger_count
          note
          luggage_type
        }
      }
      referrer_ref {
        _id
        company_name
        isPhotoVisible
        photo
        referral_code
      }
    }
  }
`;

const PaymentsDetailsPage: React.FC<{}> = (props) => {
  const navigate = useNavigate();
  const { slug } = useParams();

  const {
    data: payment,
    error: paymentError,
    loading: paymentLoading,
  } = useGetPaymentDetailsById(GET_PAYMENT_DETAILS_BY_ID, {
    _id: slug || '64f8267c7ed1f6aa0166bde1',
  });

  const referrer_info = payment?.referrer_ref;
  const customer_info = payment?.customer_ref;
  const company_info = payment?.company_ref;
  const booking_info = payment?.booking_ref;
  const trip_info = booking_info?.trip_info;

  return (
    <>
      <div className='payments_details_wrapper'>
        <div className='payment_details_section'>
          <div className='payment_details_section_header'>
            <div className='generated'>
              <p>Payments Made By</p>
            </div>
            <div className='title_logo'>
              {!customer_info && company_info?.isPhotoVisible ? (
                <Avatar
                  src={`${gBaseUrl}/photos/avatars/${company_info?.photo}`}
                  sx={{
                    bgcolor: Colors.pclXLight,
                    marginRight: 1,
                    color: Colors.primaryColorLight,
                    boxShadow: '0px 10px 15px -3px rgba(0, 0, 0, 0.1)',
                  }}
                />
              ) : (
                <Avatar
                  sx={{
                    bgcolor: Colors.pclXLight,
                    marginRight: 1,
                    color: Colors.primaryColorLight,
                    boxShadow: '0px 10px 15px -3px rgba(0, 0, 0, 0.1)',
                  }}>
                  {getNameAvatar(
                    !customer_info
                      ? company_info?.company_name || 'User'
                      : customer_info.name || 'Customer'
                  )}
                </Avatar>
              )}
              <div className='title_logo_info'>
                <p className='business_name'>
                  {!customer_info
                    ? company_info?.company_name
                    : customer_info.name}
                </p>
                <p>
                  ID: #
                  {!customer_info
                    ? company_info?.referral_code
                    : customer_info.traveller_id}
                </p>
              </div>
            </div>
          </div>

          {payment && (
            <div className='payment_details_wrapper'>
              <h2>Transaction Details</h2>
              <div className='payment_details_section_det1'>
                <div className='payment_details_section_det1_p1'>
                  <div className=''>
                    <p>Transected Amount</p>
                    <p
                      style={{
                        fontWeight: 'bold',
                        color: Colors.primaryColorLight,
                        fontSize: 25,
                      }}>
                      {getAmountWithCurrency(payment.amount_paid)}
                    </p>
                  </div>

                  <div className=''>
                    <p>SQ Payment ID</p>
                    <p style={{ fontWeight: 'bold' }}>{payment.payment_id}</p>
                  </div>
                </div>
                <div className='payment_details_section_det1_p2'>
                  <div className=''>
                    <p>Payment Date</p>
                    <p style={{ fontWeight: 'bold' }}>
                      {moment(payment.createdAt).format('lll')}
                    </p>
                  </div>

                  <div className=''>
                    <p>Payment Method</p>
                    <p style={{ fontWeight: 'bold' }}>Square Online</p>
                  </div>
                </div>
              </div>
              <div className='payment_details_section_det2'>
                <div className='payment_details_section_det2_p1'>
                  <div className=''>
                    <p>Square Charge</p>
                    <p style={{ fontWeight: 'bold' }}>
                      {getAmountWithCurrency(payment.square_charge)}
                    </p>
                  </div>

                  <div className=''>
                    <p>Net Revenue</p>
                    <p
                      style={{
                        fontWeight: 'bold',
                        color:
                          payment.net_revenue < 1
                            ? Colors.accentColorDark
                            : Colors.primaryColorLight,
                      }}>
                      {getAmountWithCurrency(payment.net_revenue)}
                    </p>
                  </div>
                </div>
                <div className='payment_details_section_det2_p2'>
                  <div className=''>
                    <p>Status</p>
                    <p
                      style={{
                        fontWeight: 'bold',
                        color: Colors.primaryColorLight,
                      }}>
                      {formatString(payment?.payment_status)}
                    </p>
                  </div>

                  <div className=''>
                    <p>Invoiced Amount</p>
                    <p
                      style={{
                        fontWeight: 'bold',
                      }}>
                      {getAmountWithCurrency(
                        booking_info?.booking_price || payment.amount_paid
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}

          {!customer_info && company_info && (
            <div className='customer_details_wrapper'>
              <h2>Paid By</h2>
              <div className='payment_details_section_det3'>
                <div className='payment_details_section_det3_p1'>
                  <div className=''>
                    <p>Contact Name</p>
                    <p style={{ fontWeight: 'bold' }}>
                      {company_info?.contact_name}
                    </p>
                  </div>

                  <div className=''>
                    <p>Phone Number</p>
                    <p
                      style={{
                        fontWeight: 'bold',
                      }}>
                      {company_info.phone}
                    </p>
                  </div>
                </div>
                <div className='payment_details_section_det3_p2'>
                  <div className=''>
                    <p>Email</p>
                    <p
                      style={{
                        fontWeight: 'bold',
                      }}>
                      {company_info.email}
                    </p>
                  </div>
                </div>
              </div>

              <div className='payment_details_section_det4'>
                <div className='payment_details_section_det4_p1'>
                  <div className=''>
                    <p>Comments</p>
                    <p style={{ marginTop: 10 }}>{'No additional comments'}</p>
                  </div>
                </div>
                <div className='payment_details_section_det4_p2'>
                  <div className=''>
                    {payment.payment_type === 'payout_request' && (
                      <FormControl
                        sx={{ m: 1, width: '22ch' }}
                        variant='standard'>
                        <Button
                          variant='contained'
                          size='small'
                          color='info'
                          onClick={() => {
                            navigate(`/payout-requests/${slug}`);
                          }}>
                          <p style={{ marginTop: 3, color: '#fff' }}>
                            Go to payout
                          </p>
                        </Button>
                      </FormControl>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}

          {customer_info && (
            <div className='customer_details_wrapper'>
              <h2>Customer Information</h2>
              <div className='payment_details_section_det3'>
                <div className='payment_details_section_det3_p1'>
                  <div className=''>
                    <p>Customer Name</p>
                    <p style={{ fontWeight: 'bold' }}>{customer_info?.name}</p>
                  </div>

                  <div className=''>
                    <p>Phone</p>
                    <p
                      style={{
                        fontWeight: 'bold',
                      }}>
                      {customer_info?.phone_number}
                    </p>
                  </div>
                </div>
                <div className='payment_details_section_det3_p2'>
                  <div className=''>
                    <p>Email</p>
                    <p
                      style={{
                        fontWeight: 'bold',
                      }}>
                      {customer_info?.email}
                    </p>
                  </div>
                </div>
              </div>

              <div className='payment_details_section_det4'>
                <div className='payment_details_section_det4_p1'>
                  <div className=''></div>
                </div>
                <div className='payment_details_section_det4_p2'>
                  <div className=''></div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className='footer_section'></div>
    </>
  );
};
export default PaymentsDetailsPage;
