import './CompanyMessagesSection.scss';
import { Avatar, IconButton } from '@mui/material';
import { motion } from 'framer-motion';
import SendIcon from '@mui/icons-material/Send';
import Colors from '../../constants/Colors';
import { useEffect, useRef, useState } from 'react';
import { gql } from '@apollo/client';
import { formatMessages } from '../../utils/functions';
import moment from 'moment';
import CircularProgress from '@mui/material/CircularProgress';
import { useAddCoMessage } from '../../hooks/gql/mutation/create/useAddCoMessage';
import { useUpdateCoMessage } from '../../hooks/gql/mutation/update/useUpdateCoMessage';
import { useGetCoMessageByCompanyId } from '../../hooks/gql/query/getByValue/useGetCoMessageByCompanyId';

const GET_CO_MESSAGE_BY_COM_ID = gql`
  query GetCoMessageByCompanyId($input: MongoIdInput!) {
    getCoMessageByCompanyId(input: $input) {
      _id
      company_ref {
        _id
        contact_name
        activationToken
      }
      conversations {
        _id
        sender
        content
        sentAt
      }
    }
  }
`;

const CompanyMessagesSection: React.FC<{ selectedCompany?: string }> = ({
  selectedCompany,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [coMessage, setCoMessage] = useState('');
  const coRef = useRef<HTMLDivElement>(null);

  const { addCoMessage } = useAddCoMessage();
  const { updateCoMessage } = useUpdateCoMessage();

  const { data: co_message, refetch: co_messageRefectch } =
    useGetCoMessageByCompanyId(GET_CO_MESSAGE_BY_COM_ID, {
      _id: selectedCompany || '64f8267c7ed1e6aa0166bde1',
    });

  const conversations = co_message?.conversations || [];
  const formattedMessages = formatMessages(conversations);

  useEffect(() => {
    const itv = setInterval(() => {
      co_messageRefectch();
    }, 5000);

    return () => clearInterval(itv);
  }, []);

  useEffect(() => {
    coRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
    });
  }, [conversations.length]);

  return (
    <motion.div
      initial={{ x: -50, y: 0 }}
      animate={{ x: 0, y: 0 }}
      transition={{ duration: 0.5 }}
      className='company_message'>
      {(co_message === undefined || selectedCompany === undefined) && (
        <p style={{ textAlign: 'center', fontSize: 16 }}>
          Select / search a valid company to send message
        </p>
      )}
      {(co_message === null || co_message) && selectedCompany && (
        <>
          <div className='company_message--h'>
            <p>
              {co_message === null
                ? 'Type a new message to start a conversation.'
                : ` You are talking to ${
                    co_message?.company_ref?.contact_name
                  } ${
                    co_message?.company_ref?.activationToken
                      ? `(OTP - ${co_message?.company_ref?.activationToken})`
                      : ``
                  }`}
            </p>
          </div>
          <div className='company_message--c'>
            {/* ITIRATE HERE */}
            {/* DATE */}
            {co_message && (
              <>
                {formattedMessages.map((m) => {
                  if (typeof m === 'string') {
                    return (
                      <div key={m} className='company_message--c_date'>
                        <p>{m}</p>
                      </div>
                    );
                  } else if (m.sender === 'admin') {
                    return (
                      <motion.div
                        key={m._id}
                        // initial={{ y: 10, opacity: 0 }}
                        // animate={{ y: 0, opacity: 1 }}
                        // transition={{ duration: 0.5 }}
                        className='company_message--c_right'>
                        <div className='conv_message'>
                          <pre className='message_content'>{m.content}</pre>
                        </div>
                        <div className='conv_time'>
                          <p>{moment(m.sentAt).format('HH:mm')}</p>
                        </div>
                      </motion.div>
                    );
                  } else {
                    return (
                      <motion.div
                        key={m._id}
                        // initial={{ y: 10, opacity: 0 }}
                        // animate={{ y: 0, opacity: 1 }}
                        // transition={{ duration: 0.5 }}
                        className='company_message--c_left'>
                        <div className='conv_message'>
                          <Avatar
                            sx={{
                              width: 30,
                              height: 30,
                              marginRight: 1,
                              bgcolor: Colors.pclXLight,
                              color: Colors.primaryColorLight,
                              boxShadow:
                                '0px 10px 15px -3px rgba(0, 0, 0, 0.1)',
                            }}
                          />
                          <pre className='message_content'>{m.content}</pre>
                        </div>
                        <div className='conv_time'>
                          <p>{moment(m.sentAt).format('HH:mm')}</p>
                        </div>
                      </motion.div>
                    );
                  }
                })}
              </>
            )}

            <div ref={coRef} />
          </div>

          {/* REPLY INPUT */}
          <div className='company_message--i'>
            <div className='company_message--i_inp'>
              <textarea
                name=''
                id=''
                placeholder='Type new message...'
                value={coMessage}
                onChange={(e) => {
                  setCoMessage(e.target.value);
                }}
                cols={20}
                rows={2}></textarea>
            </div>
            <div className='company_message--i_sub'>
              <IconButton
                onClick={async () => {
                  // Send BroadCast Message
                  setIsSubmitting(true);
                  try {
                    if (co_message === null && selectedCompany) {
                      await addCoMessage({
                        company_ref: selectedCompany,
                        conversations: {
                          sender: 'admin',
                          content: coMessage,
                        },
                      });
                    }

                    if (co_message) {
                      await updateCoMessage({
                        _id: co_message._id,
                        conversations: {
                          sender: 'admin',
                          content: coMessage,
                        },
                      });
                    }

                    setCoMessage('');
                    setIsSubmitting(false);

                    setTimeout(() => {
                      co_messageRefectch();
                    }, 1000);
                  } catch (err) {
                    setIsSubmitting(false);
                    console.log(err);
                  }
                }}
                disabled={!(coMessage.trim().length >= 2)}
                style={{ color: '#fff', backgroundColor: '#002661' }}>
                {isSubmitting ? (
                  <CircularProgress style={{ color: '#fff' }} size={24} />
                ) : (
                  <SendIcon
                    style={{
                      marginLeft: 3,
                      transform: 'rotate(-45deg)',
                      marginBottom: 3,
                    }}
                  />
                )}
              </IconButton>
            </div>
          </div>
        </>
      )}
    </motion.div>
  );
};
export default CompanyMessagesSection;
