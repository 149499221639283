import { useSelector } from 'react-redux';
import {
  useQuery,
  DocumentNode,
  ApolloError,
  ApolloQueryResult,
  OperationVariables,
} from '@apollo/client';
import { RootState } from '../../../store/store';
import { Setting } from '../../../types/schema/setting.types';

interface GetSettingHook {
  data: Setting | undefined;
  loading: boolean;
  error: ApolloError | undefined;
  refetch: (variables?: Partial<OperationVariables> | undefined) => Promise<
    ApolloQueryResult<{
      getSetting: Setting;
    }>
  >;
}

export function useGetSetting(query: DocumentNode): GetSettingHook {
  const { jwt } = useSelector((state: RootState) => state.auth);

  const { data, loading, error, refetch } = useQuery<{ getSetting: Setting }>(
    query,
    {
      context: {
        headers: {
          cookie: `jwt=${jwt}`,
          authorization: jwt ? `Bearer ${jwt}` : '',
        },
      },
    }
  );

  return { data: data?.getSetting, loading, error, refetch };
}
