import { useSelector } from 'react-redux';
import {
  useQuery,
  DocumentNode,
  ApolloError,
  ApolloQueryResult,
  OperationVariables,
} from '@apollo/client';
import { RootState } from '../../../../store/store';
import { MongoIdInput } from '../../../../types/schema/utils.types';
import { Referral } from '../../../../types/schema/referral.types';

interface GetReferralDetailsByIdHook {
  data: Referral | undefined;
  loading: boolean;
  error: ApolloError | undefined;
  refetch: (variables?: Partial<OperationVariables> | undefined) => Promise<
    ApolloQueryResult<{
      getReferralDetailsById: Referral;
    }>
  >;
}

interface GetReferralDetailsByIdMutationData {
  getReferralDetailsById: Referral;
}

interface GetReferralDetailsByIdInputType {
  input: MongoIdInput;
}

export function useGetReferralDetailsById(
  query: DocumentNode,
  input: MongoIdInput
): GetReferralDetailsByIdHook {
  const { jwt } = useSelector((state: RootState) => state.auth);

  const { data, loading, error, refetch } = useQuery<
    GetReferralDetailsByIdMutationData,
    GetReferralDetailsByIdInputType
  >(query, {
    variables: {
      input,
    },
    context: {
      headers: {
        cookie: `jwt=${jwt}`,
        authorization: jwt ? `Bearer ${jwt}` : '',
      },
    },
  });

  return {
    data: data?.getReferralDetailsById,
    loading,
    error,
    refetch,
  };
}
