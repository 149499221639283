import * as React from 'react';
import { alpha, styled } from '@mui/material/styles';
import { DataGrid, gridClasses } from '@mui/x-data-grid';
import { GridColDef } from '@mui/x-data-grid';
import moment from 'moment';
import { gql } from '@apollo/client';
import { formatString, getAmountWithCurrency } from '../../utils/functions';
import ActionEyeButton from '../../components/Utils/ActionEyeButton';
import { useGetAllBookingsByPage } from '../../hooks/gql/query/getAll/useGetAllBookingsByPage';
import Colors from '../../constants/Colors';

const bookingTableState = {
  initialState: {
    columns: {
      columnVisibilityModel: {
        id: false,
        booked_by: true,
        booking_price: true,
        booking_type: true,
        pickup_location: true,
        destination: true,
        status: true,
        createdAt: true,
        actions: true,
      },
    },
  },
};

const bookingTableColumn: GridColDef[] = [
  { field: 'id', headerClassName: 'booking_table_header' },
  {
    field: 'booked_by',
    headerName: 'Booked By',
    minWidth: 150,
    flex: 1,
    headerAlign: 'left',
    align: 'left',
    valueGetter: (params) => params.row?.company_ref?.contact_name,
    headerClassName: 'booking_table_header',
  },
  {
    field: 'booking_price',
    headerName: 'Price',
    minWidth: 120,
    flex: 1,
    headerAlign: 'center',
    valueGetter: (params) => getAmountWithCurrency(params.row?.booking_price),
    align: 'center',
    headerClassName: 'booking_table_header',
  },
  {
    field: 'booking_type',
    headerName: 'Type',
    minWidth: 150,
    flex: 1,
    headerAlign: 'center',
    valueGetter: (params) => formatString(params.row?.booking_type),
    align: 'center',
    headerClassName: 'booking_table_header',
  },
  {
    field: 'pickup_location',
    headerName: 'Pickup',
    flex: 1,
    minWidth: 120,
    headerAlign: 'center',
    valueGetter: (params) =>
      params.row?.trip_info?.pickup_location?.location_name || '---',
    headerClassName: 'booking_table_header',
  },
  {
    field: 'destination',
    headerName: 'Destination',
    flex: 1,
    minWidth: 120,
    valueGetter: (params) =>
      params.row?.trip_info?.destination?.location_name || '---',
    headerAlign: 'center',
    headerClassName: 'booking_table_header',
  },
];

const ODD_OPACITY = 0.2;

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.odd`]: {
    backgroundColor: '#F4F6F6',
    '&:hover, &.Mui-hovered': {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
    '&.Mui-selected': {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity
      ),
      '&:hover, &.Mui-hovered': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity
        ),
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  },
}));

const GET_ALL_BOOKINGS = gql`
  query GetAllBookingsByPage($input: PaginationInput!) {
    getAllBookingsByPage(input: $input) {
      _id
      company_ref {
        _id
        company_name
        contact_name
      }
      booking_price
      booking_type
      status
      trip_info {
        pickup_location {
          location_name
        }
        destination {
          location_name
        }
      }
    }
  }
`;

const BookingsPage: React.FC<{}> = (props) => {
  const {
    data: bookings,
    error: bookingsError,
    loading: bookingsLoading,
    refetch: bookingsRefectch,
  } = useGetAllBookingsByPage(GET_ALL_BOOKINGS, {
    page: 1,
    item_per_page: 1000,
  });

  const bookings_row =
    bookings?.map((c) => {
      return { ...c, id: c._id, actions: 'V' };
    }) || [];

  return (
    <>
      <div style={{ height: 631, width: '100%', marginBottom: 150 }}>
        <StripedDataGrid
          pageSizeOptions={[10, 50, 100]}
          columns={[
            ...bookingTableColumn,
            {
              field: 'createdAt',
              headerName: 'Date',
              flex: 1,
              minWidth: 180,
              headerAlign: 'center',
              align: 'center',
              headerClassName: 'booking_table_header',
              renderCell: (params) => {
                const data = params.row;
                return (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <p>{moment(data.createdAt).format('lll')}</p>
                  </div>
                );
              },
            },
            {
              field: 'status',
              headerName: 'Status',
              flex: 1,
              minWidth: 120,
              headerAlign: 'center',
              align: 'center',
              headerClassName: 'booking_table_header',
              renderCell: (params) => {
                return (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <p
                      style={{
                        backgroundColor:
                          params.row.status === 'cancelled'
                            ? Colors.accentColorDark
                            : params.row.status === 'covered'
                            ? Colors.accentColorLight
                            : Colors.pclXLight,
                        color:
                          params.row.status === 'cancelled'
                            ? Colors.light
                            : params.row.status === 'covered'
                            ? Colors.light
                            : Colors.primaryColorLight,
                        paddingTop: 3,
                        paddingBottom: 3,
                        paddingLeft: 10,
                        paddingRight: 10,
                        borderRadius: 100,
                      }}>
                      {formatString(params.row.status)}
                    </p>
                  </div>
                );
              },
            },
            {
              field: 'action',
              headerName: 'Action',
              maxWidth: 70,
              minWidth: 70,
              headerAlign: 'center',
              align: 'center',
              sortable: false,
              filterable: false,
              headerClassName: 'booking_table_header',
              renderCell: (params) => {
                const data = params.row;
                return (
                  <div>
                    <ActionEyeButton
                      id={data.id}
                      onEyeClick={(_id) => {
                        alert('More details not available on this phase!');
                      }}
                    />
                  </div>
                );
              },
            },
          ]}
          rows={bookings_row}
          initialState={{
            ...bookingTableState.initialState,
            pagination: { paginationModel: { pageSize: 10 } },
          }}
          pagination
          sx={{
            '& .booking_table_header': {
              backgroundColor: '#F3F8FF',
            },
          }}
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
          }
        />
      </div>
    </>
  );
};

export default BookingsPage;
