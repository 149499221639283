import { RootState } from '../../../../store/store';
import { useSelector } from 'react-redux';
import { gql, useMutation } from '@apollo/client';
import { AddNoticeInput, Notice } from '../../../../types/schema/notice.types';

const GQL_MUT = gql`
  mutation AddNotice($input: AddNoticeInput!) {
    addNotice(input: $input) {
      _id
    }
  }
`;

// Define the types for the mutation response and input
interface AddNoticeMutationData {
  addNotice: Notice;
}

interface AddNoticeInputType {
  input: AddNoticeInput;
}

export const useAddNotice = () => {
  const { jwt } = useSelector((state: RootState) => state.auth);

  const [addNoticeGql, { data, error, loading }] = useMutation<
    AddNoticeMutationData,
    AddNoticeInputType
  >(GQL_MUT);

  const addNotice = async (input: AddNoticeInput) => {
    const { data, errors } = await addNoticeGql({
      variables: {
        input: input,
      },
      context: {
        headers: {
          cookie: `jwt=${jwt}`,
          authorization: jwt ? `Bearer ${jwt}` : '',
        },
      },
    });

    return {
      addNotice: data?.addNotice,
      error: errors ? errors[0].message : null,
    };
  };

  return { addNotice, data, error, loading };
};
