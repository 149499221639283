import './UsersApprovalPage.scss';
import { useNavigate } from 'react-router-dom';
import { gql } from '@apollo/client';
import { useState } from 'react';
import LoadingSpinner from '../../components/UI/LoadingSpinner';
import CustomButton from '../../components/UI/CustomButton';
import UserApprovalListItem from '../../components/ListItem/UserApprovalListItem';
import { useGetAllUserContentsNeedApproval } from '../../hooks/gql/query/getAll/useGetAllUserContentsNeedApproval';

const GET_ALL_USER_CONTENTS_NEED_APPROVAL = gql`
  query GetAllUserContentsNeedApproval($input: PaginationInput!) {
    getAllUserContentsNeedApproval(input: $input) {
      _id
      company_name
      referral_code
      contact_name

      photo
      photoStatus
      isPhotoVisible

      vehiclePictures
      vpStatus
      isVPVisible

      templateMessage
      templateStatus
      isTemplateVisible
    }
  }
`;

const UsersApprovalPage: React.FC<{}> = (props) => {
  const [page_number, setPage_number] = useState(1);
  const navigate = useNavigate();

  const {
    data: users,
    error: usersError,
    loading: usersLoading,
    refetch: usersRefectch,
  } = useGetAllUserContentsNeedApproval(GET_ALL_USER_CONTENTS_NEED_APPROVAL, {
    page: page_number,
    item_per_page: 20,
  });

  return (
    <>
      <div className='main_templates_cards'>
        {usersLoading && (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '50px',
            }}>
            <LoadingSpinner />
          </div>
        )}

        {users && users.length === 0 && (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '50px',
            }}>
            <p>No template requires action</p>
          </div>
        )}
        {users &&
          users?.map((u) => {
            return (
              <UserApprovalListItem
                key={u._id}
                _id={u._id}
                company_name={u.company_name}
                contact_name={u.contact_name}
                photo={u.photo}
                photoStatus={u.photoStatus}
                isPhotoVisible={u.isPhotoVisible}
                vehiclePictures={u.vehiclePictures}
                vpStatus={u.vpStatus}
                templateMessage={u.templateMessage}
                templateStatus={u.templateStatus}
                onActionClick={(_id) => {
                  navigate(`/users-approval/${_id}`);
                }}
              />
            );
          })}
      </div>

      <div className='templates_action_wrapper'>
        <CustomButton
          title='Load More'
          onClick={() => {
            setPage_number((pn) => pn + 1);
            usersRefectch();
          }}
          isLoading={usersLoading}
        />
      </div>

      <div className='footer_content'></div>
    </>
  );
};
export default UsersApprovalPage;
