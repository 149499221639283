import * as React from 'react';
import { alpha, styled } from '@mui/material/styles';
import { DataGrid, gridClasses } from '@mui/x-data-grid';
import { GridColDef } from '@mui/x-data-grid';
import moment from 'moment';
import { gql } from '@apollo/client';
import { formatString, getAmountWithCurrency } from '../../utils/functions';
import ActionEyeButton from '../../components/Utils/ActionEyeButton';
import Colors from '../../constants/Colors';
import { useGetAllCoversByPage } from '../../hooks/gql/query/getAll/useGetAllCoversByPage';
import { useNavigate } from 'react-router-dom';

const coverTableState = {
  initialState: {
    columns: {
      columnVisibilityModel: {
        id: false,
        asked_by: true,
        cover_price: true,
        cover_type: true,
        pickup_location: true,
        destination: true,
        status: true,
        createdAt: true,
        actions: true,
      },
    },
  },
};

const coverTableColumn: GridColDef[] = [
  { field: 'id', headerClassName: 'cover_table_header' },
  {
    field: 'asked_by',
    headerName: 'Asked By',
    minWidth: 120,
    flex: 1,
    headerAlign: 'left',
    align: 'left',
    valueGetter: (params) => params.row?.asked_by?.contact_name,
    headerClassName: 'cover_table_header',
  },
  {
    field: 'cover_price',
    headerName: 'Price',
    minWidth: 120,
    flex: 1,
    headerAlign: 'center',
    valueGetter: (params) => getAmountWithCurrency(params.row?.cover_price),
    align: 'center',
    headerClassName: 'cover_table_header',
  },
  {
    field: 'cover_type',
    headerName: 'Type',
    minWidth: 150,
    flex: 1,
    headerAlign: 'center',
    valueGetter: (params) => formatString(params.row?.cover_type),
    align: 'center',
    headerClassName: 'cover_table_header',
  },
  {
    field: 'pickup_location',
    headerName: 'Pickup',
    flex: 1,
    minWidth: 120,
    headerAlign: 'center',
    valueGetter: (params) =>
      params.row?.trip_info?.pickup_location?.location_name || '---',
    headerClassName: 'cover_table_header',
  },
  {
    field: 'destination',
    headerName: 'Destination',
    flex: 1,
    minWidth: 120,
    valueGetter: (params) =>
      params.row?.trip_info?.destination?.location_name || '---',
    headerAlign: 'center',
    headerClassName: 'cover_table_header',
  },
];

const ODD_OPACITY = 0.2;

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.odd`]: {
    backgroundColor: '#F4F6F6',
    '&:hover, &.Mui-hovered': {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
    '&.Mui-selected': {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity
      ),
      '&:hover, &.Mui-hovered': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity
        ),
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  },
}));

const GET_ALL_COVERS = gql`
  query GetAllCoversByPage($input: PaginationInput!) {
    getAllCoversByPage(input: $input) {
      _id
      cover_price
      cover_type
      status
      createdAt
      asked_by {
        _id
        company_name
        contact_name
      }
      trip_info {
        pickup_location {
          location_name
        }
        destination {
          location_name
        }
      }
    }
  }
`;

const GetCoversPage: React.FC<{}> = (props) => {
  const navigate = useNavigate();
  const {
    data: covers,
    error: coversError,
    loading: coversLoading,
    refetch: coversRefectch,
  } = useGetAllCoversByPage(GET_ALL_COVERS, {
    page: 1,
    item_per_page: 1000,
  });

  const covers_row =
    covers?.map((c) => {
      return { ...c, id: c._id, actions: 'V' };
    }) || [];

  return (
    <>
      <div style={{ height: 631, width: '100%', marginBottom: 150 }}>
        <StripedDataGrid
          pageSizeOptions={[10, 50, 100]}
          columns={[
            ...coverTableColumn,
            {
              field: 'createdAt',
              headerName: 'Date',
              flex: 1,
              minWidth: 180,
              headerAlign: 'center',
              align: 'center',
              headerClassName: 'cover_table_header',
              renderCell: (params) => {
                const data = params.row;
                return (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <p>{moment(data.createdAt).format('lll')}</p>
                  </div>
                );
              },
            },
            {
              field: 'status',
              headerName: 'Status',
              flex: 1,
              minWidth: 120,
              headerAlign: 'center',
              align: 'center',
              headerClassName: 'cover_table_header',
              renderCell: (params) => {
                return (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <p
                      style={{
                        backgroundColor:
                          params.row.status === 'offer_accepted'
                            ? Colors.accentColorDark
                            : params.row.status === 'accepting_offer'
                            ? Colors.accentColorLight
                            : Colors.pclXLight,
                        color:
                          params.row.status === 'offer_accepted'
                            ? Colors.light
                            : params.row.status === 'accepting_offer'
                            ? Colors.light
                            : Colors.primaryColorLight,
                        paddingTop: 3,
                        paddingBottom: 3,
                        paddingLeft: 10,
                        paddingRight: 10,
                        borderRadius: 100,
                      }}>
                      {formatString(params.row.status)}
                    </p>
                  </div>
                );
              },
            },
            {
              field: 'action',
              headerName: 'Action',
              maxWidth: 70,
              minWidth: 70,
              headerAlign: 'center',
              align: 'center',
              sortable: false,
              filterable: false,
              headerClassName: 'cover_table_header',
              renderCell: (params) => {
                const data = params.row;
                return (
                  <div>
                    <ActionEyeButton
                      id={data.id}
                      onEyeClick={(_id) => {
                        navigate(`/get-covers/${_id}`);
                      }}
                    />
                  </div>
                );
              },
            },
          ]}
          rows={covers_row}
          initialState={{
            ...coverTableState.initialState,
            pagination: { paginationModel: { pageSize: 10 } },
          }}
          pagination
          sx={{
            '& .cover_table_header': {
              backgroundColor: '#F3F8FF',
            },
          }}
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
          }
        />
      </div>
    </>
  );
};

export default GetCoversPage;
