import React from 'react';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormLabel from '@mui/material/FormLabel';

interface InputFieldProps {
  label: string;
  value: string;
  placeholder: string;
  onChange: (value: string) => void;
  icon: React.ReactNode; // Accept icon as a prop
}

const InputField: React.FC<InputFieldProps> = ({
  label,
  value,
  placeholder,
  onChange,
  icon,
}) => {
  return (
    <FormControl
      sx={{ m: 1, justifyContent: 'flex-start', alignItems: 'flex-start' }}
      variant='standard'
      className='input_fc'>
      <FormLabel
        component='legend'
        style={{ fontFamily: 'DM Sans', fontSize: 18, marginBottom: 5 }}>
        {label}
      </FormLabel>
      <OutlinedInput
        className='input_fc_item'
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeholder}
        style={{
          backgroundColor: '#F3F8FF',
          color: '#324A59',
        }}
        startAdornment={
          <InputAdornment position='start'>
            <IconButton edge='start'>{icon}</IconButton>
          </InputAdornment>
        }
      />
    </FormControl>
  );
};

export default InputField;
