import * as React from 'react';
import { alpha, styled } from '@mui/material/styles';
import { DataGrid, gridClasses } from '@mui/x-data-grid';
import { GridColDef } from '@mui/x-data-grid';
import { Avatar } from '@mui/material';
import Colors from '../../constants/Colors';
import moment from 'moment';
import ActionButtonsReferrals from '../../components/Utils/ActionButtonsReferrals';
import { useNavigate } from 'react-router-dom';
import { gql } from '@apollo/client';
import { formatString, getNameAvatar } from '../../utils/functions';
import { gBaseUrl } from '../../utils/config';
import { useDeleteReferral } from '../../hooks/gql/mutation/delete/useDeleteReferral';
import { useGetAllReferralsByPage } from '../../hooks/gql/query/getAll/useGetAllReferralsByPage';

const GET_ALL_REFS = gql`
  query GetAllReferralsByPage($input: PaginationInput!) {
    getAllReferralsByPage(input: $input) {
      _id
      createdAt
      referral_token
      status
      trip_info {
        pickup_location {
          cords
          location_name
        }
        destination {
          cords
          location_name
        }
        pickup_datetime
      }
      referred_by {
        _id
        contact_name
        company_name
        photo
        isPhotoVisible
      }
      referred_to {
        _id
        phone_number
      }
    }
  }
`;

const referralTableState = {
  initialState: {
    columns: {
      columnVisibilityModel: {
        id: false,
        company_name: true, // referred by person name
        pickup_location: true,
        destination: true,
        phone: true, // customer phone
        createdAt: true,
        status: true,
        actions: true,
      },
    },
  },
};

const referralTableColumn: GridColDef[] = [
  { field: 'id', headerClassName: 'referrals_table_header' },
  {
    field: 'pickup_location',
    headerName: 'Pickup',
    flex: 1,
    minWidth: 120,
    headerAlign: 'center',
    valueGetter: (params) =>
      params.row?.trip_info?.pickup_location?.location_name || '---',
    headerClassName: 'referrals_table_header',
  },
  {
    field: 'destination',
    headerName: 'Destination',
    flex: 1,
    minWidth: 120,
    valueGetter: (params) =>
      params.row?.trip_info?.destination?.location_name || '---',
    headerAlign: 'center',
    headerClassName: 'referrals_table_header',
  },
  {
    field: 'phone_number',
    headerName: 'Customer Phone',
    minWidth: 180,
    flex: 1,
    headerAlign: 'center',
    valueGetter: (params) => params.row?.referred_to?.phone_number || '---',
    align: 'center',
    headerClassName: 'referrals_table_header',
  },
];

const ODD_OPACITY = 0.2;

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.odd`]: {
    backgroundColor: '#F4F6F6',
    '&:hover, &.Mui-hovered': {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
    '&.Mui-selected': {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity
      ),
      '&:hover, &.Mui-hovered': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity
        ),
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  },
}));

const ReferralsPage: React.FC<{}> = (props) => {
  const navigate = useNavigate();

  const { deleteReferral } = useDeleteReferral();

  const {
    data: referrals,
    error: referralsError,
    loading: referralsLoading,
    refetch: referralsRefectch,
  } = useGetAllReferralsByPage(GET_ALL_REFS, { page: 1, item_per_page: 1000 });

  const referrals_row =
    referrals?.map((b) => {
      return { ...b, id: b._id, actions: 'V' };
    }) || [];

  return (
    <>
      <div style={{ height: 631, width: '100%', marginBottom: 150 }}>
        <StripedDataGrid
          pageSizeOptions={[10, 50, 100]}
          columns={[
            {
              field: 'company_name',
              headerName: 'Referred By',
              minWidth: 200,
              flex: 1,
              headerAlign: 'center',
              valueGetter: (params) => params.row.referred_by?.company_name,
              headerClassName: 'referrals_table_header',
              renderCell: (params) => {
                const data = params.row;
                return (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    {data?.referred_by?.isPhotoVisible ? (
                      <Avatar
                        sizes='s'
                        sx={{
                          bgcolor: Colors.primaryColorLight,
                          marginRight: 1,
                          width: 27,
                          height: 27,
                        }}
                        src={`${gBaseUrl}/photos/avatars/${data?.referred_by?.photo}`}
                      />
                    ) : (
                      <Avatar
                        sizes='s'
                        sx={{
                          bgcolor: Colors.primaryColorLight,
                          marginRight: 1,
                          width: 27,
                          height: 27,
                        }}>
                        <p style={{ fontSize: 12 }}>
                          {getNameAvatar(
                            data?.referred_by?.company_name ||
                              'Customer Created'
                          )}
                        </p>
                      </Avatar>
                    )}

                    <p>
                      {data.referred_by?.company_name || 'Customer Created'}
                    </p>
                  </div>
                );
              },
            },
            ...referralTableColumn,
            {
              field: 'createdAt',
              headerName: 'Time Created',
              flex: 1,
              minWidth: 180,
              headerAlign: 'center',
              align: 'center',
              headerClassName: 'referrals_table_header',
              renderCell: (params) => {
                const data = params.row;
                return (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <p>
                      {moment(data.createdAt).format('MMM DD, YYYY hh:mm a')}
                    </p>
                  </div>
                );
              },
            },
            {
              field: 'status',
              headerName: 'Status',
              flex: 1,
              minWidth: 120,
              headerAlign: 'center',
              align: 'center',
              headerClassName: 'referrals_table_header',
              renderCell: (params) => {
                return (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <p
                      style={{
                        backgroundColor:
                          params.row.status === 'offer_accepted'
                            ? Colors.accentColorDark
                            : params.row.status === 'accepting_offer'
                            ? Colors.accentColorLight
                            : Colors.pclXLight,
                        color:
                          params.row.status === 'offer_accepted'
                            ? Colors.light
                            : params.row.status === 'accepting_offer'
                            ? Colors.light
                            : Colors.primaryColorLight,
                        paddingTop: 3,
                        paddingBottom: 3,
                        paddingLeft: 10,
                        paddingRight: 10,
                        borderRadius: 100,
                      }}>
                      {params.row.status === 'filling_customer_information'
                        ? 'Filling Information'
                        : formatString(params.row.status)}
                    </p>
                  </div>
                );
              },
            },

            {
              field: 'action',
              headerName: 'Action',
              maxWidth: 100,
              headerAlign: 'center',
              align: 'center',
              sortable: false,
              filterable: false,
              headerClassName: 'referrals_table_header',
              renderCell: (params) => {
                const data = params.row;
                return (
                  <div>
                    <ActionButtonsReferrals
                      id={data.id}
                      onEyeClick={(id) => {
                        if (data?.trip_info?.pickup_location) {
                          navigate(`/referrals/${id}`);
                        } else {
                          alert(
                            'No additional information, wait to fill the information.'
                          );
                        }
                      }}
                      onDeleteClick={async (_id) => {
                        await deleteReferral({
                          _id,
                        });

                        referralsRefectch();
                      }}
                    />
                  </div>
                );
              },
            },
          ]}
          rows={referrals_row}
          initialState={{
            ...referralTableState.initialState,
            pagination: { paginationModel: { pageSize: 10 } },
          }}
          pagination
          sx={{
            '& .referrals_table_header': {
              backgroundColor: '#F3F8FF',
            },
          }}
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
          }
        />
      </div>
    </>
  );
};
export default ReferralsPage;
