import * as React from 'react';
import { alpha, styled } from '@mui/material/styles';
import { DataGrid, gridClasses } from '@mui/x-data-grid';
import { GridColDef } from '@mui/x-data-grid';
import { Avatar } from '@mui/material';
import Colors from '../../constants/Colors';
import ActionsButtonsCustomer from '../../components/Utils/ActionButtonsCustomer';
import moment from 'moment';
import { gql } from '@apollo/client';
import { getAmountWithCurrency, getNameAvatar } from '../../utils/functions';
import { useDeleteCustomer } from '../../hooks/gql/mutation/delete/useDeleteCustomer';
import { useGetAllCustomersByPage } from '../../hooks/gql/query/getAll/useGetAllCustomersByPage';

const customerTableState = {
  initialState: {
    columns: {
      columnVisibilityModel: {
        id: false,
        name: true,
        phone_number: true,
        email: true,
        total_spent: true,
        total_charge_paid: true,
        last_booked: true,
        actions: true,
      },
    },
  },
};

const customerTableColumn: GridColDef[] = [
  { field: 'id', headerClassName: 'customer_table_header' },
  {
    field: 'phone_number',
    headerName: 'Phone Number',
    minWidth: 180,
    flex: 1,
    headerAlign: 'center',
    align: 'center',
    headerClassName: 'customer_table_header',
  },

  {
    field: 'email',
    headerName: 'Email',
    flex: 1,
    minWidth: 180,
    headerAlign: 'center',
    headerClassName: 'customer_table_header',
  },
];

const ODD_OPACITY = 0.2;

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.odd`]: {
    backgroundColor: '#F4F6F6',
    '&:hover, &.Mui-hovered': {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
    '&.Mui-selected': {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity
      ),
      '&:hover, &.Mui-hovered': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity
        ),
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  },
}));

const GET_ALL_CUSTOMERS = gql`
  query GetAllCustomersByPage($input: PaginationInput!) {
    getAllCustomersByPage(input: $input) {
      _id
      name
      email
      phone_number
      traveller_id
      total_spent
      total_charge_paid
      last_booked
    }
  }
`;

const CustomersPage: React.FC<{}> = (props) => {
  const { deleteCustomer } = useDeleteCustomer();

  const {
    data: customers,
    error: customersError,
    loading: customersLoading,
    refetch: customersRefectch,
  } = useGetAllCustomersByPage(GET_ALL_CUSTOMERS, {
    page: 1,
    item_per_page: 1000,
  });

  const customers_row =
    customers?.map((c) => {
      return { ...c, id: c._id, actions: 'V' };
    }) || [];

  return (
    <>
      <div style={{ height: 631, width: '100%', marginBottom: 150 }}>
        <StripedDataGrid
          pageSizeOptions={[10, 50, 100]}
          columns={[
            {
              field: 'name',
              headerName: 'Customer Name',
              minWidth: 180,
              flex: 1,
              headerAlign: 'center',
              headerClassName: 'customer_table_header',
              renderCell: (params) => {
                const data = params.row;
                return (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <Avatar
                      sizes='s'
                      sx={{
                        bgcolor: Colors.primaryColorLight,
                        marginRight: 1,
                        width: 27,
                        height: 27,
                      }}>
                      <p style={{ fontSize: 12 }}>
                        {getNameAvatar(data?.name)}
                      </p>
                    </Avatar>
                    <p>{data?.name}</p>
                  </div>
                );
              },
            },
            ...customerTableColumn,
            {
              field: 'total_spent',
              headerName: 'Total Spent',
              minWidth: 120,
              headerAlign: 'center',
              align: 'center',
              headerClassName: 'customer_table_header',
              renderCell: (params) => {
                const data = params.row;
                return (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <p>{getAmountWithCurrency(data?.total_spent)}</p>
                  </div>
                );
              },
            },
            {
              field: 'total_charge_paid',
              headerName: 'Charge Paid',
              minWidth: 120,
              headerAlign: 'center',
              align: 'center',
              headerClassName: 'customer_table_header',
              renderCell: (params) => {
                const data = params.row;
                return (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <p>{getAmountWithCurrency(data?.total_charge_paid)}</p>
                  </div>
                );
              },
            },
            {
              field: 'last_booked',
              headerName: 'Last Booked',
              minWidth: 130,
              flex: 1,
              headerAlign: 'center',
              align: 'center',
              headerClassName: 'customer_table_header',
              renderCell: (params) => {
                const data = params.row;
                return (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <p>
                      {data?.last_booked
                        ? moment(data?.last_booked).format('MMM DD, YYYY')
                        : '---'}
                    </p>
                  </div>
                );
              },
            },
            {
              field: 'action',
              headerName: 'Action',
              maxWidth: 70,
              minWidth: 70,
              headerAlign: 'center',
              align: 'center',
              sortable: false,
              filterable: false,
              headerClassName: 'customer_table_header',
              renderCell: (params) => {
                const data = params.row;
                return (
                  <div>
                    <ActionsButtonsCustomer
                      id={data.id}
                      onDelete={async (_id) => {
                        await deleteCustomer({
                          _id,
                        });

                        customersRefectch();
                      }}
                    />
                  </div>
                );
              },
            },
          ]}
          rows={customers_row}
          initialState={{
            ...customerTableState.initialState,
            pagination: { paginationModel: { pageSize: 10 } },
          }}
          pagination
          sx={{
            '& .customer_table_header': {
              backgroundColor: '#F3F8FF',
            },
          }}
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
          }
        />
      </div>
    </>
  );
};
export default CustomersPage;
