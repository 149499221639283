import { useSelector } from 'react-redux';
import {
  useQuery,
  DocumentNode,
  ApolloError,
  ApolloQueryResult,
  OperationVariables,
} from '@apollo/client';
import { RootState } from '../../../../store/store';
import { PaginationInput } from '../../../../types/schema/utils.types';
import { Booking } from '../../../../types/schema/booking.types';

interface GetAllBookingsByPageHook {
  data: Booking[] | undefined;
  loading: boolean;
  error: ApolloError | undefined;
  refetch: (variables?: Partial<OperationVariables> | undefined) => Promise<
    ApolloQueryResult<{
      getAllBookingsByPage: Booking[];
    }>
  >;
}

interface GetAllBookingsByPageMutationData {
  getAllBookingsByPage: Booking[];
}

interface GetAllBookingsByPageInputType {
  input: PaginationInput;
}

export function useGetAllBookingsByPage(
  query: DocumentNode,
  input: PaginationInput
): GetAllBookingsByPageHook {
  const { jwt } = useSelector((state: RootState) => state.auth);

  const { data, loading, error, refetch } = useQuery<
    GetAllBookingsByPageMutationData,
    GetAllBookingsByPageInputType
  >(query, {
    variables: {
      input,
    },
    context: {
      headers: {
        cookie: `jwt=${jwt}`,
        authorization: jwt ? `Bearer ${jwt}` : '',
      },
    },
  });

  return {
    data: data?.getAllBookingsByPage,
    loading,
    error,
    refetch,
  };
}
