import * as React from 'react';
import { alpha, styled } from '@mui/material/styles';
import { DataGrid, gridClasses } from '@mui/x-data-grid';
import { GridColDef } from '@mui/x-data-grid';
import Colors from '../../constants/Colors';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { gql } from '@apollo/client';
import { formatString, getAmountWithCurrency } from '../../utils/functions';
import { useGetAllPaymentsByStatusByPage } from '../../hooks/gql/query/getAll/useGetAllPaymentsByStatusByPage';
import ActionButtonsPayments from '../../components/Utils/ActionButtonsPayments';

const GET_ALL_PAYMENTS_BY_STATUS_BY_PAGE = gql`
  query GetAllPaymentsByStatusByPage($input: PaginationWithTextInput!) {
    getAllPaymentsByStatusByPage(input: $input) {
      _id
      admin_revenue
      amount_paid
      createdAt
      payment_status
      payment_type
      referrer_revenue
    }
  }
`;

const payoutTableState = {
  initialState: {
    columns: {
      columnVisibilityModel: {
        id: false,
        payment_type: true,
        amount_paid: true,
        admin_revenue: true,
        createdAt: true,
        payment_status: true,
        actions: true,
      },
    },
  },
};

const payoutTableColumn: GridColDef[] = [
  { field: 'id', headerClassName: 'payouts_table_header' },
];

const ODD_OPACITY = 0.2;

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.odd`]: {
    backgroundColor: '#F4F6F6',
    '&:hover, &.Mui-hovered': {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
    '&.Mui-selected': {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity
      ),
      '&:hover, &.Mui-hovered': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity
        ),
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  },
}));

const PayoutRequestsPage: React.FC<{}> = (props) => {
  const navigate = useNavigate();

  const {
    data: payouts,
    error: payoutsError,
    loading: payoutsLoading,
    refetch: payoutsRefectch,
  } = useGetAllPaymentsByStatusByPage(GET_ALL_PAYMENTS_BY_STATUS_BY_PAGE, {
    text: 'payout_request',
    page: 1,
    item_per_page: 1000,
  });

  const payouts_row =
    payouts?.map((b: any) => {
      return { ...b, id: b._id, actions: 'V' };
    }) || [];

  return (
    <>
      <div style={{ height: 631, width: '100%', marginBottom: 150 }}>
        <StripedDataGrid
          pageSizeOptions={[10, 50, 100]}
          columns={[
            ...payoutTableColumn,
            {
              field: 'payment_type',
              headerName: 'Indentifier',
              flex: 1,
              width: 120,
              headerAlign: 'center',
              align: 'center',
              headerClassName: 'payouts_table_header',
              renderCell: (params) => {
                const data = params.row;
                return (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <p>{formatString(data?.payment_type)}</p>
                  </div>
                );
              },
            },
            {
              field: 'amount_paid',
              headerName: 'Amount Requested',
              flex: 1,
              width: 120,
              headerAlign: 'center',
              align: 'center',
              headerClassName: 'payouts_table_header',
              renderCell: (params) => {
                const data = params.row;
                return (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <p>{getAmountWithCurrency(data?.amount_paid)}</p>
                  </div>
                );
              },
            },
            {
              field: 'admin_revenue',
              headerName: 'Admin Revenue',
              flex: 1,
              width: 120,
              headerAlign: 'center',
              align: 'center',
              headerClassName: 'payouts_table_header',
              renderCell: (params) => {
                const data = params.row;
                return (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <p
                      style={{
                        color:
                          data?.admin_revenue < 1
                            ? Colors.accentColorDark
                            : Colors.primaryColorLight,
                      }}>
                      {getAmountWithCurrency(data?.admin_revenue)}
                    </p>
                  </div>
                );
              },
            },
            {
              field: 'createdAt',
              headerName: 'Date and Time',
              flex: 1,
              minWidth: 180,
              headerAlign: 'center',
              align: 'center',
              headerClassName: 'payouts_table_header',
              renderCell: (params) => {
                const data = params.row;
                return (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <p>
                      {moment(data?.createdAt).format('MMM DD, YYYY hh:mm A')}
                    </p>
                  </div>
                );
              },
            },
            {
              field: 'payment_status',
              headerName: 'Status',
              flex: 1,
              minWidth: 100,
              headerAlign: 'center',
              align: 'center',
              headerClassName: 'payouts_table_header',
              renderCell: (params) => {
                const data = params.row;
                return (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <p
                      style={{
                        backgroundColor:
                          data?.payment_status === 'pending'
                            ? Colors.accentColorDark
                            : Colors.pclXLight,
                        color:
                          data?.payment_status === 'pending'
                            ? Colors.light
                            : Colors.primaryColorLight,
                        paddingTop: 3,
                        paddingBottom: 3,
                        paddingLeft: 10,
                        paddingRight: 10,
                        borderRadius: 100,
                      }}>
                      {formatString(data?.payment_status)}
                    </p>
                  </div>
                );
              },
            },

            {
              field: 'actions',
              headerName: 'Action',
              maxWidth: 100,
              headerAlign: 'center',
              align: 'center',
              sortable: false,
              filterable: false,
              headerClassName: 'payouts_table_header',
              renderCell: (params) => {
                const data = params.row;
                return (
                  <div>
                    <ActionButtonsPayments
                      id={data.id}
                      onEyeClick={(id) => {
                        navigate(`/payout-requests/${id}`);
                      }}
                    />
                  </div>
                );
              },
            },
          ]}
          rows={payouts_row}
          initialState={{
            ...payoutTableState.initialState,
            pagination: { paginationModel: { pageSize: 10 } },
          }}
          pagination
          sx={{
            '& .payouts_table_header': {
              backgroundColor: '#F3F8FF',
            },
          }}
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
          }
        />
      </div>
    </>
  );
};
export default PayoutRequestsPage;
