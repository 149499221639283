import './ReferralsDetailsConversationPage.scss';
import { FormControl, InputAdornment, OutlinedInput } from '@mui/material';
import Colors from '../../constants/Colors';
import SupportCCListItem from '../../components/ListItem/SupportCCListItem';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { gql } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import RDCSection from './RDCSection';
import { useGetAllProposalsByRefIdBySearch } from '../../hooks/gql/query/getAll/useGetAllProposalsByRefIdBySearch';
import { Proposal } from '../../types/schema/proposal.types';
import { User } from '../../types/schema/user.types';
import { useGetReferralDetailsById } from '../../hooks/gql/query/getById/useGetReferralDetailsById';

const GET_ENQ_DETAILS = gql`
  query GetReferralDetailsById($input: MongoIdInput!) {
    getReferralDetailsById(input: $input) {
      _id
      status
      referred_to {
        _id
        name
        phone_number
        email
      }
      referred_by {
        _id
        referral_code
        company_name
        photo
        isPhotoVisible
      }
      proposals {
        _id
        agreed_amount
        proposed_by {
          _id
          company_name
          phone
          photo
          isPhotoVisible
          referral_code
        }
      }
      favourite_companies {
        _id
        points
        total_referrals
        updatedAt
      }
      trip_info {
        pickup_location {
          cords
          location_name
        }
        destination {
          cords
          location_name
        }
        tripType
        pickup_datetime
        return_datetime
        passenger_count
        travelling_reason
        luggage_type
        vehicle_count
        vehicle_type
        note
      }
    }
  }
`;

const GET_ALL_PROPOSALS_BY_REFS = gql`
  query GetAllProposalsByRefIdBySearch($input: PaginationWIDSearchInput!) {
    getAllProposalsByRefIdBySearch(input: $input) {
      _id
      proposed_by {
        _id
        referral_code
        contact_name
        phone
        photo
        isPhotoVisible
      }
    }
  }
`;

const ReferralsDetailsConversationPage: React.FC<{}> = (props) => {
  const { search } = useLocation();
  const { slug } = useParams();
  const [searchQ, setSearchQ] = useState('');
  const [currentProposal, setCurrentProposal] = useState(
    '64f8267c7ed1f6aa0166bde1'
  );
  const [selectedCompany, setSelectedCompany] = useState(
    '64f8267c7ed1f6aa0166bde1'
  );

  const {
    data: referral,
    error: referralError,
    loading: referralLoading,
    refetch: referralRefectch,
  } = useGetReferralDetailsById(GET_ENQ_DETAILS, {
    _id: slug || '64f8267c7ed1f6aa0166bde1',
  });

  const {
    data: proposals,
    error: proposalsError,
    loading: proposalsLoading,
    refetch: proposalsRefectch,
  } = useGetAllProposalsByRefIdBySearch(GET_ALL_PROPOSALS_BY_REFS, {
    _id: slug || '64f8267c7ed1f6aa0166bde1',
    query: searchQ,
    page: 1,
    item_per_page: 20,
  });

  const favCompanies = referral?.favourite_companies || [];
  const sortedProposal: Proposal[] = [];
  const favIds = favCompanies?.map((fc: User) => fc?._id);
  proposals?.forEach((p) => {
    if (favIds.includes(p?.proposed_by?._id)) {
      sortedProposal.unshift(p);
    } else {
      sortedProposal.push(p);
    }
  });

  useEffect(() => {
    if (proposals && proposals?.length > 0) {
      setCurrentProposal(proposals[0]._id);
      setSelectedCompany(proposals[0].proposed_by._id);
    }
  }, [proposalsLoading]);

  useEffect(() => {
    const sQuery = search.split('=')[1];
    if (sQuery) {
      const formattedQuery = decodeURIComponent(sQuery);
      setSearchQ(formattedQuery);
    }
  }, [search.split('=')[1]]);

  return (
    <div className='rdcmessage_wrapper'>
      <div className='customer_list'>
        <div className='customer_list_search'>
          <FormControl
            sx={{
              m: 1,
              width: '88%',
              borderRadius: 1,
              backgroundColor: Colors.lightBg,
              margin: 0,
            }}
            variant='outlined'>
            <OutlinedInput
              id='s_search'
              size='small'
              placeholder='Search Customer'
              style={{}}
              value={searchQ}
              onChange={(e) => {
                setSearchQ(e.target.value);
              }}
              startAdornment={
                <InputAdornment position='start'>
                  <SearchOutlinedIcon />
                </InputAdornment>
              }
            />
          </FormControl>
        </div>
        <div className='customer_list_items'>
          {sortedProposal?.map((c) => {
            return (
              <SupportCCListItem
                key={c._id}
                active_s_id={currentProposal}
                id={c._id}
                s_name={c.proposed_by.contact_name}
                s_id={c.proposed_by.phone}
                photo={c.proposed_by.photo}
                isPhotoVisible={c.proposed_by.isPhotoVisible}
                onItemClick={(id) => {
                  setCurrentProposal(id);
                }}
              />
            );
          })}
        </div>
      </div>

      <RDCSection
        selectedProposal={currentProposal}
        selectedCompany={selectedCompany}
      />
    </div>
  );
};
export default ReferralsDetailsConversationPage;
