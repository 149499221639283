import { useSelector } from 'react-redux';
import {
  useQuery,
  ApolloError,
  ApolloQueryResult,
  OperationVariables,
  gql,
} from '@apollo/client';
import { RootState } from '../../../../store/store';

const GQL_QUE = gql`
  query GetAllReferralsCount {
    getAllReferralsCount
  }
`;

interface GetAllReferralsCountHook {
  data: number | undefined;
  loading: boolean;
  error: ApolloError | undefined;
  refetch: (variables?: Partial<OperationVariables> | undefined) => Promise<
    ApolloQueryResult<{
      getAllReferralsCount: number;
    }>
  >;
}

interface GetAllReferralsCountMutationData {
  getAllReferralsCount: number;
}

export function useGetAllReferralsCount(): GetAllReferralsCountHook {
  const { jwt } = useSelector((state: RootState) => state.auth);

  const { data, loading, error, refetch } =
    useQuery<GetAllReferralsCountMutationData>(GQL_QUE, {
      context: {
        headers: {
          cookie: `jwt=${jwt}`,
          authorization: jwt ? `Bearer ${jwt}` : '',
        },
      },
    });

  return {
    data: data?.getAllReferralsCount,
    loading,
    error,
    refetch,
  };
}
