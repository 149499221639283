import { useSelector } from 'react-redux';
import {
  useQuery,
  DocumentNode,
  ApolloError,
  ApolloQueryResult,
  OperationVariables,
} from '@apollo/client';
import { LeaderBoardObjType } from '../../../types/schema/user.types';
import { MongoIdInput } from '../../../types/schema/utils.types';
import { RootState } from '../../../store/store';

interface GetLeaderboardPositionByUserIdHook {
  data: LeaderBoardObjType | undefined;
  loading: boolean;
  error: ApolloError | undefined;
  refetch: (variables?: Partial<OperationVariables> | undefined) => Promise<
    ApolloQueryResult<{
      getLeaderboardPositionByUserId: LeaderBoardObjType;
    }>
  >;
}

interface GetLeaderboardPositionByUserIdMutationData {
  getLeaderboardPositionByUserId: LeaderBoardObjType;
}

interface GetLeaderboardPositionByUserIdInputType {
  input: MongoIdInput;
}

export function useGetLeaderboardPositionByUserId(
  query: DocumentNode,
  input: MongoIdInput
): GetLeaderboardPositionByUserIdHook {
  const { jwt } = useSelector((state: RootState) => state.auth);

  const { data, loading, error, refetch } = useQuery<
    GetLeaderboardPositionByUserIdMutationData,
    GetLeaderboardPositionByUserIdInputType
  >(query, {
    variables: {
      input,
    },
    context: {
      headers: {
        cookie: `jwt=${jwt}`,
        authorization: jwt ? `Bearer ${jwt}` : '',
      },
    },
  });

  return {
    data: data?.getLeaderboardPositionByUserId,
    loading,
    error,
    refetch,
  };
}
