import { Suspense } from 'react';
import Layout from './components/Layout/Layout';
import LoadingSpinner from './components/UI/LoadingSpinner';
import { useSelector } from 'react-redux';
import { RootState } from './store/store';
import { Route, Routes } from 'react-router-dom';
import LoginPage from './pages/login/LoginPage';
import ErrorPage from './pages/error/ErrorPage';
import DashboardPage from './pages/dashboard/DashboardPage';
import CompaniesPage from './pages/companies/CompaniesPage';
import CustomersPage from './pages/customers/CustomersPage';
import ReferralsPage from './pages/referrals/ReferralsPage';
import PaymentsPage from './pages/payments/PaymentsPage';
import TestimonialsPage from './pages/testimonials/TestimonialsPage';
import SettingsPage from './pages/settings/SettingsPage';
import CompaniesDetailsPage from './pages/companies/CompaniesDetailsPage';
import ReferralsDetailsPage from './pages/referrals/ReferralsDetailsPage';
import PaymentsDetailsPage from './pages/payments/PaymentsDetailsPage';
import PayoutRequestsDetailsPage from './pages/payoutRequests/PayoutRequestsDetailsPage';
import PayoutRequestsPage from './pages/payoutRequests/PayoutRequestsPage';
import CompanyMessagesPage from './pages/companyMessages/CompanyMessagesPage';
import CustomerMessagesPage from './pages/customerMessages/CustomerMessagesPage';
import CustomerReviewsPage from './pages/customerReviews/CustomerReviewsPage';
import ReferralsDetailsConversationPage from './pages/referrals/ReferralsDetailsConversationPage';
import UsersApprovalPage from './pages/usersApproval/UsersApprovalPage';
import UsersApprovalDetailsPage from './pages/usersApproval/UsersApprovalDetailsPage';
import InAppWalletsPage from './pages/inAppWallets/InAppWalletsPage';
import InAppWalletsDetailsPage from './pages/inAppWallets/InAppWalletsDetailsPage';
import InAppWalletsDetailsTransactionsPage from './pages/inAppWallets/InAppWalletsDetailsTransactionsPage';
import BookingsPage from './pages/bookings/BookingsPage';
import GetCoversPage from './pages/getCovers/GetCoversPage';
import GetCoversDetailsPage from './pages/getCovers/GetCoversDetailsPage';
import GetCoversDetailsConversationsPage from './pages/getCovers/GetCoversDetailsConversationsPage';
import QuotationsPage from './pages/quotations/QuotationsPage';
import CancellationsPage from './pages/cancellations/CancellationsPage';
import CompaniesDetailsTransactionsPage from './pages/companies/CompaniesDetailsTransactionsPage';

const App = () => {
  const { isAuthenticated } = useSelector((state: RootState) => state.auth);

  return (
    <Layout>
      <Suspense
        fallback={
          <div className='centered'>
            <LoadingSpinner />
          </div>
        }>
        <Routes>
          {!isAuthenticated && (
            <>
              <Route path='/' element={<LoginPage />} />
              <Route path='login' element={<LoginPage />} />
            </>
          )}

          {isAuthenticated && (
            <>
              <Route path='/' element={<DashboardPage />} />
              <Route path='dashboard' element={<DashboardPage />} />

              <Route path='companies'>
                <Route index={true} element={<CompaniesPage />} />
                <Route path=':slug'>
                  <Route index={true} element={<CompaniesDetailsPage />} />
                  <Route
                    path='transactions'
                    element={<CompaniesDetailsTransactionsPage />}
                  />
                </Route>
              </Route>

              <Route path='customers' element={<CustomersPage />} />

              <Route path='users-approval'>
                <Route index={true} element={<UsersApprovalPage />} />
                <Route path=':slug' element={<UsersApprovalDetailsPage />} />
              </Route>

              <Route path='company-messages'>
                <Route index={true} element={<CompanyMessagesPage />} />
              </Route>

              <Route path='customer-messages'>
                <Route index={true} element={<CustomerMessagesPage />} />
              </Route>

              <Route path='payout-requests'>
                <Route index={true} element={<PayoutRequestsPage />} />
                <Route path=':slug' element={<PayoutRequestsDetailsPage />} />
              </Route>

              <Route path='in-app-wallets'>
                <Route index={true} element={<InAppWalletsPage />} />
                <Route path=':slug' element={<InAppWalletsDetailsPage />} />
                <Route
                  path=':slug/transactions'
                  element={<InAppWalletsDetailsTransactionsPage />}
                />
              </Route>

              <Route path='payments'>
                <Route index={true} element={<PaymentsPage />} />
                <Route path=':slug' element={<PaymentsDetailsPage />} />
              </Route>

              <Route path='bookings'>
                <Route index={true} element={<BookingsPage />} />
              </Route>

              <Route path='get-covers'>
                <Route index={true} element={<GetCoversPage />} />
                <Route path=':slug' element={<GetCoversDetailsPage />} />
                <Route
                  path=':slug/conversations'
                  element={<GetCoversDetailsConversationsPage />}
                />
              </Route>

              <Route path='quotations'>
                <Route index={true} element={<QuotationsPage />} />
              </Route>

              <Route path='cancellations'>
                <Route index={true} element={<CancellationsPage />} />
              </Route>

              <Route path='referrals'>
                <Route index={true} element={<ReferralsPage />} />
                <Route path=':slug' element={<ReferralsDetailsPage />} />
                <Route
                  path=':slug/conversations'
                  element={<ReferralsDetailsConversationPage />}
                />
              </Route>

              <Route path='testimonials' element={<TestimonialsPage />} />

              <Route
                path='customer-reviews'
                element={<CustomerReviewsPage />}
              />

              <Route path='settings' element={<SettingsPage />} />
            </>
          )}

          <Route path='*' element={<ErrorPage />} />
        </Routes>
      </Suspense>
    </Layout>
  );
};

export default App;
