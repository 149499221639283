import { Avatar, Button, FormControl, Grid } from '@mui/material';
import './CompaniesDetailsPage.scss';
import { gql } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import {
  getAmountWithCurrency,
  getNameAvatar,
  getServiceAreaString,
  getVehicleSeats,
} from '../../utils/functions';
import Colors from '../../constants/Colors';
import CompanyListItem from '../../components/ListItem/CompanyListItem';
import TransactionListItem from '../../components/ListItem/TransactionListItem';
import { gBaseUrl } from '../../utils/config';
import { useUpdateUser } from '../../hooks/gql/mutation/update/useUpdateUser';
import { useDeleteUser } from '../../hooks/gql/mutation/delete/useDeleteUser';
import { useGetUserDetailsById } from '../../hooks/gql/query/getById/useGetUserDetailsById';
import { useGetLeaderboardPositionByUserId } from '../../hooks/gql/query/useGetLeaderboardPositionByUserId';
import { useGetAllTransactionsByUserIdByPage } from '../../hooks/gql/query/getAll/useGetAllTransactionsByUserIdByPage';
import { useGetAllContactsByUserId } from '../../hooks/gql/query/getAll/useGetAllContactsByUserId';
import SettingsCheckbox from '../../components/Input/SettingsCheckbox';
//
const GET_USER_DETAILS_BY_ID = gql`
  query GetUserDetailsById($input: MongoIdInput!) {
    getUserDetailsById(input: $input) {
      _id
      company_name
      photo
      isPhotoVisible
      referral_code
      total_earning
      final_wallet_balance
      link_refer_earning
      total_referrals
      business_address {
        location_name
      }
      total_cancellations
      isSuspended
      vehicle_type
      phone
      contact_name
      email
      contact_name
      templateMessage
      vehiclePictures
      settings {
        add_partner
        ask_for_cover
        proposal_on_ce
        proposal_on_gc
        send_cancellation
        send_premium_quotation
        send_quotation
        storing_booking
      }
      service_areas {
        identifier
        location_name
        cords {
          type
          coordinates
        }
        radius
      }
    }
  }
`;

const GET_USER_LEADERBOARD_POSITION = gql`
  query GetLeaderboardPositionByUserId($input: MongoIdInput!) {
    getLeaderboardPositionByUserId(input: $input) {
      _id
      position
      points
    }
  }
`;

const GET_ALL_TRANSACTIONS_BY_USER_ID = gql`
  query GetAllTransactionsByUserIdByPage($input: PaginationWithIdInput!) {
    getAllTransactionsByUserIdByPage(input: $input) {
      _id
      amount
      transaction_status
      transaction_token
      transaction_type
      createdAt
      mode
    }
  }
`;

const GET_ALL_CONTACTS_BY_USER_ID = gql`
  query GetAllContactsByUserId($input: MongoIdInput!) {
    getAllContactsByUserId(input: $input) {
      _id
      company_name
      contact_name
      photo
      isPhotoVisible
      referral_code
      total_earning
    }
  }
`;

const CompaniesDetailsPage: React.FC<{}> = (props) => {
  const navigate = useNavigate();
  const { slug } = useParams();

  const { updateUser } = useUpdateUser();
  const { deleteUser } = useDeleteUser();

  const {
    data: user,
    error: userError,
    loading: userLoading,
    refetch: userRefectch,
  } = useGetUserDetailsById(GET_USER_DETAILS_BY_ID, {
    _id: slug || '64f8267c7ed1f6aa0166bde1',
  });

  const {
    data: leaderboard_position,
    error: leaderboard_positionError,
    loading: leaderboard_positionLoading,
    refetch: leaderboard_positionRefectch,
  } = useGetLeaderboardPositionByUserId(GET_USER_LEADERBOARD_POSITION, {
    _id: slug || '64f8267c7ed1f6aa0166bde1',
  });

  const {
    data: contacts,
    error: contactsError,
    loading: contactsLoading,
    refetch: contactsRefectch,
  } = useGetAllContactsByUserId(GET_ALL_CONTACTS_BY_USER_ID, {
    _id: slug || '64f8267c7ed1f6aa0166bde1',
  });

  const {
    data: transactions,
    error: transactionsError,
    loading: transactionsLoading,
    refetch: transactionsRefectch,
  } = useGetAllTransactionsByUserIdByPage(GET_ALL_TRANSACTIONS_BY_USER_ID, {
    _id: slug || '64f8267c7ed1f6aa0166bde1',
    page: 1,
    item_per_page: 5,
  });

  const settings = user?.settings;

  return (
    <>
      <div className='companies_details_wrapper'>
        <div className='company_details_section'>
          {user && (
            <>
              <div className='company_details_section_header'>
                <div className='title_logo'>
                  {user.isPhotoVisible ? (
                    <Avatar
                      src={`${gBaseUrl}/photos/avatars/${user.photo}`}
                      sx={{
                        bgcolor: Colors.pclXLight,
                        marginRight: 1,
                        color: Colors.primaryColorLight,
                        boxShadow: '0px 10px 15px -3px rgba(0, 0, 0, 0.1)',
                      }}
                    />
                  ) : (
                    <Avatar
                      sx={{
                        width: 40,
                        height: 40,
                        bgcolor: Colors.pclXLight,
                        marginRight: 1,
                        color: Colors.primaryColorLight,
                        boxShadow: '0px 10px 15px -3px rgba(0, 0, 0, 0.1)',
                      }}>
                      {getNameAvatar(user.company_name)}
                    </Avatar>
                  )}

                  <div className='title_logo_text'>
                    <p className='business_name' style={{ fontWeight: 'bold' }}>
                      {user?.company_name}
                    </p>
                    <p>#{user.referral_code}</p>
                  </div>
                </div>

                <div className='title_stats'>
                  <p>Rank: #{leaderboard_position?.position}</p>
                  <p>Booking Cancelled: {user.total_cancellations}</p>
                </div>
              </div>

              {settings && (
                <div className='company_details_section_det0'>
                  <Grid container spacing={0}>
                    <Grid item xs={12} sm={6} md={3}>
                      <SettingsCheckbox
                        label='Proposal on CE'
                        settingKey='proposal_on_ce'
                        initialValue={settings.proposal_on_ce}
                        userId={user._id}
                        settings={settings}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <SettingsCheckbox
                        label='Proposal on GC'
                        settingKey='proposal_on_gc'
                        initialValue={settings.proposal_on_gc}
                        userId={user._id}
                        settings={settings}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <SettingsCheckbox
                        label='Storing Booking'
                        settingKey='storing_booking'
                        initialValue={settings.storing_booking}
                        userId={user._id}
                        settings={settings}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <SettingsCheckbox
                        label='Add Partner'
                        settingKey='add_partner'
                        initialValue={settings.add_partner}
                        userId={user._id}
                        settings={settings}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <SettingsCheckbox
                        label='Ask for Cover'
                        settingKey='ask_for_cover'
                        initialValue={settings.ask_for_cover}
                        userId={user._id}
                        settings={settings}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <SettingsCheckbox
                        label='Send Premium Quotation'
                        settingKey='send_premium_quotation'
                        initialValue={settings.send_premium_quotation}
                        userId={user._id}
                        settings={settings}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <SettingsCheckbox
                        label='Send Quotation'
                        settingKey='send_quotation'
                        initialValue={settings.send_quotation}
                        userId={user._id}
                        settings={settings}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <SettingsCheckbox
                        label='Send Cancellation'
                        settingKey='send_cancellation'
                        initialValue={settings.send_cancellation}
                        userId={user._id}
                        settings={settings}
                      />
                    </Grid>
                  </Grid>
                </div>
              )}

              <div className='company_details_section_det1'>
                <div className='company_details_section_det1_p1'>
                  <div className=''>
                    <p>Total Earnings</p>
                    <p style={{ fontWeight: 'bold' }}>
                      {getAmountWithCurrency(user.total_earning)}
                    </p>
                  </div>

                  <div className=''>
                    <p>Wallet Balance</p>
                    <p style={{ fontWeight: 'bold' }}>
                      {getAmountWithCurrency(user.final_wallet_balance)}
                    </p>
                  </div>
                </div>
                <div className='company_details_section_det1_p2'>
                  <div className=''>
                    <p>Total Referral</p>
                    <p style={{ fontWeight: 'bold' }}>{user.total_referrals}</p>
                  </div>

                  <div className=''>
                    <p>Phone</p>
                    <p style={{ fontWeight: 'bold' }}>{user.phone}</p>
                  </div>
                </div>
              </div>
              <div className='company_details_section_det2'>
                <div className='company_details_section_det2_p1'>
                  <div className=''>
                    <p>Contact Name</p>
                    <p style={{ fontWeight: 'bold' }}>{user.contact_name}</p>
                  </div>

                  <div className=''>
                    <p>Email</p>
                    <p style={{ fontWeight: 'bold' }}>{user.email}</p>
                  </div>
                </div>
                <div className='company_details_section_det2_p2'>
                  <div className=''>
                    <p>Company Address</p>
                    <p style={{ fontWeight: 'bold' }}>
                      {user.business_address.location_name}
                    </p>
                  </div>

                  <div className=''>
                    <p>Vehicle Capacity</p>
                    <p style={{ fontWeight: 'bold' }}>
                      {getVehicleSeats(user.vehicle_type)}
                    </p>
                  </div>
                </div>
              </div>

              <div className='company_details_section_det3'>
                <div className='company_details_section_det3_p1'>
                  <div className=''>
                    <p style={{ fontWeight: 'bold' }}>Template Message</p>
                    <pre style={{ marginTop: 10 }}>{user?.templateMessage}</pre>
                  </div>
                </div>
                <div className='company_details_section_det3_p2'>
                  <div className=''>
                    <p style={{ fontWeight: 'bold' }}>Service Areas</p>
                    <p
                      style={{
                        marginTop: 10,
                        whiteSpace: 'pre-wrap',
                      }}>
                      {getServiceAreaString(user.service_areas)}
                    </p>
                  </div>
                </div>
              </div>
              <div className='company_details_section_images'>
                <div className='cds_image'>
                  <p style={{ fontWeight: 'bold' }}>Vehicle Images</p>
                  <div className='v_images_wrapper'>
                    {user.vehiclePictures &&
                      user.vehiclePictures.map((vp: string) => {
                        return (
                          <div className='v_image_item' key={vp}>
                            <img
                              style={{ objectFit: 'cover' }}
                              src={`${gBaseUrl}/photos/vehiclePictures/${vp}`}
                              alt='Vehicle'
                            />
                          </div>
                        );
                      })}
                  </div>
                </div>

                <div className='cds_actions'>
                  <FormControl sx={{ m: 1, width: '15ch' }} variant='standard'>
                    <Button
                      variant='contained'
                      size='small'
                      color='error'
                      onClick={async () => {
                        await deleteUser({
                          _id: user._id,
                        });
                        navigate('/companies');
                      }}>
                      <p style={{ marginTop: 3, color: '#fff' }}>
                        Delete Company
                      </p>
                    </Button>
                  </FormControl>

                  <FormControl sx={{ m: 1, width: '15ch' }} variant='standard'>
                    <Button
                      variant='contained'
                      size='small'
                      color='info'
                      onClick={async () => {
                        await updateUser({
                          _id: user._id,
                          isSuspended: !user.isSuspended,
                        });
                        userRefectch();
                      }}>
                      <p style={{ marginTop: 3, color: '#fff' }}>
                        {user.isSuspended ? 'Unsuspend' : 'Suspend'} Company
                      </p>
                    </Button>
                  </FormControl>
                </div>
              </div>
            </>
          )}
        </div>

        <div className='transactions__section_wrapper'>
          <div className='transactions_section'>
            <div className='transactions_header'>
              <h2>Transactions</h2>
              <div
                className='transactions_header_action'
                onClick={() => {
                  navigate(`/companies/${slug}/transactions`);
                }}>
                <p>View All</p>
                <ChevronRightOutlinedIcon style={{ color: '#47C56C' }} />
              </div>
            </div>

            <div className='transactions_cards'>
              {transactions && transactions.length === 0 && (
                <p style={{ textAlign: 'center', marginTop: 10 }}>
                  No Transactions yet
                </p>
              )}
              {transactions &&
                transactions.map((p) => {
                  return (
                    <TransactionListItem
                      key={p._id}
                      _id={p._id}
                      transaction_type={p.transaction_type}
                      mode={p.mode}
                      amount={p.amount}
                      createdAt={p.createdAt}
                      status={p.transaction_status}
                    />
                  );
                })}
            </div>
          </div>
        </div>
      </div>

      <div className='referred_section_wrapper'>
        <h2>Contacts ({contacts?.length || 0})</h2>
        <div className='referrals_cards'>
          {contacts &&
            contacts.map((ru) => {
              return (
                <CompanyListItem
                  key={ru._id}
                  _id={ru._id}
                  company_name={ru.company_name}
                  referral_code={ru.referral_code}
                  photo={ru.photo}
                  isPhotoVisible={ru.isPhotoVisible}
                  counter={ru.total_earning}
                  isAmount={true}
                />
              );
            })}
        </div>
      </div>
      <div className='footer_section'></div>
    </>
  );
};
export default CompaniesDetailsPage;
