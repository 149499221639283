import './DashboardPage.scss';
import React, { useEffect } from 'react';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import ApartmentOutlinedIcon from '@mui/icons-material/ApartmentOutlined';
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import CurrencyPoundOutlinedIcon from '@mui/icons-material/CurrencyPoundOutlined';
import CompanyListItem from '../../components/ListItem/CompanyListItem';
import { gql } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { getAmountWithCurrency, sumRevenueByDate } from '../../utils/functions';
import moment from 'moment';
import Colors from '../../constants/Colors';
import { RangeTypeEnum } from '../../types/schema/utils.types';
import { useGetAllReferralsByPage } from '../../hooks/gql/query/getAll/useGetAllReferralsByPage';
import { useUpdateUser } from '../../hooks/gql/mutation/update/useUpdateUser';
import { useGetAllUserContentsNeedApproval } from '../../hooks/gql/query/getAll/useGetAllUserContentsNeedApproval';
import { useGetAllUsersCount } from '../../hooks/gql/query/getAll/useGetAllUsersCount';
import { useGetAllReferralsCount } from '../../hooks/gql/query/getAll/useGetAllReferralsCount';
import { useGetAllCustomersCount } from '../../hooks/gql/query/getAll/useGetAllCustomersCount';
import { useGetAllPaymentsByRangeByPage } from '../../hooks/gql/query/getAll/useGetAllPaymentsByRangeByPage';
import { useGetAllPaymentsRevenueByRange } from '../../hooks/gql/query/getAll/useGetAllPaymentsRevenueByRange';
import UserApprovalListItem from '../../components/ListItem/UserApprovalListItem';
import PaymentsListItem from '../../components/ListItem/PaymentsListItem';

const GET_ALL_REFS_BY_PAGE = gql`
  query GetAllReferralsByPage($input: PaginationInput!) {
    getAllReferralsByPage(input: $input) {
      _id
      createdAt
      referral_token
      status
      referred_by {
        _id
        contact_name
        company_name
        photo
        isPhotoVisible
        referral_code
      }
    }
  }
`;

const GET_ALL_USER_CONTENTS_NEED_APPROVAL = gql`
  query GetAllUserContentsNeedApproval($input: PaginationInput!) {
    getAllUserContentsNeedApproval(input: $input) {
      _id
      company_name
      referral_code
      contact_name

      photo
      photoStatus
      isPhotoVisible

      vehiclePictures
      vpStatus
      isVPVisible

      templateMessage
      templateStatus
      isTemplateVisible
    }
  }
`;

const GET_ALL_PAYMENTS_BY_RANGE_BY_PAGE_GRAPH = gql`
  query GetAllPaymentsByRangeByPage($input: PaginationWithRangeInput!) {
    getAllPaymentsByRangeByPage(input: $input) {
      net_revenue
      createdAt
    }
  }
`;

const GET_ALL_PAYMENTS_BY_RANGE_BY_PAGE = gql`
  query GetAllPaymentsByRangeByPage($input: PaginationWithRangeInput!) {
    getAllPaymentsByRangeByPage(input: $input) {
      _id
      admin_revenue
      amount_paid
      createdAt
      payment_status
      payment_type
      referrer_revenue
    }
  }
`;

const GET_ALL_PAYMENTS_REVENUE_BY_RANGE = gql`
  query GetAllPaymentsRevenueByRange($input: TextInput!) {
    getAllPaymentsRevenueByRange(input: $input) {
      _id
      admin_revenue
      amount_paid
      net_revenue
      referrer_revenue
      square_charge
      total_payments
    }
  }
`;

const DashboardPage: React.FC<{}> = (props) => {
  const navigate = useNavigate();
  const { updateUser } = useUpdateUser();
  const [range, setRange] = React.useState<RangeTypeEnum>('all');

  const {
    data: referrals,
    error: referralsError,
    loading: referralsLoading,
    refetch: referralsRefectch,
  } = useGetAllReferralsByPage(GET_ALL_REFS_BY_PAGE, {
    page: 1,
    item_per_page: 8,
  });

  const {
    data: users,
    error: usersError,
    loading: usersLoading,
    refetch: usersRefectch,
  } = useGetAllUserContentsNeedApproval(GET_ALL_USER_CONTENTS_NEED_APPROVAL, {
    page: 1,
    item_per_page: 2,
  });

  const {
    data: payments,
    error: paymentsError,
    loading: paymentsLoading,
    refetch: paymentsRefectch,
  } = useGetAllPaymentsByRangeByPage(GET_ALL_PAYMENTS_BY_RANGE_BY_PAGE, {
    page: 1,
    item_per_page: 3,
    range,
  });

  const {
    data: payments_graph,
    error: payments_graphError,
    loading: payments_graphLoading,
    refetch: payments_graphRefectch,
  } = useGetAllPaymentsByRangeByPage(GET_ALL_PAYMENTS_BY_RANGE_BY_PAGE_GRAPH, {
    page: 1,
    item_per_page: 10000,
    range,
  });

  const {
    data: revenue,
    error: revenueError,
    loading: revenueLoading,
    refetch: revenueRefetch,
  } = useGetAllPaymentsRevenueByRange(GET_ALL_PAYMENTS_REVENUE_BY_RANGE, {
    text: range,
  });

  const { data: users_count } = useGetAllUsersCount();
  const { data: referrals_count } = useGetAllReferralsCount();
  const { data: customers_count } = useGetAllCustomersCount();

  const graph_from_payments = payments_graph?.map((p) => {
    const { createdAt, net_revenue } = p;

    return { Revenue: net_revenue, Date: moment(createdAt).format('MMM DD') };
  });
  const graph_data = sumRevenueByDate(graph_from_payments);

  useEffect(() => {
    payments_graphRefectch();
    paymentsRefectch();
    revenueRefetch();
  }, [range]);

  return (
    <>
      <div className='graph_section'>
        <div className='revenue_graph'>
          <div className='graph_header'>
            <p>
              Total Revenue (
              <strong style={{ color: Colors.primaryColorLight }}>
                {getAmountWithCurrency(revenue?.net_revenue || 0)}
              </strong>
              )
            </p>
            <FormControl>
              <Select
                id='demo-simple-select'
                value={range}
                onChange={(event: SelectChangeEvent) => {
                  setRange(event.target.value as RangeTypeEnum);
                }}>
                <MenuItem value={'all'}>All</MenuItem>
                <MenuItem value={'week'}>This week</MenuItem>
                <MenuItem value={'month'}>This month</MenuItem>
                <MenuItem value={'year'}>This year</MenuItem>
              </Select>
            </FormControl>
          </div>

          <div className='graph'>
            <ResponsiveContainer width='100%' height='100%'>
              <AreaChart
                width={500}
                height={400}
                data={graph_data}
                margin={{
                  top: 10,
                  right: 30,
                  left: 0,
                  bottom: 0,
                }}>
                <CartesianGrid strokeDasharray='3 3' />
                <XAxis dataKey='Date' />
                <YAxis />
                <Tooltip />
                <Area
                  type='monotone'
                  dataKey='Revenue'
                  stroke='#47C56C'
                  fill='#DAF3E2'
                />
              </AreaChart>
            </ResponsiveContainer>
          </div>
        </div>
        <div className='summary'>
          <p>Summary</p>
          <div className='summary_row1'>
            <div className='summary_row1_item'>
              <div
                className='summary_icon'
                style={{ backgroundColor: '#DAF3E2' }}>
                <PeopleAltOutlinedIcon
                  fontSize='large'
                  style={{ color: '#47C56C' }}
                />
              </div>
              <div className='summary_content'>
                <p className='summary_content_number'>{customers_count}</p>
                <p className='summary_content_title'>Customers</p>
              </div>
            </div>
            <div className='summary_row1_item'>
              <div
                className='summary_icon'
                style={{ backgroundColor: '#FCDCDC' }}>
                <ApartmentOutlinedIcon
                  fontSize='large'
                  style={{ color: '#EC1313' }}
                />
              </div>
              <div className='summary_content'>
                <p className='summary_content_number'>{users_count}</p>
                <p className='summary_content_title'>Companies</p>
              </div>
            </div>
          </div>
          <div className='summary_row2'>
            <div className='summary_row2_item'>
              <div
                className='summary_icon'
                style={{ backgroundColor: '#F6F0D1' }}>
                <EmojiEventsOutlinedIcon
                  fontSize='large'
                  style={{ color: '#D4B618' }}
                />
              </div>
              <div className='summary_content'>
                <p className='summary_content_number'>{referrals_count}</p>
                <p className='summary_content_title'>Referrals</p>
              </div>
            </div>
            <div className='summary_row2_item'>
              <div
                className='summary_icon'
                style={{ backgroundColor: '#D1E4FF' }}>
                <CurrencyPoundOutlinedIcon
                  fontSize='large'
                  style={{ color: '#1C7AFF' }}
                />
              </div>
              <div className='summary_content'>
                <p className='summary_content_number'>
                  {getAmountWithCurrency(revenue?.admin_revenue || 0)}
                </p>
                <p className='summary_content_title'>Admin Revenue</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='stats_section'>
        <div className='templates_referrals'>
          <div className='templates'>
            {users && users.length > 0 && (
              <>
                <div className='templates_header'>
                  <h2>Offer Templates</h2>

                  <div
                    className='templates_header_action'
                    onClick={() => {
                      navigate('/users-approval');
                    }}>
                    <p>View All</p>
                    <ChevronRightOutlinedIcon style={{ color: '#47C56C' }} />
                  </div>
                </div>
                <div className='templates_cards'>
                  {users &&
                    users.map((u) => {
                      return (
                        <UserApprovalListItem
                          key={u._id}
                          _id={u._id}
                          company_name={u.company_name}
                          contact_name={u.contact_name}
                          photo={u.photo}
                          photoStatus={u.photoStatus}
                          isPhotoVisible={u.isPhotoVisible}
                          vehiclePictures={u.vehiclePictures}
                          vpStatus={u.vpStatus}
                          templateMessage={u.templateMessage}
                          templateStatus={u.templateStatus}
                          onActionClick={(_id) => {
                            navigate(`/users-approval/${_id}`);
                          }}
                        />
                      );
                    })}
                </div>
              </>
            )}
          </div>
          <div className='referrals'>
            <div className='referrals_header'>
              <h2>Recent Referrals</h2>
              <div
                className='referrals_header_action'
                onClick={() => {
                  navigate('/referrals');
                }}>
                <p>View All</p>
                <ChevronRightOutlinedIcon style={{ color: '#47C56C' }} />
              </div>
            </div>

            <div className='referrals_cards'>
              {referrals &&
                referrals.map((r) => {
                  return (
                    <CompanyListItem
                      key={r._id}
                      _id={r._id}
                      company_name={
                        r.referred_by?.company_name || 'Customer Created'
                      }
                      photo={r.referred_by?.photo}
                      isPhotoVisible={r.referred_by?.isPhotoVisible}
                      referral_code={r.referred_by?.referral_code || 'NO_REF'}
                    />
                  );
                })}
            </div>
          </div>
        </div>
        <div className='transactions'>
          <div className='transactions_header'>
            <h2>Payments</h2>

            <div
              className='transactions_header_action'
              onClick={() => {
                navigate('/payments');
              }}>
              <p>View All</p>
              <ChevronRightOutlinedIcon style={{ color: '#47C56C' }} />
            </div>
          </div>

          <div className='transactions_cards'>
            {payments &&
              payments.map((b) => {
                return (
                  <PaymentsListItem
                    key={b._id}
                    _id={b._id}
                    payment_type={b.payment_type}
                    amount={b.amount_paid}
                    createdAt={b.createdAt}
                    status={b.payment_status}
                  />
                );
              })}
          </div>
        </div>
      </div>

      <div className='footer_section'></div>
    </>
  );
};
export default DashboardPage;
