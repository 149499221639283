import React, { useState } from 'react';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import './CustomerReviewsPage.scss';
import { useNavigate } from 'react-router-dom';
import { gql } from '@apollo/client';
import LoadingSpinner from '../../components/UI/LoadingSpinner';
import CustomButton from '../../components/UI/CustomButton';
import ReviewListItem from '../../components/ListItem/ReviewListItem';
import { useUpdateReviewByAdmin } from '../../hooks/gql/mutation/update/useUpdateReviewByAdmin';
import { useDeleteReview } from '../../hooks/gql/mutation/delete/useDeleteReview';
import { useGetAllReviewsByPage } from '../../hooks/gql/query/getAll/useGetAllReviewsByPage';

const GET_REVIEWS_BY_PAGE = gql`
  query GetAllReviewsByPage($input: PaginationWithBooleanInput!) {
    getAllReviewsByPage(input: $input) {
      _id
      message
      rating
      isVisible
      createdAt
      review_by {
        _id
        name
      }
      review_to {
        _id
        contact_name
        referral_code
        photo
        isPhotoVisible
      }
    }
  }
`;

const CustomerReviewsPage: React.FC<{}> = (props) => {
  const [sortOption, setSortOption] = useState('1');
  const [page_number, setPage_number] = useState(1);

  const navigate = useNavigate();
  const { updateReviewByAdmin } = useUpdateReviewByAdmin();
  const { deleteReview } = useDeleteReview();

  const {
    data: reviews,
    error: reviewsError,
    loading: reviewsLoading,
    refetch: reviewsRefectch,
  } = useGetAllReviewsByPage(GET_REVIEWS_BY_PAGE, {
    page: page_number,
    item_per_page: 20,
    bool: sortOption === '1' ? false : true,
  });

  const handleChange = (event: SelectChangeEvent) => {
    setSortOption(event.target.value as string);
    reviewsRefectch();
  };

  return (
    <>
      <div className='sorting_wrapper'>
        <p>Sort by</p>
        <FormControl>
          <Select
            id='demo-simple-select'
            value={sortOption}
            onChange={handleChange}
            sx={{
              boxShadow: 'none',
              '.MuiOutlinedInput-notchedOutline': { border: 0 },
            }}>
            <MenuItem value={'1'}>Requires Approval</MenuItem>
            <MenuItem value={'2'}>Already Approved</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div className='reviews_cards'>
        {reviewsLoading && !reviews && (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <LoadingSpinner />
          </div>
        )}

        {reviews && reviews.length === 0 && (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              paddingTop: '100px',
            }}>
            <p>No Review Requires Action</p>
          </div>
        )}

        {reviews &&
          reviews.map((rv) => {
            return (
              <ReviewListItem
                _id={rv._id}
                key={rv._id}
                isVisible={rv.isVisible}
                createdAt={rv.createdAt}
                refetch_review={() => {
                  reviewsRefectch();
                }}
                onActionClick={async (_id, isVisible) => {
                  await updateReviewByAdmin({
                    _id,
                    isVisible: !isVisible,
                  });
                  reviewsRefectch();
                }}
                onDelete={async (_id) => {
                  await deleteReview({
                    _id,
                  });

                  reviewsRefectch();
                }}
                rating={rv.rating || 5}
                message={rv.message || ''}
                contact_name={rv.review_to.contact_name}
                referral_code={rv.review_to.referral_code}
                photo={rv.review_to.photo}
                isPhotoVisible={rv.review_to.isPhotoVisible}
                review_by={{
                  name: rv.review_by.name,
                }}
              />
            );
          })}
      </div>

      <div className='reviews_action_wrapper'>
        <CustomButton
          title='Load More'
          onClick={() => {
            setPage_number((pn) => pn + 1);
            reviewsRefectch();
          }}
          isLoading={reviewsLoading}
        />
      </div>

      <div className='footer_content'></div>
    </>
  );
};
export default CustomerReviewsPage;
