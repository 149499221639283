import * as React from 'react';
import { alpha, styled } from '@mui/material/styles';
import { DataGrid, gridClasses } from '@mui/x-data-grid';
import { GridColDef } from '@mui/x-data-grid';
import Colors from '../../constants/Colors';
import moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';
import { gql } from '@apollo/client';
import { formatString, getAmountWithCurrency } from '../../utils/functions';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useEffect } from 'react';
import ActionButtonsPayments from '../../components/Utils/ActionButtonsPayments';
import { RangeTypeEnum } from '../../types/schema/utils.types';
import { useGetAllTransactionsByUserIdByRangeByPage } from '../../hooks/gql/query/getAll/useGetAllTransactionsByUserIdByRangeByPage';

const GET_ALL_TRANSACTIONS_BY_USERID_BY_RANGE_BY_PAGE = gql`
  query GetAllTransactionsByUserIdByRangeByPage(
    $input: PaginationWithIdRangeInput!
  ) {
    getAllTransactionsByUserIdByRangeByPage(input: $input) {
      _id
      amount
      mode
      transaction_status
      transaction_type
      transaction_token
      createdAt
    }
  }
`;

const transactionTableState = {
  initialState: {
    columns: {
      columnVisibilityModel: {
        id: false,
        amount: true,
        transaction_type: true,
        transaction_token: true,
        createdAt: true,
        transaction_status: true,
        actions: true,
      },
    },
  },
};

const transactionTableColumn: GridColDef[] = [
  { field: 'id', headerClassName: 'transactions_table_header' },
];

const ODD_OPACITY = 0.2;

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.odd`]: {
    backgroundColor: '#F4F6F6',
    '&:hover, &.Mui-hovered': {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
    '&.Mui-selected': {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity
      ),
      '&:hover, &.Mui-hovered': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity
        ),
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  },
}));

const InAppWalletsDetailsTransactionsPage: React.FC<{}> = (props) => {
  const [range, setRange] = React.useState<RangeTypeEnum>('all');
  const navigate = useNavigate();
  const { slug } = useParams();

  const {
    data: transactions,
    error: transactionsError,
    loading: transactionsLoading,
    refetch: transactionsRefectch,
  } = useGetAllTransactionsByUserIdByRangeByPage(
    GET_ALL_TRANSACTIONS_BY_USERID_BY_RANGE_BY_PAGE,
    {
      _id: slug || '64f8267c7ed1f6aa0166bde1',
      page: 1,
      item_per_page: 1000,
      range,
    }
  );

  const transactions_row =
    transactions?.map((b) => {
      return { ...b, id: b._id, actions: 'V' };
    }) || [];

  useEffect(() => {
    transactionsRefectch();
  }, [range]);

  return (
    <>
      <div style={{ height: 631, width: '100%', marginBottom: 150 }}>
        <p style={{ textAlign: 'center' }}>
          This transactions page listing all the transactions that is related to
          operators wallet balance, either its goes in or out from the operator
          balance. Transactions always calculating operators accounting and
          Payments is calculating Platforms Accounting.
        </p>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
          }}>
          <FormControl>
            <Select
              id='demo-simple-select'
              value={range}
              onChange={(event: SelectChangeEvent) => {
                setRange(event.target.value as RangeTypeEnum);
              }}>
              <MenuItem value={'all'}>All</MenuItem>
              <MenuItem value={'week'}>This week</MenuItem>
              <MenuItem value={'month'}>This month</MenuItem>
              <MenuItem value={'year'}>This year</MenuItem>
            </Select>
          </FormControl>
        </div>
        <StripedDataGrid
          pageSizeOptions={[10, 50, 100]}
          columns={[
            ...transactionTableColumn,
            {
              field: 'transaction_type',
              headerName: 'Indentifier',
              flex: 1,
              minWidth: 120,
              headerAlign: 'center',
              align: 'center',
              headerClassName: 'transactions_table_header',
              renderCell: (params) => {
                const data = params.row;
                return (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <p>{formatString(data?.transaction_type)}</p>
                  </div>
                );
              },
            },
            {
              field: 'amount',
              headerName: 'Amount Paid',
              flex: 1,
              minWidth: 120,
              headerAlign: 'center',
              align: 'center',
              headerClassName: 'transactions_table_header',
              renderCell: (params) => {
                const data = params.row;
                return (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <p
                      style={{
                        color:
                          data?.mode < 0
                            ? Colors.accentColorDark
                            : Colors.primaryColorLight,
                      }}>
                      {data?.mode < 0 ? '-' : ''}
                      {getAmountWithCurrency(data?.amount)}
                    </p>
                  </div>
                );
              },
            },
            {
              field: 'transaction_token',
              headerName: 'Token',
              flex: 1,
              minWidth: 120,
              headerAlign: 'center',
              align: 'center',
              headerClassName: 'transactions_table_header',
              renderCell: (params) => {
                const data = params.row;
                return (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <p>#{data?.transaction_token}</p>
                  </div>
                );
              },
            },
            {
              field: 'createdAt',
              headerName: 'Date and Time',
              flex: 1,
              minWidth: 180,
              headerAlign: 'center',
              align: 'center',
              headerClassName: 'transactions_table_header',
              renderCell: (params) => {
                const data = params.row;
                return (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <p>
                      {moment(data?.createdAt).format('MMM DD, YYYY hh:mm A')}
                    </p>
                  </div>
                );
              },
            },
            {
              field: 'transaction_status',
              headerName: 'Status',
              flex: 1,
              minWidth: 100,
              headerAlign: 'center',
              align: 'center',
              headerClassName: 'transactions_table_header',
              renderCell: (params) => {
                const data = params.row;
                return (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <p
                      style={{
                        backgroundColor:
                          data?.transaction_status === 'pending'
                            ? Colors.accentColorDark
                            : Colors.pclXLight,
                        color:
                          data?.transaction_status === 'pending'
                            ? Colors.light
                            : Colors.primaryColorLight,
                        paddingTop: 3,
                        paddingBottom: 3,
                        paddingLeft: 10,
                        paddingRight: 10,
                        borderRadius: 100,
                      }}>
                      {formatString(data?.transaction_status)}
                    </p>
                  </div>
                );
              },
            },

            {
              field: 'actions',
              headerName: 'Action',
              maxWidth: 100,
              headerAlign: 'center',
              align: 'center',
              sortable: false,
              filterable: false,
              headerClassName: 'transactions_table_header',
              renderCell: (params) => {
                const data = params.row;
                return (
                  <div>
                    <ActionButtonsPayments
                      id={data.id}
                      onEyeClick={(id) => {
                        alert('More details not available right now.');
                      }}
                    />
                  </div>
                );
              },
            },
          ]}
          rows={transactions_row}
          initialState={{
            ...transactionTableState.initialState,
            pagination: { paginationModel: { pageSize: 10 } },
          }}
          pagination
          sx={{
            '& .transactions_table_header': {
              backgroundColor: '#F3F8FF',
            },
          }}
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
          }
        />
      </div>
    </>
  );
};
export default InAppWalletsDetailsTransactionsPage;
