import { useSelector } from 'react-redux';
import {
  useQuery,
  DocumentNode,
  ApolloError,
  ApolloQueryResult,
  OperationVariables,
} from '@apollo/client';
import { RootState } from '../../../../store/store';
import { MongoIdInput } from '../../../../types/schema/utils.types';
import { CoMessage } from '../../../../types/schema/comessage.types';

interface GetCoMessageByCompanyIdHook {
  data: CoMessage | undefined;
  loading: boolean;
  error: ApolloError | undefined;
  refetch: (variables?: Partial<OperationVariables> | undefined) => Promise<
    ApolloQueryResult<{
      getCoMessageByCompanyId: CoMessage;
    }>
  >;
}

interface GetCoMessageByCompanyIdMutationData {
  getCoMessageByCompanyId: CoMessage;
}

interface GetCoMessageByCompanyIdInputType {
  input: MongoIdInput;
}

export function useGetCoMessageByCompanyId(
  query: DocumentNode,
  input: MongoIdInput
): GetCoMessageByCompanyIdHook {
  const { jwt } = useSelector((state: RootState) => state.auth);

  const { data, loading, error, refetch } = useQuery<
    GetCoMessageByCompanyIdMutationData,
    GetCoMessageByCompanyIdInputType
  >(query, {
    variables: {
      input,
    },
    context: {
      headers: {
        cookie: `jwt=${jwt}`,
        authorization: jwt ? `Bearer ${jwt}` : '',
      },
    },
  });

  return { data: data?.getCoMessageByCompanyId, loading, error, refetch };
}
