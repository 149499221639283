import { RootState } from '../../../../store/store';
import { useSelector } from 'react-redux';
import { gql, useMutation } from '@apollo/client';
import { UpdateUserInput, User } from '../../../../types/schema/user.types';

const UPDATE_USER = gql`
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      _id
      contact_name
      email
      phone
    }
  }
`;

// Define the types for the mutation response and input
interface UpdateUserMutationData {
  updateUser: User;
}

interface UpdateUserInputType {
  input: UpdateUserInput;
}

export const useUpdateUser = () => {
  const { jwt } = useSelector((state: RootState) => state.auth);

  const [updateUserGql, { data, error, loading }] = useMutation<
    UpdateUserMutationData,
    UpdateUserInputType
  >(UPDATE_USER);

  const updateUser = async (input: UpdateUserInput) => {
    const { data, errors } = await updateUserGql({
      variables: {
        input: input,
      },
      context: {
        headers: {
          cookie: `jwt=${jwt}`,
          authorization: jwt ? `Bearer ${jwt}` : '',
        },
      },
    });

    return {
      updateUser: data?.updateUser,
      error: errors ? errors[0].message : null,
    };
  };

  return { updateUser, data, error, loading };
};
