import { formatString, getAmountWithCurrency } from '../../utils/functions';
import './PaymentsListItem.scss';
import moment from 'moment';

const PaymentsListItem: React.FC<{
  _id: string;
  payment_type: string;
  status: string;
  createdAt: Date;
  amount: number;
}> = ({ _id, payment_type, status, createdAt, amount }) => {
  return (
    <div className='payments_cards_item'>
      <div className='name_status'>
        <div className='company_name'>
          <p>Payment Type</p>
          <p className='det_answer'>{formatString(payment_type)}</p>
        </div>

        <div className='tr_status'>
          <p>Status</p>
          <p className='det_answer det_answer_status'>{formatString(status)}</p>
        </div>
      </div>
      <div className='date_amount'>
        <div className='tr_date'>
          <p>Date</p>
          <p className='det_answer'>
            {moment(createdAt).format('MMM DD, YYYY')}
          </p>
        </div>
        <div className='tr_amount'>
          <p>Amount</p>
          <p className='det_answer'>{getAmountWithCurrency(amount)}</p>
        </div>
      </div>
    </div>
  );
};

export default PaymentsListItem;
