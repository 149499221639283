/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';

const ActionsButtonsCustomer: React.FC<{
  id: string;
  onDelete: (domain: string) => void;
}> = ({ id, onDelete }) => {
  return (
    <Stack direction='row' spacing={1}>
      <IconButton
        color='primary'
        aria-label='group'
        onClick={() => onDelete(id)}>
        <DeleteOutlinedIcon style={{ color: 'red' }} />
      </IconButton>
    </Stack>
  );
};

export default ActionsButtonsCustomer;
