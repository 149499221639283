import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface AuthState {
  isAuthenticated: boolean;
  jwt: string;
  user: {
    _id: string;
    contact_name: string;
    email: string;
    userType: string;
  };
}

const initialState: AuthState = {
  jwt: '',
  isAuthenticated: false,
  user: {
    _id: '',
    contact_name: '',
    email: '',
    userType: '',
  },
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    authenticate: (state, data: PayloadAction<AuthState>) => {
      state.isAuthenticated = data.payload.isAuthenticated;
      state.jwt = data.payload.jwt;
      state.user = data.payload.user;
    },

    resetAuth: (state) => {
      state.isAuthenticated = false;
      state.jwt = initialState.jwt;
      state.user = initialState.user;
    },
  },
});

// Action creators are generated for each case reducer function
export const { authenticate, resetAuth } = authSlice.actions;

export default authSlice.reducer;
