import { RootState } from '../../../../store/store';
import { useSelector } from 'react-redux';
import { gql, useMutation } from '@apollo/client';
import {
  Setting,
  UpdateSettingInput,
} from '../../../../types/schema/setting.types';

const GQL_MUT = gql`
  mutation UpdateSetting($input: UpdateSettingInput!) {
    updateSetting(input: $input) {
      _id
    }
  }
`;

// Define the types for the mutation response and input
interface UpdateSettingMutationData {
  updateSetting: Setting;
}

interface UpdateSettingInputType {
  input: UpdateSettingInput;
}

export const useUpdateSetting = () => {
  const { jwt } = useSelector((state: RootState) => state.auth);

  const [updateSettingGql, { data, error, loading }] = useMutation<
    UpdateSettingMutationData,
    UpdateSettingInputType
  >(GQL_MUT);

  const updateSetting = async (input: UpdateSettingInput) => {
    const { data, errors } = await updateSettingGql({
      variables: {
        input: input,
      },
      context: {
        headers: {
          cookie: `jwt=${jwt}`,
          authorization: jwt ? `Bearer ${jwt}` : '',
        },
      },
    });

    return {
      updateSetting: data?.updateSetting,
      error: errors ? errors[0].message : null,
    };
  };

  return { updateSetting, data, error, loading };
};
