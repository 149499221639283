import { RootState } from '../../../../store/store';
import { useSelector } from 'react-redux';
import { gql, useMutation } from '@apollo/client';
import {
  AddCuMessageInput,
  CuMessage,
} from '../../../../types/schema/cumessage.types';

const GQL_MUT = gql`
  mutation AddCuMessage($input: AddCuMessageInput!) {
    addCuMessage(input: $input) {
      _id
    }
  }
`;

// Define the types for the mutation response and input
interface AddCuMessageMutationData {
  addCuMessage: CuMessage;
}

interface AddCuMessageInputType {
  input: AddCuMessageInput;
}

export const useAddCuMessage = () => {
  const { jwt } = useSelector((state: RootState) => state.auth);

  const [addCuMessageGql, { data, error, loading }] = useMutation<
    AddCuMessageMutationData,
    AddCuMessageInputType
  >(GQL_MUT);

  const addCuMessage = async (input: AddCuMessageInput) => {
    const { data, errors } = await addCuMessageGql({
      variables: {
        input: input,
      },
      context: {
        headers: {
          cookie: `jwt=${jwt}`,
          authorization: jwt ? `Bearer ${jwt}` : '',
        },
      },
    });

    return {
      addCuMessage: data?.addCuMessage,
      error: errors ? errors[0].message : null,
    };
  };

  return { addCuMessage, data, error, loading };
};
