import { useSelector } from 'react-redux';
import {
  useQuery,
  ApolloError,
  ApolloQueryResult,
  OperationVariables,
  gql,
} from '@apollo/client';
import { RootState } from '../../../../store/store';

const GQL_QUE = gql`
  query GetAllCustomersCount {
    getAllCustomersCount
  }
`;

interface GetAllCustomersCountHook {
  data: number | undefined;
  loading: boolean;
  error: ApolloError | undefined;
  refetch: (variables?: Partial<OperationVariables> | undefined) => Promise<
    ApolloQueryResult<{
      getAllCustomersCount: number;
    }>
  >;
}

interface GetAllCustomersCountMutationData {
  getAllCustomersCount: number;
}

export function useGetAllCustomersCount(): GetAllCustomersCountHook {
  const { jwt } = useSelector((state: RootState) => state.auth);

  const { data, loading, error, refetch } =
    useQuery<GetAllCustomersCountMutationData>(GQL_QUE, {
      context: {
        headers: {
          cookie: `jwt=${jwt}`,
          authorization: jwt ? `Bearer ${jwt}` : '',
        },
      },
    });

  return {
    data: data?.getAllCustomersCount,
    loading,
    error,
    refetch,
  };
}
