type URLCONFIG = {
  graphQLURL: string;
  loginUrl: string;
  usersUrl: string;
  settingsID: string;

  squareLocationId?: string;
  squareAppId?: string;
  mapsGeocodingAPIKey?: string;
};

export const isLive = process.env.REACT_APP_IS_LIVE === 'true';
const isLiveTestDomain = process.env.REACT_APP_LIVE_TEST_DOMAIN === 'true';
const isMobileTesting = process.env.REACT_APP_IS_MOBILE_TESTING === 'true';

const isMobileIP = false;
const isIPhone = false;
const liveDomain = isLiveTestDomain ? 'moniruzzaman.biz' : 'fully-booked.uk';

const localIPMobile1 = 'http://192.168.43.2';
const localIPMobile2 = 'http://172.20.10.2';
const localIPWiFi = 'http://192.168.0.105';

const localIPMobile = isIPhone ? localIPMobile2 : localIPMobile1;
const localIP = isMobileIP ? localIPMobile : localIPWiFi;

const localApiAddress = isMobileTesting
  ? `${localIP}:5000`
  : 'https://api.fully-booked.localhost';
const liveApiAddress = `https://api.${liveDomain}`;

const localAdminAddress = isMobileTesting
  ? `${localIP}:3001`
  : 'https://admin.fully-booked.localhost';
const liveAdminAddress = `https://admin.${liveDomain}`;

export const gBaseUrl = isLive ? liveApiAddress : localApiAddress;
export const adminWebBaseUrl = isLive ? liveAdminAddress : localAdminAddress;

export const URLConfig: URLCONFIG = {
  graphQLURL: `${gBaseUrl}/graphql`,
  loginUrl: `${adminWebBaseUrl}/login`,
  usersUrl: `${adminWebBaseUrl}/users`,

  settingsID: '651c4e2026e6585f3cce82d9',
  mapsGeocodingAPIKey: process.env.REACT_APP_MAPS_GEOCODING_API_KEY,
  squareLocationId: process.env.REACT_APP_SQUARE_LOCATION_ID,
  squareAppId: process.env.REACT_APP_SQUARE_APP_ID,
};
