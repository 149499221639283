import { useSelector } from 'react-redux';
import {
  useQuery,
  DocumentNode,
  ApolloError,
  ApolloQueryResult,
  OperationVariables,
} from '@apollo/client';
import { RootState } from '../../../../store/store';
import { MongoIdInput } from '../../../../types/schema/utils.types';
import { User } from '../../../../types/schema/user.types';

interface GetUserDetailsByIdHook {
  data: User | undefined;
  loading: boolean;
  error: ApolloError | undefined;
  refetch: (variables?: Partial<OperationVariables> | undefined) => Promise<
    ApolloQueryResult<{
      getUserDetailsById: User;
    }>
  >;
}

interface GetUserDetailsByIdMutationData {
  getUserDetailsById: User;
}

interface GetUserDetailsByIdInputType {
  input: MongoIdInput;
}

export function useGetUserDetailsById(
  query: DocumentNode,
  input: MongoIdInput
): GetUserDetailsByIdHook {
  const { jwt } = useSelector((state: RootState) => state.auth);

  const { data, loading, error, refetch } = useQuery<
    GetUserDetailsByIdMutationData,
    GetUserDetailsByIdInputType
  >(query, {
    variables: {
      input,
    },
    context: {
      headers: {
        cookie: `jwt=${jwt}`,
        authorization: jwt ? `Bearer ${jwt}` : '',
      },
    },
  });

  return {
    data: data?.getUserDetailsById,
    loading,
    error,
    refetch,
  };
}
