import { RootState } from '../../../../store/store';
import { useSelector } from 'react-redux';
import { gql, useMutation } from '@apollo/client';
import { MongoIdInput } from '../../../../types/schema/utils.types';
import { Referral } from '../../../../types/schema/referral.types';

const GQL_MUT = gql`
  mutation DeleteReferral($input: MongoIdInput!) {
    deleteReferral(input: $input) {
      _id
    }
  }
`;

// Define the types for the mutation response and input
interface DeleteReferralMutationData {
  deleteReferral: Referral;
}

interface DeleteReferralInputType {
  input: MongoIdInput;
}

export const useDeleteReferral = () => {
  const { jwt } = useSelector((state: RootState) => state.auth);

  const [deleteReferralGql, { data, error, loading }] = useMutation<
    DeleteReferralMutationData,
    DeleteReferralInputType
  >(GQL_MUT);

  const deleteReferral = async (input: MongoIdInput) => {
    const { data, errors } = await deleteReferralGql({
      variables: {
        input: input,
      },
      context: {
        headers: {
          cookie: `jwt=${jwt}`,
          authorization: jwt ? `Bearer ${jwt}` : '',
        },
      },
    });

    return {
      deleteReferral: data?.deleteReferral,
      error: errors ? errors[0].message : null,
    };
  };

  return { deleteReferral, data, error, loading };
};
