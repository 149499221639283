import { useSelector } from 'react-redux';
import {
  useQuery,
  DocumentNode,
  ApolloError,
  ApolloQueryResult,
  OperationVariables,
} from '@apollo/client';
import { RootState } from '../../../../store/store';
import { PaginationWIDSearchInput } from '../../../../types/schema/utils.types';
import { Proposal } from '../../../../types/schema/proposal.types';

interface GetAllProposalsByCoverIdBySearchHook {
  data: Proposal[] | undefined;
  loading: boolean;
  error: ApolloError | undefined;
  refetch: (variables?: Partial<OperationVariables> | undefined) => Promise<
    ApolloQueryResult<{
      getAllProposalsByCoverIdBySearch: Proposal[];
    }>
  >;
}

interface GetAllProposalsByCoverIdBySearchMutationData {
  getAllProposalsByCoverIdBySearch: Proposal[];
}

interface GetAllProposalsByCoverIdBySearchInputType {
  input: PaginationWIDSearchInput;
}

export function useGetAllProposalsByCoverIdBySearch(
  query: DocumentNode,
  input: PaginationWIDSearchInput
): GetAllProposalsByCoverIdBySearchHook {
  const { jwt } = useSelector((state: RootState) => state.auth);

  const { data, loading, error, refetch } = useQuery<
    GetAllProposalsByCoverIdBySearchMutationData,
    GetAllProposalsByCoverIdBySearchInputType
  >(query, {
    variables: {
      input,
    },
    context: {
      headers: {
        cookie: `jwt=${jwt}`,
        authorization: jwt ? `Bearer ${jwt}` : '',
      },
    },
  });

  return {
    data: data?.getAllProposalsByCoverIdBySearch,
    loading,
    error,
    refetch,
  };
}
