import './RDCSection.scss';
import { Avatar } from '@mui/material';
import Colors from '../../constants/Colors';
import { useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import { gql } from '@apollo/client';
import moment from 'moment';
import { getNameAvatar } from '../../utils/functions';
import { gBaseUrl } from '../../utils/config';
import { useGetProposalDetailsById } from '../../hooks/gql/query/getById/useGetProposalDetailsById';

const GET_PROPOSAL_BY_ID = gql`
  query GetProposalDetailsById($input: MongoIdInput!) {
    getProposalDetailsById(input: $input) {
      _id
      proposal_amount
      selected_vehicle
      agreed_amount
      proposed_by {
        _id
        contact_name
        company_name
        business_address {
          location_name
        }
        paymentMethods
        photo
        isPhotoVisible
        phone
      }
      proposed_referral_ref {
        _id
        referred_to {
          _id
          name
        }
        booked_company_ref {
          _id
        }
      }
      conversations {
        _id
        sender
        content
        sentAt
      }
    }
  }
`;

const RDCSection: React.FC<{
  selectedProposal: string;
  selectedCompany: string;
}> = ({ selectedProposal, selectedCompany }) => {
  const cuRef = useRef<HTMLDivElement>(null);

  const {
    data: proposal,
    error: proposalError,
    loading: proposalLoading,
    refetch: proposalRefectch,
  } = useGetProposalDetailsById(GET_PROPOSAL_BY_ID, {
    _id: selectedProposal,
  });

  const proposed_by = proposal?.proposed_by;
  const proposed_referral = proposal?.proposed_referral_ref;
  const currentConversations = proposal?.conversations || [];

  useEffect(() => {
    const interval = setInterval(() => {
      proposalRefectch();
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    cuRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
    });
  }, [currentConversations.length]);

  return (
    <div className='rdcmessage-section'>
      {currentConversations.length > 0 && selectedProposal && (
        <>
          <div className='rdcmessage-section--h'>
            <p>{`You are secretly watching conversation betweeen ${proposed_by?.contact_name} and ${proposed_referral?.referred_to?.name} 🙄`}</p>
          </div>
          <div className='rdcmessage-section--c'>
            {/* ITIRATE HERE */}
            {/* DATE */}

            {currentConversations.length > 0 && (
              <>
                {currentConversations.map((cv, i: number) => {
                  if (cv.sender === 'customer') {
                    return (
                      <motion.div
                        key={i}
                        initial={{ y: 10, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        transition={{ duration: 0.5 }}
                        className='rdcmessage-section--c_right'>
                        <div className='conv_message'>
                          <pre className='message_content'>{cv.content}</pre>
                        </div>
                        {cv.sentAt && (
                          <div className='conv_time'>
                            <p>{moment(cv.sentAt).format('HH:mm')}</p>
                          </div>
                        )}
                      </motion.div>
                    );
                  } else {
                    return (
                      <motion.div
                        key={i}
                        initial={{ y: 10, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        transition={{ duration: 0.5 }}
                        className='rdcmessage-section--c_left'>
                        <div className='conv_message'>
                          {proposed_by?.isPhotoVisible ? (
                            <Avatar
                              src={`${gBaseUrl}/photos/avatars/${proposed_by?.photo}`}
                              sx={{
                                width: 30,
                                height: 30,
                                bgcolor: Colors.pclXLight,
                                marginRight: 1,
                                color: Colors.primaryColorLight,
                                boxShadow:
                                  '0px 10px 15px -3px rgba(0, 0, 0, 0.1)',
                              }}
                            />
                          ) : (
                            <Avatar
                              sx={{
                                width: 30,
                                height: 30,
                                bgcolor: Colors.pclXLight,
                                marginRight: 1,
                                color: Colors.primaryColorLight,
                                boxShadow:
                                  '0px 10px 15px -3px rgba(0, 0, 0, 0.1)',
                              }}>
                              {getNameAvatar(
                                proposed_by?.company_name || 'User'
                              )}
                            </Avatar>
                          )}

                          <pre className='message_content'>{cv.content}</pre>
                        </div>
                        {cv.sentAt && (
                          <div className='conv_time'>
                            <p>{moment(cv.sentAt).format('HH:mm')}</p>
                          </div>
                        )}
                      </motion.div>
                    );
                  }
                })}
              </>
            )}

            <div style={{ marginBottom: 100 }} />
            <div ref={cuRef} />
          </div>
          <div className='rdcmessage-section--i'>
            <div className='rdcmessage-section--i_inp'></div>
            <div className='rdcmessage-section--i_sub'></div>
          </div>
        </>
      )}
    </div>
  );
};
export default RDCSection;
