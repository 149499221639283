import { getNameAvatar } from '../../utils/functions';
import './UserApprovalListItem.scss';
import { Avatar, FormControl } from '@mui/material';
import Colors from '../../constants/Colors';
import Button from '@mui/material/Button';
import { gBaseUrl } from '../../utils/config';
import {
  PhotoEnum,
  VPEnum,
  TemplateStatusEnum,
} from '../../types/schema/user.types';

const UserApprovalListItem: React.FC<{
  _id: string;
  company_name: string;
  contact_name: string;

  photo: string;
  photoStatus: PhotoEnum;
  isPhotoVisible: boolean;

  vehiclePictures?: string[];
  vpStatus: VPEnum;

  templateMessage?: string;
  templateStatus: TemplateStatusEnum;

  onActionClick: (id: string) => void;
}> = ({
  _id,
  company_name,
  contact_name,

  photo,
  photoStatus,
  isPhotoVisible,

  vehiclePictures,
  vpStatus,

  templateMessage,
  templateStatus,

  onActionClick,
}) => {
  return (
    <div className='user_approval_cards_item'>
      <div className='uac_content'>
        <div className='title_logo'>
          <div className='logo_comp'>
            {isPhotoVisible ? (
              <Avatar
                src={`${gBaseUrl}/photos/avatars/${photo}`}
                sx={{
                  bgcolor: Colors.pclXLight,
                  marginRight: 1,
                  color: Colors.primaryColorLight,
                  boxShadow: '0px 10px 15px -3px rgba(0, 0, 0, 0.1)',
                }}
              />
            ) : (
              <Avatar
                sx={{
                  bgcolor: Colors.pclXLight,
                  marginRight: 1,
                  color: Colors.primaryColorLight,
                  boxShadow: '0px 10px 15px -3px rgba(0, 0, 0, 0.1)',
                }}>
                {getNameAvatar(contact_name)}
              </Avatar>
            )}
            <div className='logo_comp_det'>
              <p className='business_name'>{company_name}</p>
              <p className='contact_name'>{contact_name}</p>
            </div>
          </div>

          {photoStatus === 'new' && (
            <div className='profile_img'>
              <div className='image_item'>
                <img src={`${gBaseUrl}/photos/avatars/${photo}`} />
              </div>
            </div>
          )}
        </div>

        {vpStatus === 'new' &&
          vehiclePictures &&
          vehiclePictures.length > 0 && (
            <div className='picture_content'>
              <h4>Vehicle Pictures</h4>
              <div className='content_wrapper'>
                {/* IMAGES */}
                {vehiclePictures.slice(-3).map((vp) => {
                  return (
                    <div className='image_item' key={vp}>
                      <img src={`${gBaseUrl}/photos/vehiclePictures/${vp}`} />
                    </div>
                  );
                })}
              </div>
            </div>
          )}

        {templateStatus !== 'approved' && (
          <div className='template_content'>
            <h4>Offer Template</h4>
            <div className='content_wrapper'>
              <p className='content_text'>{templateMessage}</p>
            </div>
          </div>
        )}
      </div>

      <div className='action_buttons'>
        <FormControl sx={{ width: '100%' }} variant='standard'>
          <Button
            variant='contained'
            color={templateStatus === 'rejected' ? 'error' : 'primary'}
            size='small'
            onClick={() => {
              onActionClick(_id);
            }}>
            View Requests
          </Button>
        </FormControl>
      </div>
    </div>
  );
};

export default UserApprovalListItem;
