import * as React from 'react';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import HttpsOutlinedIcon from '@mui/icons-material/HttpsOutlined';
import Button from '@mui/material/Button';
import './LoginPage.scss';
import { useDispatch } from 'react-redux';
import { authenticate } from '../../store/reducer/auth';
import { useNavigate } from 'react-router-dom';
import { gql, useMutation } from '@apollo/client';
import { useState } from 'react';

const LOGIN_MUTATION = gql`
  mutation LoginUser($input: LoginUserInput!) {
    loginUser(input: $input) {
      jwt
      user {
        _id
        phone
        email
        contact_name
        userType
        isSetupFinished
      }
    }
  }
`;

const LoginPage: React.FC<{}> = (props) => {
  const [showPassword, setShowPassword] = React.useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loginEmail, setLoginEmail] = useState('');
  const [loginPassword, setLoginPassword] = useState('');

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [login, loginData] = useMutation(LOGIN_MUTATION);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <div className='main_wrapper'>
      <div className='left_image_section'>
        <img
          src='/images/computer_desk.png'
          alt='Computer Desk'
          className='computer_desk_img'
        />
      </div>
      <div className='right_input_section'>
        <div className='logo_wrapper'>
          <img
            src='/images/main_logo.png'
            alt='Logo'
            className='main_logo_img'
          />
          <p className='welcom_text'>Welcome Back!</p>
        </div>
        <div className='input_fields'>
          <FormControl sx={{ m: 1 }} variant='standard' className='input_fc'>
            <OutlinedInput
              id='outlined-adornment-email'
              placeholder='Email'
              value={loginEmail}
              onChange={(e) => {
                setLoginEmail(e.target.value);
              }}
              className='input_fc_item'
              style={{
                backgroundColor: '#F3F8FF',
                color: '#324A59',
              }}
              startAdornment={
                <InputAdornment position='start'>
                  <IconButton aria-label='email icon' edge='start'>
                    <EmailOutlinedIcon style={{ color: '#324A59' }} />
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>

          <FormControl sx={{ m: 1 }} variant='standard' className='input_fc'>
            <OutlinedInput
              id='outlined-adornment-password'
              type={showPassword ? 'text' : 'password'}
              style={{
                backgroundColor: '#F3F8FF',
                color: '#324A59',
              }}
              value={loginPassword}
              onChange={(e) => {
                setLoginPassword(e.target.value);
              }}
              className='input_fc_item'
              placeholder='Password'
              endAdornment={
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge='end'>
                    {showPassword ? (
                      <VisibilityOff style={{ color: '#324A59' }} />
                    ) : (
                      <Visibility style={{ color: '#324A59' }} />
                    )}
                  </IconButton>
                </InputAdornment>
              }
              startAdornment={
                <InputAdornment position='start'>
                  <IconButton aria-label='email icon' edge='start'>
                    <HttpsOutlinedIcon style={{ color: '#324A59' }} />
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>

          <FormControl sx={{ m: 1 }} variant='standard' className='input_fc'>
            <Button
              variant='contained'
              size='large'
              className='input_fc_item'
              onClick={async () => {
                setIsSubmitting(true);

                try {
                  const user = await login({
                    variables: {
                      input: {
                        email: loginEmail.toLowerCase(),
                        password: loginPassword,
                      },
                    },
                  });

                  const userData = user.data.loginUser.user;
                  const isAdmin = userData.userType === 'admin';
                  const jwt = user.data.loginUser.jwt;

                  if (!isAdmin) {
                    alert('Please login with admin credentials.');
                    setIsSubmitting(false);
                    return;
                  }

                  if (isAdmin) {
                    dispatch(
                      authenticate({
                        isAuthenticated: true,
                        jwt,
                        user: {
                          _id: userData._id,
                          contact_name: userData.contact_name,
                          email: userData.email,
                          userType: userData.userType,
                        },
                      })
                    );

                    navigate('/dashboard');
                  }
                  setIsSubmitting(false);
                } catch (err) {
                  alert(
                    loginData.error?.message || 'Check your email or password'
                  );
                  setIsSubmitting(false);
                  console.log(err);
                }
                setIsSubmitting(false);
              }}>
              {isSubmitting ? 'Please wait...' : 'Login'}
            </Button>
          </FormControl>
        </div>
      </div>
    </div>
  );
};
export default LoginPage;
