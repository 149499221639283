import { RootState } from '../../../../store/store';
import { useSelector } from 'react-redux';
import { gql, useMutation } from '@apollo/client';
import { User } from '../../../../types/schema/user.types';
import { MongoIdInput } from '../../../../types/schema/utils.types';

const GQL_MUT = gql`
  mutation DeleteUserAvatar($input: MongoIdInput!) {
    deleteUserAvatar(input: $input) {
      _id
    }
  }
`;

// Define the types for the mutation response and input
interface DeleteUserAvatarMutationData {
  deleteUserAvatar: User;
}

interface DeleteUserAvatarInputType {
  input: MongoIdInput;
}

export const useDeleteUserAvatar = () => {
  const { jwt } = useSelector((state: RootState) => state.auth);

  const [deleteUserAvatarGql, { data, error, loading }] = useMutation<
    DeleteUserAvatarMutationData,
    DeleteUserAvatarInputType
  >(GQL_MUT);

  const deleteUserAvatar = async (input: MongoIdInput) => {
    const { data, errors } = await deleteUserAvatarGql({
      variables: {
        input: input,
      },
      context: {
        headers: {
          cookie: `jwt=${jwt}`,
          authorization: jwt ? `Bearer ${jwt}` : '',
        },
      },
    });

    return {
      deleteUserAvatar: data?.deleteUserAvatar,
      error: errors ? errors[0].message : null,
    };
  };

  return { deleteUserAvatar, data, error, loading };
};
