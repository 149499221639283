import './CompanyMessagesPage.scss';
import { FormControl, InputAdornment, OutlinedInput } from '@mui/material';
import { motion } from 'framer-motion';
import Colors from '../../constants/Colors';
import SupportCCListItem from '../../components/ListItem/SupportCCListItem';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { useEffect, useRef, useState } from 'react';
import { gql } from '@apollo/client';
import BroadcastMessageSection from './BroadcastMessageSection';
import CompanyMessageSection from './CompanyMessagesSection';
import { useLocation } from 'react-router-dom';
import { useGetAllUsersBySearchByPage } from '../../hooks/gql/query/getAll/useGetAllUsersBySearchByPage';

const GET_ALL_USERS_BY_SEARCH = gql`
  query GetAllUsersBySearchByPage($input: PaginationWSearchInput!) {
    getAllUsersBySearchByPage(input: $input) {
      _id
      company_name
      contact_name
      email
      photo
      isPhotoVisible
      referral_code
      phone
    }
  }
`;

const CompanyMessagesPage: React.FC<{}> = (props) => {
  const [isBroadcast, setIsBroadcast] = useState(false);
  const brRef = useRef<HTMLDivElement>(null);
  const coRef = useRef<HTMLDivElement>(null);
  const { search } = useLocation();
  const [searchQ, setSearchQ] = useState('');
  const [selectedCompany, setSelectedCompany] = useState<string | undefined>();

  const {
    data: companies,
    error: companiesError,
    loading: companiesLoading,
    refetch: companiesRefectch,
  } = useGetAllUsersBySearchByPage(GET_ALL_USERS_BY_SEARCH, {
    query: searchQ,
    page: 1,
    item_per_page: 6,
  });

  useEffect(() => {
    if (companies && companies.length > 0) {
      setSelectedCompany(companies[0]._id);
    } else {
      setSelectedCompany(undefined);
    }
  }, [companiesLoading]);

  useEffect(() => {
    const sQuery = search.split('=')[1];

    if (sQuery) {
      const formattedQuery = decodeURIComponent(sQuery);
      setSearchQ(formattedQuery);
    }
  }, [search.split('=')[1]]);

  return (
    <div className='company_messages'>
      <div className='navigation_header'>
        <div
          className='navigation_header-company'
          style={{
            backgroundColor: isBroadcast ? '#F3F8FF' : '#CADCF7',
          }}
          onClick={() => {
            setTimeout(() => {
              coRef.current?.scrollIntoView({
                behavior: 'smooth',
                block: 'end',
              });
            }, 1000);
            setIsBroadcast(false);
          }}>
          <p>Company Messages</p>
        </div>
        <div
          className='navigation_header-broadcast'
          style={{
            backgroundColor: isBroadcast ? '#CADCF7' : '#F3F8FF',
          }}
          onClick={() => {
            setTimeout(() => {
              brRef.current?.scrollIntoView({
                behavior: 'smooth',
                block: 'end',
              });
            }, 1000);
            setIsBroadcast(true);
          }}>
          <p>Broadcast Messages</p>
        </div>
      </div>
      <div className='message_main_content'>
        {!isBroadcast && (
          <>
            <motion.div
              initial={{ x: -50, y: 0 }}
              animate={{ x: 0, y: 0 }}
              transition={{ duration: 0.5 }}
              className='company_list'>
              <div className='company_list_search'>
                <FormControl
                  sx={{
                    m: 1,
                    width: '88%',
                    borderRadius: 1,
                    backgroundColor: Colors.lightBg,
                    margin: 0,
                  }}
                  variant='outlined'>
                  <OutlinedInput
                    id='s_search'
                    size='small'
                    placeholder='Search company'
                    style={{}}
                    value={searchQ}
                    onChange={(e) => {
                      setSearchQ(e.target.value);
                    }}
                    startAdornment={
                      <InputAdornment position='start'>
                        <SearchOutlinedIcon />
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </div>
              <div className='company_list_items'>
                {companies &&
                  companies.map((c) => {
                    return (
                      <SupportCCListItem
                        key={c._id}
                        active_s_id={
                          selectedCompany || '64f8267c7ed1e6aa0166bde1'
                        }
                        id={c._id}
                        s_name={c.company_name || 'Filling Information'}
                        photo={c.photo}
                        isPhotoVisible={c.isPhotoVisible}
                        s_id={c.referral_code}
                        onItemClick={(id) => {
                          setSelectedCompany(id);
                        }}
                      />
                    );
                  })}
              </div>
            </motion.div>

            <CompanyMessageSection selectedCompany={selectedCompany} />
          </>
        )}

        {isBroadcast && <BroadcastMessageSection />}
      </div>
    </div>
  );
};
export default CompanyMessagesPage;
