import { useSelector } from 'react-redux';
import {
  useQuery,
  DocumentNode,
  ApolloError,
  ApolloQueryResult,
  OperationVariables,
} from '@apollo/client';
import { RootState } from '../../../../store/store';
import { Review } from '../../../../types/schema/review.types';
import { PaginationWithBooleanInput } from '../../../../types/schema/utils.types';

interface GetAllReviewsByPageHook {
  data: Review[] | undefined;
  loading: boolean;
  error: ApolloError | undefined;
  refetch: (variables?: Partial<OperationVariables> | undefined) => Promise<
    ApolloQueryResult<{
      getAllReviewsByPage: Review[];
    }>
  >;
}

interface UseGetAllReviewsByPageMutationData {
  getAllReviewsByPage: Review[];
}

interface UseGetAllReviewsByPageInputType {
  input: PaginationWithBooleanInput;
}

export function useGetAllReviewsByPage(
  query: DocumentNode,
  input: PaginationWithBooleanInput
): GetAllReviewsByPageHook {
  const { jwt } = useSelector((state: RootState) => state.auth);

  const { data, loading, error, refetch } = useQuery<
    UseGetAllReviewsByPageMutationData,
    UseGetAllReviewsByPageInputType
  >(query, {
    variables: {
      input,
    },
    context: {
      headers: {
        cookie: `jwt=${jwt}`,
        authorization: jwt ? `Bearer ${jwt}` : '',
      },
    },
  });

  return {
    data: data?.getAllReviewsByPage,
    loading,
    error,
    refetch,
  };
}
