import React, { useState } from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import {
  UpdateUserInput,
  UserSettingsObjType,
} from '../../types/schema/user.types';
import { useUpdateUser } from '../../hooks/gql/mutation/update/useUpdateUser';

interface SettingsCheckboxProps {
  label: string;
  settingKey: keyof NonNullable<UpdateUserInput['settings']>;
  initialValue: boolean;
  userId: string;
  settings: UserSettingsObjType;
}

const SettingsCheckbox: React.FC<SettingsCheckboxProps> = ({
  label,
  settingKey,
  initialValue,
  userId,
  settings,
}) => {
  const [checked, setChecked] = useState(initialValue);
  const { updateUser } = useUpdateUser();

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);

    // @ts-ignore
    const { __typename, ...cleanedSettings } = settings;

    const input: UpdateUserInput = {
      _id: userId,
      settings: {
        ...cleanedSettings,
        [settingKey]: event.target.checked,
      },
    };

    const { error } = await updateUser(input);

    if (error) {
      alert(`Failed to update setting ${settingKey}: ${error}`);
    }
  };

  return (
    <FormControlLabel
      control={
        <Checkbox checked={checked} onChange={handleChange} color='primary' />
      }
      label={label}
    />
  );
};

export default SettingsCheckbox;
