import * as React from 'react';
import { alpha, styled } from '@mui/material/styles';
import { DataGrid, gridClasses } from '@mui/x-data-grid';
import { GridColDef } from '@mui/x-data-grid';
import ActionButtons from '../../components/Utils/ActionButtons';
import { Avatar } from '@mui/material';
import Colors from '../../constants/Colors';
import { useNavigate } from 'react-router-dom';
import { gql } from '@apollo/client';
import { getAmountWithCurrency, getNameAvatar } from '../../utils/functions';
import { gBaseUrl } from '../../utils/config';
import { useUpdateUser } from '../../hooks/gql/mutation/update/useUpdateUser';
import { useDeleteUser } from '../../hooks/gql/mutation/delete/useDeleteUser';
import { useGetAllUserWalletsByPage } from '../../hooks/gql/query/getAll/useGetAllUserWalletsByPage';
import ActionButtonsPayments from '../../components/Utils/ActionButtonsPayments';

const companyTableState = {
  initialState: {
    columns: {
      columnVisibilityModel: {
        id: false,
        company_name: true,
        email: true,
        final_wallet_balance: true,
        pending_wallet_balance: true,
        total_earning: true,
        action: true,

        phone: false,
        contact_name: false,
        link_refer_earning: false,
      },
    },
  },
};

const companyTableColumn: GridColDef[] = [
  { field: 'id', headerClassName: 'company_table_header' },
  {
    field: 'email',
    headerName: 'Email',
    flex: 1,
    minWidth: 200,
    headerAlign: 'center',
    headerClassName: 'company_table_header',
  },
  {
    field: 'phone',
    headerName: 'Phone Number',
    minWidth: 150,
    flex: 1,
    headerAlign: 'center',
    align: 'center',
    headerClassName: 'company_table_header',
  },
  {
    field: 'contact_name',
    headerName: 'Contact Name',
    minWidth: 150,
    flex: 1,
    headerAlign: 'center',
    align: 'center',
    headerClassName: 'company_table_header',
  },
];

const ODD_OPACITY = 0.2;

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.odd`]: {
    backgroundColor: '#F4F6F6',
    '&:hover, &.Mui-hovered': {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
    '&.Mui-selected': {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity
      ),
      '&:hover, &.Mui-hovered': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity
        ),
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  },
}));

const GET_ALL_USER_WALLETS = gql`
  query GetAllUserWalletsByPage($input: PaginationInput!) {
    getAllUserWalletsByPage(input: $input) {
      _id
      company_name
      email
      final_wallet_balance
      pending_wallet_balance
      total_earning

      photo
      isPhotoVisible
      contact_name
      phone
      link_refer_earning
    }
  }
`;

const InAppWalletsPage: React.FC<{}> = (props) => {
  const navigate = useNavigate();

  const { updateUser } = useUpdateUser();
  const { deleteUser } = useDeleteUser();

  const {
    data: users,
    error: usersError,
    loading: usersLoading,
    refetch: usersRefectch,
  } = useGetAllUserWalletsByPage(GET_ALL_USER_WALLETS, {
    page: 1,
    item_per_page: 1000,
  });

  const users_row =
    users?.map((c) => {
      return { ...c, id: c._id, actions: 'V' };
    }) || [];

  return (
    <>
      <div style={{ height: 631, width: '100%', marginBottom: 150 }}>
        <StripedDataGrid
          pageSizeOptions={[10, 50, 100]}
          columns={[
            {
              field: 'company_name',
              headerName: 'Company Name',
              minWidth: 250,
              flex: 1,
              headerAlign: 'center',
              headerClassName: 'company_table_header',
              renderCell: (params) => {
                const data = params.row;
                return (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    {data?.isPhotoVisible ? (
                      <Avatar
                        sizes='s'
                        sx={{
                          bgcolor: Colors.primaryColorLight,
                          marginRight: 1,
                          width: 27,
                          height: 27,
                        }}
                        src={`${gBaseUrl}/photos/avatars/${data?.photo}`}
                      />
                    ) : (
                      <Avatar
                        sizes='s'
                        sx={{
                          bgcolor: Colors.primaryColorLight,
                          marginRight: 1,
                          width: 27,
                          height: 27,
                        }}>
                        <p style={{ fontSize: 12 }}>
                          {getNameAvatar(data?.company_name)}
                        </p>
                      </Avatar>
                    )}
                    <p>{data?.company_name}</p>
                  </div>
                );
              },
            },
            ...companyTableColumn,
            {
              field: 'final_wallet_balance',
              headerName: 'Wallet Balance',
              minWidth: 100,
              flex: 1,
              headerAlign: 'center',
              align: 'center',
              headerClassName: 'company_table_header',
              renderCell: (params) => {
                const data = params.row;
                const wallet_balance = data?.final_wallet_balance || 0;
                const fontColor =
                  wallet_balance === 0
                    ? Colors.fontColor2
                    : wallet_balance < 0
                    ? Colors.accentColorDark
                    : Colors.primaryColorLight;

                return (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <p
                      style={{
                        color: fontColor,
                      }}>
                      {getAmountWithCurrency(wallet_balance)}
                    </p>
                  </div>
                );
              },
            },
            {
              field: 'pending_wallet_balance',
              headerName: 'Pending Balance',
              minWidth: 100,
              flex: 1,
              headerAlign: 'center',
              align: 'center',
              headerClassName: 'company_table_header',
              renderCell: (params) => {
                const data = params.row;
                const pending_balance = data?.pending_wallet_balance || 0;

                return (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <p>{getAmountWithCurrency(pending_balance)}</p>
                  </div>
                );
              },
            },

            {
              field: 'link_refer_earning',
              headerName: 'Refer Earnings',
              minWidth: 100,
              flex: 1,
              headerAlign: 'center',
              align: 'center',
              headerClassName: 'company_table_header',
              renderCell: (params) => {
                const data = params.row;
                return (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <p>{getAmountWithCurrency(data?.link_refer_earning)}</p>
                  </div>
                );
              },
            },

            {
              field: 'total_earning',
              headerName: 'Total Earnings',
              minWidth: 100,
              flex: 1,
              headerAlign: 'center',
              align: 'center',
              headerClassName: 'company_table_header',
              renderCell: (params) => {
                const data = params.row;
                return (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <p>{getAmountWithCurrency(data?.total_earning)}</p>
                  </div>
                );
              },
            },
            {
              field: 'action',
              headerName: 'Action',
              minWidth: 90,
              flex: 1,
              headerAlign: 'center',
              align: 'center',
              sortable: false,
              filterable: false,
              headerClassName: 'company_table_header',
              renderCell: (params) => {
                const data = params.row;
                return (
                  <div>
                    <ActionButtonsPayments
                      id={data.id}
                      onEyeClick={(id) => {
                        navigate(`/in-app-wallets/${id}`);
                      }}
                    />
                  </div>
                );
              },
            },
          ]}
          rows={users_row}
          initialState={{
            ...companyTableState.initialState,
            pagination: { paginationModel: { pageSize: 10 } },
          }}
          pagination
          sx={{
            '& .company_table_header': {
              backgroundColor: '#F3F8FF',
            },
          }}
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
          }
        />
      </div>
    </>
  );
};
export default InAppWalletsPage;
