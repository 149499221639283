import { RootState } from '../../../../store/store';
import { useSelector } from 'react-redux';
import { gql, useMutation } from '@apollo/client';
import {
  Review,
  UpdateReviewInput,
} from '../../../../types/schema/review.types';

const GQL_MUT = gql`
  mutation UpdateReviewByAdmin($input: UpdateReviewInput!) {
    updateReviewByAdmin(input: $input) {
      _id
    }
  }
`;

// Define the types for the mutation response and input
interface UpdateReviewByAdminMutationData {
  updateReviewByAdmin: Review;
}

interface UpdateReviewByAdminInputType {
  input: UpdateReviewInput;
}

export const useUpdateReviewByAdmin = () => {
  const { jwt } = useSelector((state: RootState) => state.auth);

  const [updateReviewByAdminGql, { data, error, loading }] = useMutation<
    UpdateReviewByAdminMutationData,
    UpdateReviewByAdminInputType
  >(GQL_MUT);

  const updateReviewByAdmin = async (input: UpdateReviewInput) => {
    const { data, errors } = await updateReviewByAdminGql({
      variables: {
        input: input,
      },
      context: {
        headers: {
          cookie: `jwt=${jwt}`,
          authorization: jwt ? `Bearer ${jwt}` : '',
        },
      },
    });

    return {
      updateReviewByAdmin: data?.updateReviewByAdmin,
      error: errors ? errors[0].message : null,
    };
  };

  return { updateReviewByAdmin, data, error, loading };
};
