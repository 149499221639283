import Colors from '../../constants/Colors';
import { gBaseUrl } from '../../utils/config';
import { getNameAvatar } from '../../utils/functions';
import './SupportCCListItem.scss';
import { Avatar } from '@mui/material';

const SupportCCListItem: React.FC<{
  id: string;
  s_name: string;
  s_id: string;
  active_s_id: string;
  photo?: string;
  isPhotoVisible?: boolean;
  onItemClick: (id: string) => void;
}> = ({
  id,
  s_name,
  s_id,
  photo,
  isPhotoVisible,
  onItemClick,
  active_s_id,
}) => {
  return (
    <div
      className='support_cc_item'
      onClick={() => {
        onItemClick(id);
      }}>
      <div
        className='support_cc_item_cont'
        style={{
          border:
            active_s_id === id
              ? `1px solid ${Colors.primaryColorLight}`
              : `1px solid transparent`,
        }}>
        {isPhotoVisible ? (
          <Avatar
            src={`${gBaseUrl}/photos/avatars/${photo}`}
            sx={{
              bgcolor: Colors.pclXLight,
              marginRight: 1,
              color: Colors.primaryColorLight,
              boxShadow: '0px 10px 15px -3px rgba(0, 0, 0, 0.1)',
            }}
          />
        ) : (
          <Avatar
            sx={{
              bgcolor: Colors.pclXLight,
              marginRight: 1,
              color: Colors.primaryColorLight,
              boxShadow: '0px 10px 15px -3px rgba(0, 0, 0, 0.1)',
            }}>
            {getNameAvatar(s_name)}
          </Avatar>
        )}
        <div className='business_info'>
          <p className='business_name'>{s_name}</p>
          <p className='business_id'>#{s_id}</p>
        </div>
      </div>
    </div>
  );
};

export default SupportCCListItem;
