import * as React from 'react';
import { alpha, styled } from '@mui/material/styles';
import { DataGrid, gridClasses } from '@mui/x-data-grid';
import { GridColDef } from '@mui/x-data-grid';
import moment from 'moment';
import { gql } from '@apollo/client';
import { formatString } from '../../utils/functions';
import { useDeleteCustomer } from '../../hooks/gql/mutation/delete/useDeleteCustomer';
import { useGetAllCancellationsByPage } from '../../hooks/gql/query/getAll/useGetAllCancellationsByPage';
import ActionEyeButton from '../../components/Utils/ActionEyeButton';

const cancellationTableState = {
  initialState: {
    columns: {
      columnVisibilityModel: {
        id: false,
        cancelled_by: true,
        cancellation_reason: true,
        pickup_location: true,
        destination: true,
        createdAt: true,
        actions: true,
      },
    },
  },
};

const cancellationTableColumn: GridColDef[] = [
  { field: 'id', headerClassName: 'cancellation_table_header' },
  {
    field: 'cancelled_by',
    headerName: 'Cancelled By',
    minWidth: 150,
    flex: 1,
    headerAlign: 'left',
    align: 'left',
    valueGetter: (params) =>
      params.row?.cancelled_user_ref?.contact_name ||
      params.row?.cancelled_customer_ref?.name ||
      formatString(params.row?.initiator),
    headerClassName: 'cancellation_table_header',
  },
  {
    field: 'cancellation_reason',
    headerName: 'Reason',
    minWidth: 180,
    flex: 1,
    headerAlign: 'center',
    valueGetter: (params) => formatString(params.row?.cancellation_reason),
    align: 'left',
    headerClassName: 'cancellation_table_header',
  },
  {
    field: 'pickup_location',
    headerName: 'Pickup',
    flex: 1,
    minWidth: 120,
    headerAlign: 'center',
    valueGetter: (params) =>
      params.row?.trip_info?.pickup_location?.location_name || '---',
    headerClassName: 'cancellation_table_header',
  },
  {
    field: 'destination',
    headerName: 'Destination',
    flex: 1,
    minWidth: 120,
    valueGetter: (params) =>
      params.row?.trip_info?.destination?.location_name || '---',
    headerAlign: 'center',
    headerClassName: 'cancellation_table_header',
  },
];

const ODD_OPACITY = 0.2;

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.odd`]: {
    backgroundColor: '#F4F6F6',
    '&:hover, &.Mui-hovered': {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
    '&.Mui-selected': {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity
      ),
      '&:hover, &.Mui-hovered': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity
        ),
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  },
}));

const GET_ALL_CANCELLATIONS = gql`
  query GetAllCancellationsByPage($input: PaginationInput!) {
    getAllCancellationsByPage(input: $input) {
      _id
      cancellation_reason
      cancelled_user_ref {
        _id
        company_name
        contact_name
      }
      cancelled_customer_ref {
        _id
        name
      }
      initiator
      createdAt
      trip_info {
        destination {
          location_name
        }
        pickup_location {
          location_name
        }
      }
    }
  }
`;

const CancellationsPage: React.FC<{}> = (props) => {
  const {
    data: cancellations,
    error: cancellationsError,
    loading: cancellationsLoading,
    refetch: cancellationsRefectch,
  } = useGetAllCancellationsByPage(GET_ALL_CANCELLATIONS, {
    page: 1,
    item_per_page: 1000,
  });

  const cancellations_row =
    cancellations?.map((c) => {
      return { ...c, id: c._id, actions: 'V' };
    }) || [];

  return (
    <>
      <div style={{ height: 631, width: '100%', marginBottom: 150 }}>
        <StripedDataGrid
          pageSizeOptions={[10, 50, 100]}
          columns={[
            ...cancellationTableColumn,
            {
              field: 'createdAt',
              headerName: 'Date Cancelled',
              flex: 1,
              minWidth: 180,
              headerAlign: 'center',
              align: 'center',
              headerClassName: 'cancellation_table_header',
              renderCell: (params) => {
                const data = params.row;
                return (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <p>{moment(data.createdAt).format('lll')}</p>
                  </div>
                );
              },
            },
            {
              field: 'action',
              headerName: 'Action',
              maxWidth: 70,
              minWidth: 70,
              headerAlign: 'center',
              align: 'center',
              sortable: false,
              filterable: false,
              headerClassName: 'cancellation_table_header',
              renderCell: (params) => {
                const data = params.row;
                return (
                  <div>
                    <ActionEyeButton
                      id={data.id}
                      onEyeClick={(_id) => {
                        alert('More details not available on this phase!');
                      }}
                    />
                  </div>
                );
              },
            },
          ]}
          rows={cancellations_row}
          initialState={{
            ...cancellationTableState.initialState,
            pagination: { paginationModel: { pageSize: 10 } },
          }}
          pagination
          sx={{
            '& .cancellation_table_header': {
              backgroundColor: '#F3F8FF',
            },
          }}
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
          }
        />
      </div>
    </>
  );
};

export default CancellationsPage;
