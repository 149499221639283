import { useSelector } from 'react-redux';
import {
  useQuery,
  DocumentNode,
  ApolloError,
  ApolloQueryResult,
  OperationVariables,
} from '@apollo/client';
import { RootState } from '../../../../store/store';
import { MongoIdInput } from '../../../../types/schema/utils.types';
import { CuMessage } from '../../../../types/schema/cumessage.types';

interface GetCuMessageByCustomerIdHook {
  data: CuMessage | undefined;
  loading: boolean;
  error: ApolloError | undefined;
  refetch: (variables?: Partial<OperationVariables> | undefined) => Promise<
    ApolloQueryResult<{
      getCuMessageByCustomerId: CuMessage;
    }>
  >;
}

interface GetCuMessageByCustomerIdMutationData {
  getCuMessageByCustomerId: CuMessage;
}

interface GetCuMessageByCustomerIdInputType {
  input: MongoIdInput;
}

export function useGetCuMessageByCustomerId(
  query: DocumentNode,
  input: MongoIdInput
): GetCuMessageByCustomerIdHook {
  const { jwt } = useSelector((state: RootState) => state.auth);

  const { data, loading, error, refetch } = useQuery<
    GetCuMessageByCustomerIdMutationData,
    GetCuMessageByCustomerIdInputType
  >(query, {
    variables: {
      input,
    },
    context: {
      headers: {
        cookie: `jwt=${jwt}`,
        authorization: jwt ? `Bearer ${jwt}` : '',
      },
    },
  });

  return { data: data?.getCuMessageByCustomerId, loading, error, refetch };
}
