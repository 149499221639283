import React, { useState } from 'react';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import './TestimonialsPage.scss';
import { gql } from '@apollo/client';
import LoadingSpinner from '../../components/UI/LoadingSpinner';
import CustomButton from '../../components/UI/CustomButton';
import TestimonialListItem from '../../components/ListItem/TestimonialListItem';
import { useUpdateTestimonialByAdmin } from '../../hooks/gql/mutation/update/useUpdateTestimonialByAdmin';
import { useDeleteTestimonial } from '../../hooks/gql/mutation/delete/useDeleteTestimonial';
import { useGetAllTestimonialsByPage } from '../../hooks/gql/query/getAll/useGetAllTestimonialsByPage';

const GET_TESTIMONIALS_BY_PAGE = gql`
  query GetAllTestimonialsByPage($input: PaginationWithBooleanInput!) {
    getAllTestimonialsByPage(input: $input) {
      _id
      message
      rating
      isVisible
      added_by {
        _id
        contact_name
        referral_code
        photo
        isPhotoVisible
      }
    }
  }
`;

const TestimonialsPage: React.FC<{}> = (props) => {
  const [sortOption, setSortOption] = useState('1');
  const [page_number, setPage_number] = useState(1);

  const { updateTestimonialByAdmin } = useUpdateTestimonialByAdmin();
  const { deleteTestimonial } = useDeleteTestimonial();

  const {
    data: testimonials,
    error: testimonialsError,
    loading: testimonialsLoading,
    refetch: testimonialsRefectch,
  } = useGetAllTestimonialsByPage(GET_TESTIMONIALS_BY_PAGE, {
    bool: sortOption === '1' ? false : true,
    page: page_number,
    item_per_page: 20,
  });

  const handleChange = (event: SelectChangeEvent) => {
    setSortOption(event.target.value as string);
    testimonialsRefectch();
  };

  return (
    <>
      <div className='sorting_wrapper'>
        <p>Sort by</p>
        <FormControl>
          <Select
            id='demo-simple-select'
            value={sortOption}
            onChange={handleChange}
            sx={{
              boxShadow: 'none',
              '.MuiOutlinedInput-notchedOutline': { border: 0 },
            }}>
            <MenuItem value={'1'}>Requires Approval</MenuItem>
            <MenuItem value={'2'}>Already Approved</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div className='testimonials_cards'>
        {testimonialsLoading && !testimonials && (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <LoadingSpinner />
          </div>
        )}

        {testimonials?.length === 0 && (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              paddingTop: '100px',
            }}>
            <p>No Testimonial Requires Action</p>
          </div>
        )}
        {testimonials?.map((tm: any) => {
          return (
            <TestimonialListItem
              isVisible={tm.isVisible}
              onActionClick={async (_id, isVisible) => {
                await updateTestimonialByAdmin({
                  _id,
                  isVisible: !isVisible,
                });
                testimonialsRefectch();
              }}
              onDelete={async (_id) => {
                await deleteTestimonial({
                  _id,
                });
                testimonialsRefectch();
              }}
              key={tm._id}
              _id={tm._id}
              rating={tm.rating}
              message={tm.message}
              added_by={{
                contact_name: tm.added_by.contact_name,
                photo: tm.added_by.photo,
                isPhotoVisible: tm.added_by.isPhotoVisible,
              }}
            />
          );
        })}
      </div>

      <div className='testimonials_action_wrapper'>
        <CustomButton
          title='Load More'
          onClick={() => {
            setPage_number((pn) => pn + 1);
            testimonialsRefectch();
          }}
          isLoading={testimonialsLoading}
        />
      </div>

      <div className='footer_content'></div>
    </>
  );
};
export default TestimonialsPage;
