import { RootState } from '../../../../store/store';
import { useSelector } from 'react-redux';
import { gql, useMutation } from '@apollo/client';
import { MongoIdInput } from '../../../../types/schema/utils.types';
import { Customer } from '../../../../types/schema/customer.types';

const GQL_MUT = gql`
  mutation DeleteCustomer($input: MongoIdInput!) {
    deleteCustomer(input: $input) {
      _id
    }
  }
`;

// Define the types for the mutation response and input
interface DeleteCustomerMutationData {
  deleteCustomer: Customer;
}

interface DeleteCustomerInputType {
  input: MongoIdInput;
}

export const useDeleteCustomer = () => {
  const { jwt } = useSelector((state: RootState) => state.auth);

  const [deleteCustomerGql, { data, error, loading }] = useMutation<
    DeleteCustomerMutationData,
    DeleteCustomerInputType
  >(GQL_MUT);

  const deleteCustomer = async (input: MongoIdInput) => {
    const { data, errors } = await deleteCustomerGql({
      variables: {
        input: input,
      },
      context: {
        headers: {
          cookie: `jwt=${jwt}`,
          authorization: jwt ? `Bearer ${jwt}` : '',
        },
      },
    });

    return {
      deleteCustomer: data?.deleteCustomer,
      error: errors ? errors[0].message : null,
    };
  };

  return { deleteCustomer, data, error, loading };
};
