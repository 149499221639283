import './CustomerMessagesPage.scss';
import { FormControl, InputAdornment, OutlinedInput } from '@mui/material';
import Colors from '../../constants/Colors';
import SupportCCListItem from '../../components/ListItem/SupportCCListItem';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { gql } from '@apollo/client';
import { useEffect, useState } from 'react';
import CustomerMessagesSection from './CustomerMessagesSection';
import { useLocation } from 'react-router-dom';
import { useGetAllCustomersBySearchByPage } from '../../hooks/gql/query/getAll/useGetAllCustomersBySearchByPage';

const GET_ALL_CUSTOMER_BY_SEARCH = gql`
  query GetAllCustomersBySearchByPage($input: PaginationWSearchInput!) {
    getAllCustomersBySearchByPage(input: $input) {
      _id
      name
      email
      phone_number
    }
  }
`;

const CustomerMessagesPage: React.FC<{}> = (props) => {
  const { search } = useLocation();
  const [searchQ, setSearchQ] = useState('');

  const [selectedCustomer, setSelectedCustomer] = useState<
    string | undefined
  >();

  const {
    data: customers,
    error: customersError,
    loading: customersLoading,
    refetch: customersRefectch,
  } = useGetAllCustomersBySearchByPage(GET_ALL_CUSTOMER_BY_SEARCH, {
    query: searchQ,
    page: 1,
    item_per_page: 6,
  });

  useEffect(() => {
    if (customers && customers.length > 0) {
      setSelectedCustomer(customers[0]._id);
    } else {
      setSelectedCustomer(undefined);
    }
  }, [customersLoading]);

  useEffect(() => {
    const sQuery = search.split('=')[1];
    if (sQuery) {
      const formattedQuery = decodeURIComponent(sQuery);
      setSearchQ(formattedQuery);
    }
  }, [search.split('=')[1]]);

  return (
    <div className='customer_messages_wrapper'>
      <div className='customer_list'>
        <div className='customer_list_search'>
          <FormControl
            sx={{
              m: 1,
              width: '88%',
              borderRadius: 1,
              backgroundColor: Colors.lightBg,
              margin: 0,
            }}
            variant='outlined'>
            <OutlinedInput
              id='s_search'
              size='small'
              placeholder='Search Customer'
              style={{}}
              value={searchQ}
              onChange={(e) => {
                setSearchQ(e.target.value);
              }}
              startAdornment={
                <InputAdornment position='start'>
                  <SearchOutlinedIcon />
                </InputAdornment>
              }
            />
          </FormControl>
        </div>
        <div className='customer_list_items'>
          {customers &&
            customers.map((c) => {
              return (
                <SupportCCListItem
                  key={c._id}
                  active_s_id={selectedCustomer || '64f8267c7ed1e6aa0166bde1'}
                  id={c._id}
                  s_name={c.name || 'Filling Information'}
                  s_id={c.phone_number}
                  onItemClick={(id) => {
                    setSelectedCustomer(id);
                  }}
                />
              );
            })}
        </div>
      </div>
      <CustomerMessagesSection selectedCustomer={selectedCustomer} />
    </div>
  );
};
export default CustomerMessagesPage;
