import { RootState } from '../../../../store/store';
import { useSelector } from 'react-redux';
import { gql, useMutation } from '@apollo/client';
import {
  CoMessage,
  UpdateCoMessageInput,
} from '../../../../types/schema/comessage.types';

const GQL_MUT = gql`
  mutation UpdateCoMessage($input: UpdateCoMessageInput!) {
    updateCoMessage(input: $input) {
      _id
    }
  }
`;

// Define the types for the mutation response and input
interface UpdateCoMessageMutationData {
  updateCoMessage: CoMessage;
}

interface UpdateCoMessageInputType {
  input: UpdateCoMessageInput;
}

export const useUpdateCoMessage = () => {
  const { jwt } = useSelector((state: RootState) => state.auth);

  const [updateCoMessageGql, { data, error, loading }] = useMutation<
    UpdateCoMessageMutationData,
    UpdateCoMessageInputType
  >(GQL_MUT);

  const updateCoMessage = async (input: UpdateCoMessageInput) => {
    const { data, errors } = await updateCoMessageGql({
      variables: {
        input: input,
      },
      context: {
        headers: {
          cookie: `jwt=${jwt}`,
          authorization: jwt ? `Bearer ${jwt}` : '',
        },
      },
    });

    return {
      updateCoMessage: data?.updateCoMessage,
      error: errors ? errors[0].message : null,
    };
  };

  return { updateCoMessage, data, error, loading };
};
