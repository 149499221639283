import Rating from '@mui/material/Rating';
import { getNameAvatar, getRatingLabel } from '../../utils/functions';
import './TestimonialListItem.scss';
import { Avatar, FormControl } from '@mui/material';
import Colors from '../../constants/Colors';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { gBaseUrl } from '../../utils/config';

const TestimonialListItem: React.FC<{
  _id: string;
  rating: number;
  message: string;
  isVisible: boolean;
  added_by: {
    contact_name: string;
    photo: string;
    isPhotoVisible: boolean;
  };
  onDelete: (id: string) => void;
  onActionClick: (id: string, isVisible: boolean) => void;
}> = ({
  _id,
  rating,
  isVisible,
  message,
  added_by: { contact_name, photo, isPhotoVisible },
  onDelete,
  onActionClick,
}) => {
  return (
    <div className='testimonials_cards_item'>
      <div className='title_logo'>
        <div className='logo_comp'>
          {isPhotoVisible ? (
            <Avatar
              src={`${gBaseUrl}/photos/avatars/${photo}`}
              sx={{
                bgcolor: Colors.pclXLight,
                marginRight: 1,
                color: Colors.primaryColorLight,
                boxShadow: '0px 10px 15px -3px rgba(0, 0, 0, 0.1)',
              }}
            />
          ) : (
            <Avatar
              sx={{
                bgcolor: Colors.pclXLight,
                marginRight: 1,
                color: Colors.primaryColorLight,
                boxShadow: '0px 10px 15px -3px rgba(0, 0, 0, 0.1)',
              }}>
              {getNameAvatar(contact_name)}
            </Avatar>
          )}
          <div className='logo_comp_star'>
            <p className='business_name'>{contact_name}</p>
            <div className='rating_wrapper'>
              <p>{rating}</p>
              <Rating
                name='simple-controlled'
                value={rating}
                onChange={(event, newValue) => {
                  //
                }}
              />
            </div>
          </div>
        </div>

        <div className='temp_status'>
          <p>{getRatingLabel(rating)}</p>
        </div>
      </div>
      <div className='content_wrapper'>
        <p className='content_text'>{message}</p>
      </div>
      <div className='action_buttons'>
        <FormControl sx={{ m: 1, width: '12ch' }} variant='standard'>
          <Button
            variant='contained'
            size='small'
            onClick={() => {
              onActionClick(_id, isVisible);
            }}>
            {isVisible ? 'Hide' : 'Approve'}
          </Button>
        </FormControl>
        <div className='view_del'>
          <IconButton
            aria-label='delete'
            size='small'
            onClick={() => {
              onDelete(_id);
            }}>
            <DeleteIcon style={{ color: 'red' }} />
          </IconButton>
        </div>
      </div>
    </div>
  );
};

export default TestimonialListItem;
