import { RootState } from '../../../../store/store';
import { useSelector } from 'react-redux';
import { gql, useMutation } from '@apollo/client';
import { User } from '../../../../types/schema/user.types';
import { MongoIdInput } from '../../../../types/schema/utils.types';

const GQL_MUT = gql`
  mutation DeleteUser($input: MongoIdInput!) {
    deleteUser(input: $input) {
      _id
    }
  }
`;

// Define the types for the mutation response and input
interface DeleteUserMutationData {
  deleteUser: User;
}

interface DeleteUserInputType {
  input: MongoIdInput;
}

export const useDeleteUser = () => {
  const { jwt } = useSelector((state: RootState) => state.auth);

  const [deleteUserGql, { data, error, loading }] = useMutation<
    DeleteUserMutationData,
    DeleteUserInputType
  >(GQL_MUT);

  const deleteUser = async (input: MongoIdInput) => {
    const { data, errors } = await deleteUserGql({
      variables: {
        input: input,
      },
      context: {
        headers: {
          cookie: `jwt=${jwt}`,
          authorization: jwt ? `Bearer ${jwt}` : '',
        },
      },
    });

    return {
      deleteUser: data?.deleteUser,
      error: errors ? errors[0].message : null,
    };
  };

  return { deleteUser, data, error, loading };
};
