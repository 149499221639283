import { RootState } from '../../../../store/store';
import { useSelector } from 'react-redux';
import { gql, useMutation } from '@apollo/client';
import { Review } from '../../../../types/schema/review.types';
import { MongoIdInput } from '../../../../types/schema/utils.types';

const GQL_MUT = gql`
  mutation DeleteReview($input: MongoIdInput!) {
    deleteReview(input: $input) {
      _id
    }
  }
`;

// Define the types for the mutation response and input
interface DeleteReviewMutationData {
  deleteReview: Review;
}

interface DeleteReviewInputType {
  input: MongoIdInput;
}

export const useDeleteReview = () => {
  const { jwt } = useSelector((state: RootState) => state.auth);

  const [deleteReviewGql, { data, error, loading }] = useMutation<
    DeleteReviewMutationData,
    DeleteReviewInputType
  >(GQL_MUT);

  const deleteReview = async (input: MongoIdInput) => {
    const { data, errors } = await deleteReviewGql({
      variables: {
        input: input,
      },
      context: {
        headers: {
          cookie: `jwt=${jwt}`,
          authorization: jwt ? `Bearer ${jwt}` : '',
        },
      },
    });

    return {
      deleteReview: data?.deleteReview,
      error: errors ? errors[0].message : null,
    };
  };

  return { deleteReview, data, error, loading };
};
