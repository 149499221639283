import { RootState } from '../../../../store/store';
import { useSelector } from 'react-redux';
import { gql, useMutation } from '@apollo/client';
import {
  AddCoMessageInput,
  CoMessage,
} from '../../../../types/schema/comessage.types';

const GQL_MUT = gql`
  mutation AddCoMessage($input: AddCoMessageInput!) {
    addCoMessage(input: $input) {
      _id
    }
  }
`;

// Define the types for the mutation response and input
interface AddCoMessageMutationData {
  addCoMessage: CoMessage;
}

interface AddCoMessageInputType {
  input: AddCoMessageInput;
}

export const useAddCoMessage = () => {
  const { jwt } = useSelector((state: RootState) => state.auth);

  const [addCoMessageGql, { data, error, loading }] = useMutation<
    AddCoMessageMutationData,
    AddCoMessageInputType
  >(GQL_MUT);

  const addCoMessage = async (input: AddCoMessageInput) => {
    const { data, errors } = await addCoMessageGql({
      variables: {
        input: input,
      },
      context: {
        headers: {
          cookie: `jwt=${jwt}`,
          authorization: jwt ? `Bearer ${jwt}` : '',
        },
      },
    });

    return {
      addCoMessage: data?.addCoMessage,
      error: errors ? errors[0].message : null,
    };
  };

  return { addCoMessage, data, error, loading };
};
